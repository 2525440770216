import { makeStyles } from "@mui/styles";
import btnMask from "../../assets/images/btn-mask.png";

export default makeStyles(({ palette, functions }) => {
  const { white, black, text, dark, transparent, gradients } = palette;
  const { linearGradient, pxToRem, rgba } = functions;
  return {
    contained: {
      backgroundColor: "#1264e2",
      color: ({ buttonColor }) => {
        let textColor = "#fff !important";

        if (buttonColor === "default") {
          textColor = "#fff !important";
        } else if (["primary", "secondary", "light"].includes(buttonColor)) {
          textColor = "#fff !important";
        } else if (["info", "warning", "error", "dark"].includes(buttonColor)) {
          textColor = "#fff !important";
        } else if (buttonColor === "#1264e2") {
          textColor = "#fff !important";
        }

        return textColor;
      },
      "&:hover, &:focus": {
        backgroundColor: ({ buttonColor }) => `#1264e2`,
        color: ({ buttonColor }) => {
          let textColor = black.main;

          if (buttonColor === "default") {
            textColor = dark.main;
          } else if (["primary", "secondary", "light"].includes(buttonColor)) {
            textColor = text.main;
          } else if (["info", "warning", "error", "dark"].includes(buttonColor)) {
            textColor = white.main;
          } else if (buttonColor === "#1264e2") {
            textColor = text.focus;
          }

          return textColor;
        },
      },

      "&:disabled": {
        backgroundColor: ({ buttonColor }) =>
          buttonColor === "#1264e2",
        color: ({ buttonColor }) => {
          let textColor = black.main;

          if (buttonColor === "default") {
            textColor = text.main;
          } else if (["primary", "secondary", "light"].includes(buttonColor)) {
            textColor = text.main;
          } else if (["info", "warning", "error", "dark"].includes(buttonColor)) {
            textColor = white.main;
          }

          return textColor;
        },
      },
    },

    outlined: {
      backgroundColor: ({ buttonColor }) =>
        buttonColor === "white" ? rgba(white.main, 0.1) : transparent.main,
      color: ({ buttonColor }) => `${palette[buttonColor].main} !important`,
      borderColor: ({ buttonColor }) =>
        buttonColor === "white" ? rgba(white.main, 0.75) : palette[buttonColor].main,

      "&:hover": {
        backgroundColor: transparent.main,
        borderColor: ({ buttonColor }) => palette[buttonColor].focus,
      },

      "&:focus:not(:hover)": {
        backgroundColor: ({ buttonColor }) =>
          buttonColor === "white" ? rgba(white.main, 0.1) : transparent.main,
      },

      "&:disabled": {
        color: ({ buttonColor }) => palette[buttonColor].main,
        borderColor: ({ buttonColor }) => palette[buttonColor].main,
      },
    },

    gradient: {
      background: ({ buttonColor }) => {
        let background;

        if (["info"].includes(buttonColor)) {
          background = linearGradient(
            `${gradients[buttonColor].main} 3.68%`,
            `${gradients[buttonColor].state} 98%`,
            110
          );
        } else if (["primary"].includes(buttonColor)) {
          background = transparent.main;
        } else {
          background = white.main;
        }

        return background;
      },
      color: ({ buttonColor }) => {
        let color;

        if (buttonColor === "default") {
          color = text.main;
        } else if (["info"].includes(buttonColor)) {
          color = white.main;
        }

        return color;
      },
      zIndex: 1,
      "&::before, &::after": {
        position: "absolute",
        borderRadius: "0.5rem",
        content: ({ buttonColor }) => (buttonColor === "primary" ? "''" : "none"),
        zIndex: -1,
      },
      "&::before": {
        width: "calc(100% + 4px)",
        height: "calc(100% + 4px)",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        background:
          "linear-gradient(239deg, rgba(50,90,255,1) 0%, rgba(58,127,242,1) 18%, rgba(66,164,229,1) 32%, rgba(72,193,219,1) 49%, rgba(76,213,212,1) 66%, rgba(79,226,207,1) 81%, rgba(80,230,206,1) 95%)",
      },
      "&::after": {
        width: "100%",
        height: "100%",
        background: `url(${btnMask}) center no-repeat`,
        backgroundColor: "#fff",
        backgroundSize: "cover !important",
      },
      "&:hover": {
        backgroundColor: ({ buttonColor }) => {
          let background;

          if (["info"].includes(buttonColor)) {
            background = linearGradient(
              `${gradients[buttonColor].main} 3.68%`,
              `${gradients[buttonColor].state} 98%`,
              110
            );
          } else if (["primary"].includes(buttonColor)) {
            background = transparent.main;
          } else {
            background = white.main;
          }

          return background;
        },
      },

      "&:focus:not(:hover)": {
        boxShadow: "none",
      },

      "&:disabled": {
        backgroundColor: ({ buttonColor }) => {
          let background;

          if (["info"].includes(buttonColor)) {
            background = linearGradient(
              `${gradients[buttonColor].main} 3.68%`,
              `${gradients[buttonColor].state} 98%`,
              110
            );
          } else {
            background = white.main;
          }

          return background;
        },
        color: ({ buttonColor }) => {
          let color;

          if (buttonColor === "default") {
            color = text.main;
          } else if (["info"].includes(buttonColor)) {
            color = white.main;
          }

          return color;
        },
      },
    },

    text: {
      color: ({ buttonColor }) => palette[buttonColor].main,

      "&:hover": {
        color: ({ buttonColor }) => palette[buttonColor].main,
      },

      "&:focus:not(:hover)": {
        color: ({ buttonColor }) => palette[buttonColor].main,
      },
    },

    circular: {
      borderRadius: pxToRem(160),
    },

    iconOnly: {
      minWidth: ({ size }) => {
        let minWidth;

        if (size === "small") {
          minWidth = pxToRem(25.4);
        } else if (size === "large") {
          minWidth = pxToRem(52);
        } else {
          minWidth = pxToRem(38);
        }

        return minWidth;
      },

      width: ({ size }) => {
        let width;

        if (size === "small") {
          width = pxToRem(25.4);
        } else if (size === "large") {
          width = pxToRem(52);
        } else {
          width = pxToRem(38);
        }

        return width;
      },

      minHeight: ({ size }) => {
        let minHeight;

        if (size === "small") {
          minHeight = pxToRem(25.4);
        } else if (size === "large") {
          minHeight = pxToRem(52);
        } else {
          minHeight = pxToRem(38);
        }

        return minHeight;
      },

      height: ({ size }) => {
        let height;

        if (size === "small") {
          height = pxToRem(25.4);
        } else if (size === "large") {
          height = pxToRem(52);
        } else {
          height = pxToRem(38);
        }

        return height;
      },

      padding: ({ size }) => {
        let padding;

        if (size === "small") {
          padding = pxToRem(4.5);
        } else if (size === "large") {
          padding = pxToRem(16);
        } else {
          padding = `${pxToRem(11)} ${pxToRem(11)} ${pxToRem(10)}`;
        }

        return padding;
      },

      "& .material-icons": {
        marginTop: 0,
      },

      "&:hover, &:focus, &:active": {
        transform: "none",
      },
    },
  };
});
