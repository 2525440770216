import * as React from "react";
import usePagination from "@mui/material/usePagination";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const List = styled("ul")({
  listStyle: "none",
  padding: 10,
  margin: 0,
  display: "flex",

  ".pageitem": {
    fontWeight: 600,
    background: "#e3e8f0",
    padding: "8px 15px",
    borderRadius: "2px",
    margin: "5px",
    "&.active": {
      background: "blue",
      color: "white"
    }
  }
});

const StyledIconButton = styled(IconButton)({
  background: "#e3e8f0",
  color: "#000 !important",
  fontSize: "20px",
  borderRadius: "2px !important",
  padding: "5px 14px",
  margin: "5px"
});

const UsePagination = ({ handleChange, page, totalpage }) => {
  const { items } = usePagination({
    count: totalpage,
    page,
    onChange: (e, value) => handleChange(e, value)
  });

  return (
    <nav>
      <List>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;
          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = "…";
          } else if (type === "page") {
            children = (
              <button
                className={`pageitem ${selected ? "active" : ""}`}
                type="button"
                {...item}
              >
                {page}
              </button>
            );
          } else if (type === "previous") {
            children = (
              <StyledIconButton
                type="button"
                {...item}
                onClick={(e) => handleChange(e, page - 1)}
              >
                <ArrowBackIosIcon />
                Previous
              </StyledIconButton>
            );
          } else if (type === "next") {
            children = (
              <StyledIconButton
                type="button"
                {...item}
                onClick={(e) => handleChange(e, page + 1)}
              >
                Next
                <ArrowForwardIosIcon />
              </StyledIconButton>
            );
          }

          return <li key={index}>{children}</li>;
        })}
      </List>
    </nav>
  );
};

export default UsePagination;
