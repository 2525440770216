import {
  Box,
  Card,
  Checkbox,
  CircularProgress,
  DialogContent,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Switch,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import UsePagination from './multiPage.jsx';
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import EnhancedTable from "components/SuiTable/EnhancedTable";
import TableToolbar from "components/SuiTable/TableToolbar";
import { useFormik } from "formik";
import { CustomerTableHeader } from "./data";
import EditIcon from "Container/Icons/EditIcon";
import DeleteIcon from "Container/Icons/DeleteIcon";
import { Link, useNavigate } from "react-router-dom";
import SuiDialog from "components/SuiDialog";
import { useEffect, useState } from "react";
import SuiTypography from "components/SuiTypography";
import DiscountTypeDropdown from "components/SuiDropDownList/DiscountTypeDropdown";
import { useGetCustomerInfoMutation, useDeleteUserMutation } from "app/features/api/AuthApi";
import { useDeleteAdminMutation } from "app/features/api/AuthApi";
import useNavigateSearch from "hooks/useNavigateSearch";
import { useDownloadDataMutation } from "app/features/api/AuthApi";
import { appendZero } from "helper";
import { format } from "date-fns";
import { useUpdateCustomerStatusMutation } from "app/features/api/CustomerApi";
import { useUpdateCustomerNetworkStatusMutation } from "app/features/api/CustomerStatusApi";
import { setID } from "app/features/DashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { resetCustomerDetails } from "app/features/CustomeDetailsSlice";
import { useCustomerBulkDiscountMutation } from "app/features/api/CustomerApi";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import * as Yup from "yup";
import ErrorMessageComponent from "components/FormikErrorMessage";
import CommonPagination from "components/SuiTablePagination/CommonPagination";
import SearchIcon from '@mui/icons-material/Search';
export let apiController = new AbortController();

const CustomerTable = () => {
  const [count, setCount] = useState(0);
  const suiSnackbar = useSuiSnackbar();
  const [checkedItems, setCheckedItems] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [customerInfo, setCustomerInfo] = useState("");
  const [searchParams, setSearchParams] = useState('')
  const { permission } = useSelector((state) => state.auth)
  const [isChecked, setIsChecked] = useState(true)
  let page = new URLSearchParams(window.location.search).get("page");
  if (page === null) {
    page = 1;
  }
  const navigateSearch = useNavigateSearch();
  const [open, setOpen] = useState(false);
  const [getCustomerInfo, { isLoading }] = useGetCustomerInfoMutation();
  const [downloadData] = useDownloadDataMutation();
  const [updateCustomerStatus] = useUpdateCustomerStatusMutation();
  const [currentPage, setCurrentPage] = useState(1);
  const [customerBulkDiscount] = useCustomerBulkDiscountMutation();
  const [updateCustomerNetworkStatus] = useUpdateCustomerNetworkStatusMutation();
  const [deleteUser, { isLoading: loader }] = useDeleteUserMutation();
  const [paginationObject, setPaginationObject] = useState({
    currentPage: 0,
    resultsPerPage: 0,
    totalPages: 0,
    totalUsers: 0
  })
  const loginType = localStorage.getItem('type')
  useEffect(() => {
    if (permission && !permission?.includes('users:read')) {
      console.log(permission)
      navigate('/profile')
    }
    if (loginType === 'tenant') {
      navigate('/profile')
    }
  }, [permission])

  function getPageRangeText(paginationInfo) {
    const { currentPage, resultsPerPage, totalUsers } = paginationInfo;
    const startIndex = (currentPage - 1) * resultsPerPage + 1;
    let endIndex = currentPage * resultsPerPage;
    if (endIndex > totalUsers) {
      endIndex = totalUsers;
    }

    // Generate the text
    const text = `Showing ${startIndex} to ${endIndex} of ${totalUsers} entries`;

    return text;
  }
  // console.log(permission)
  const doCancel = () => {
    apiController.abort();
    apiController = new AbortController();
  };

  const dispatch = useDispatch();
  const updateCustomerStatusFunc = async (id, status) => {
    const body = {
      id: id,
      status: status,
    };
    try {
      const response = await updateCustomerStatus(body).unwrap();
      suiSnackbar(response.message, "success");
      getCustomerOnload();
    } catch (error) {
      console.log(error);
    }
  };
  const updateCustomerNetorkStatusFunc = async (id, status) => {
    const body = {
      id: id,
      status: status,
    };
    try {
      const response = await updateCustomerNetworkStatus(body).unwrap();
      suiSnackbar(response.message, "success");
      getCustomerOnload();
    } catch (error) {
      console.log(error);
    }
  };
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [deleteID, setDeleteID] = useState("");
  const deleteModal = (id) => {
    setDeleteID(id);
    setOpenModal(true);
  };
  const handleDeleteIconClick = async (id) => {
    try {
      const response = await deleteUser(id).unwrap();
      if (response.statusCode === 200) {
        suiSnackbar(response.message, "success");
        setOpenModal(false);
        navigate('/users')
        const rolesInfo = await getCustomerInfo({ page: 1, limit: 10, })
        setSearchParams('')
        setCount(rolesInfo?.data?.data?.pagination?.totalUsers);
        setPaginationObject(rolesInfo?.data?.data?.pagination)
        setCustomerInfo(rolesInfo?.data?.data?.users);
      }
    } catch (error) {
      console.log(error);
      suiSnackbar(error?.data?.message, 'error')
    }
    // handleSubmitForm(1);
  };

  // FORM DETAIL
  const formik = useFormik({
    initialValues: {
      cus_account_number: "",
      first_name: "",
      last_name: "",
      mobile_num: "",
      email: "",
      account_type: "",
      account_status: "",
      cus_company_name: "",
    },

    enableReinitialize: true,
    onSubmit: () => {
      handleSubmitForm();
    },
  });

  const discountFormik = useFormik({
    initialValues: {
      sip_trunk: "",
      sip_trunk_margin: 0,
      international_did: "",
      international_did_margin: 0,
      national_did: "",
      national_did_margin: 0,
    },
    // enableReinitialize: true,/
    validationSchema: Yup.object().shape({
      sip_trunk: Yup.string().required("Please select the discount type"),
      international_did: Yup.string().required("Please select the discount type"),
      national_did: Yup.string().required("Please select the discount type"),
      sip_trunk_margin: Yup.number().when("sip_trunk", {
        is: (value) => value && value.toLowerCase() === "percentage",
        then: Yup.number()
          .typeError("Please enter a number")
          .min(1, "SIP Trunk value should be at least 1%.")
          .max(100, "SIP Trunk value should not exceed 100%."),
        otherwise: Yup.number()
          .typeError("Please enter a number")
          .min(1, "SIP Trunk value must be at least 1"),
      }),
      international_did_margin: Yup.number().when("international_did", {
        is: (value) => value && value.toLowerCase() === "percentage",
        then: Yup.number()
          .typeError("Please enter a number")
          .min(1, "International DID value should be at least 1%")
          .max(100, "International DID value should not exceed 100%."),
        otherwise: Yup.number()
          .typeError("Please enter a number")
          .min(1, "International DID value must be at least 1"),
      }),
      national_did_margin: Yup.number().when("national_did", {
        is: (value) => value && value.toLowerCase() === "percentage",
        then: Yup.number()
          .typeError("Please enter a number")
          .min(1, "National DID value should be at least 1%")
          .max(100, "National DID value should not exceed 100%."),
        otherwise: Yup.number()
          .typeError("Please enter a number")
          .min(1, "National DID value must be at least 1"),
      }),
    }),
    onSubmit: (values) => {
      handleDiscount(values);
    },
  });
  const handlePageChange = async (e, page) => {
    setCurrentPage(page);
    // navigateSearch("/users", { search: searchParams,limit: 10,page: page });
    setIsChecked(true)
    try {
      const response = await getCustomerInfo({ search: searchParams, page: page, limit: 10, })
      setCustomerInfo(response?.data?.data?.users)
      setCount(response?.data?.data?.pagination?.totalUsers);
      setPaginationObject(response?.data?.data?.pagination)
      setPerPage(10);
      setCurrentPage(page);
    } catch (error) {
      console.log(error)
    } finally { setIsChecked(false) }
    // getCustomerMultipleRecords(perPage, page);
  };
  const downloadDetails = async () => {
    let urlParamss = {};
    if (formik.values.cus_account_number) {
      urlParamss.cus_account_number = formik.values.cus_account_number;
    }
    if (formik.values.first_name) {
      urlParamss.cus_firstname = formik.values.first_name;
    }
    if (formik.values.last_name) {
      urlParamss.cus_lastname = formik.values.last_name;
    }
    if (formik.values.mobile_num) {
      urlParamss.cus_phone_number = formik.values.mobile_num;
    }
    if (formik.values.email) {
      urlParamss.cus_email = formik.values.email;
    }
    if (formik.values.cus_company_name) {
      urlParamss.cus_company_name = formik.values.cus_company_name;
    }
    if (formik.values.account_type == "pre_paid" || formik.values.account_type == "post_paid") {
      if (formik.values.account_type == "pre_paid") {
        urlParamss.cus_account_type = 1;
      } else if (formik.values.account_type == "post_paid") {
        urlParamss.cus_account_type = 0;
      }
    }
    if (formik.values.account_status === "active") {
      urlParamss.cus_status = 1;
    }
    if (formik.values.account_status === "inactive") {
      urlParamss.cus_status = 0;
    }
    if (formik.values.account_status === "blocked") {
      urlParamss.cus_status = 3;
    }
    if (formik.values.account_status === "restricted") {
      urlParamss.cus_status = 2;
    }
    const response =
      Object.keys(urlParamss)?.length > 0
        ? await downloadData(urlParamss).unwrap()
        : await downloadData().unwrap();
    return response?.data;
  };

  const handleSubmitForm = async () => {
    navigateSearch("/users", { page: currentPage });
    let urlParamss = {
      limit: 10,
      page: 1,
    };
    if (formik.values.cus_account_number) {
      urlParamss.cus_account_number = formik.values.cus_account_number;
    }
    if (formik.values.first_name) {
      urlParamss.cus_firstname = formik.values.first_name;
    }
    if (formik.values.last_name) {
      urlParamss.cus_lastname = formik.values.last_name;
    }
    if (formik.values.mobile_num) {
      urlParamss.cus_phone_number = formik.values.mobile_num;
    }

    if (formik.values.email) {
      urlParamss.cus_email = formik.values.email;
    }
    if (formik.values.cus_company_name) {
      urlParamss.cus_company_name = formik.values.cus_company_name;
    }
    if (formik.values.account_type == "pre_paid" || formik.values.account_type == "post_paid") {
      if (formik.values.account_type == "pre_paid") {
        urlParamss.cus_account_type = 1;
      } else {
        urlParamss.cus_account_type = 0;
      }
    }
    if (
      formik.values.account_status == "active" ||
      formik.values.account_status == "inactive" ||
      formik.values.account_status == "blocked" ||
      formik.values.account_status == "restricted"
    ) {
      if (formik.values.account_status == "active") {
        urlParamss.cus_status = 1;
      }
      if (formik.values.account_status == "inactive") {
        urlParamss.cus_status = 0;
      }
      if (formik.values.account_status == "blocked") {
        urlParamss.cus_status = 3;
      }
      if (formik.values.account_status == "restricted") {
        urlParamss.cus_status = 2;
      }
    }

    try {
      const response = await getCustomerInfo(urlParamss).unwrap();
      navigateSearch("/users", { page: currentPage });
      setCustomerInfo(response.data.rows);
      setCount(response?.data?.count);

    } catch (error) {
      if (!error.response || !error.response.status) {
        console.error("Network error:", error.message);
      } else {
        console.error("Request failed with status:", error.response.status);
      }
      setCustomerInfo(null);
      setCount(null);
    }
  };

  useEffect(() => {
    // handleSubmitForm();
    if (localStorage.getItem("cus_id")) {
      localStorage.removeItem("cus_id");
    }
    dispatch(resetCustomerDetails());
  }, []);

  // useEffect(() => {
  //   handleSubmitForm(1);
  //   dispatch(resetCustomerDetails());
  // }, []);
  const rows =
    customerInfo &&
    customerInfo?.map((item) => {
      const isNetworkStatus = item.cus_network_status == 1;
      // const isTwoFactorAuth = item.cus_2fa_auth === "0" ? false : true;
      let accountStatus = "";
      if (item.cus_status == 1) {
        accountStatus = "Active";
      }
      if (item.cus_status == 0) {
        accountStatus = "Inactive";
      }
      if (item.cus_status == 3) {
        accountStatus = "Blocked";
      }
      if (item.cus_status == 2) {
        accountStatus = "restricted";
      }

      const handleCheckSingle = (cus_id) => {
        const newCheckedItems = [...checkedItems];
        const currentIndex = newCheckedItems.indexOf(cus_id);

        if (currentIndex === -1) {
          newCheckedItems.push(cus_id);
        } else {
          newCheckedItems.splice(currentIndex, 1);
        }

        setCheckedItems(newCheckedItems);
      };

      const cus_id = item;
      const editRedirect = (item) => {
        navigateSearch(`edit-user/${item}`);
      };
      dispatch(setID(cus_id));
      return (
        <>
          <TableRow key={item.cus_id}>
            <TableCell>
              <Tooltip title={item.name || "-"} arrow>
                <span>{item.name || "-"}</span>
              </Tooltip>
            </TableCell>
            <TableCell>
              <Tooltip title={item.email} arrow>
                <span>{item.email?.length > 25 ? `${item.email.slice(0, 25)}...` : item.email}</span>
              </Tooltip>
            </TableCell>
            <TableCell>
              <Tooltip title={item.cb_Role?.name || "-"} arrow>
                <span>{item.cb_Role?.name || "-"}</span>
              </Tooltip>
            </TableCell>
            <TableCell>
              {permission ? (
                <>
                  {permission.includes("users:create") || permission.includes("users:delete") ? (
                    <>
                      {permission.includes("users:create") && (
                        <Tooltip title="Edit" arrow>
                          <IconButton onClick={() => editRedirect(item.id)}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {permission.includes("users:delete") && (
                        <Tooltip title="Delete" arrow>
                          <IconButton onClick={() => deleteModal(item.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </>
                  ) : (
                    '-'
                  )}
                </>
              ) : (
                '-'
              )}

            </TableCell>
          </TableRow>
        </>
      );
    });
  const getCustomerOnload = async () => {
    try {
      const response = await getCustomerInfo({
        limit: 10,
        page: 1,
      }).unwrap();

      setPerPage(10);
      setCurrentPage(1);
      setCount(response?.data?.pagination?.totalUsers);
      setCustomerInfo(response.data?.users);
      setPaginationObject(response?.data?.pagination)

      if (checkedItems.length > 0) {
        setCheckedItems([]);
      }
    } catch (error) {
      console.log(error);
    } finally { setIsChecked(false) }
  };
  const getCustomerMultipleRecords = async (perPage, currentPage) => {
    let urlParamss = {
      limit: perPage,
      page: currentPage,
    };
    if (formik.values.cus_account_number) {
      urlParamss.cus_account_number = formik.values.cus_account_number;
    }
    if (formik.values.first_name) {
      urlParamss.cus_firstname = formik.values.first_name;
    }
    if (formik.values.last_name) {
      urlParamss.cus_lastname = formik.values.last_name;
    }
    if (formik.values.mobile_num) {
      urlParamss.cus_phone_number = formik.values.mobile_num;
    }

    if (formik.values.email) {
      urlParamss.cus_email = formik.values.email;
    }
    if (formik.values.cus_company_name) {
      urlParamss.cus_company_name = formik.values.cus_company_name;
    }
    if (formik.values.account_type == "pre_paid" || formik.values.account_type == "post_paid") {
      if (formik.values.account_type == "pre_paid") {
        urlParamss.cus_account_type = 1;
      } else {
        urlParamss.cus_account_type = 0;
      }
    }
    if (
      formik.values.account_status == "active" ||
      formik.values.account_status == "inactive" ||
      formik.values.account_status == "blocked" ||
      formik.values.account_status == "restricted"
    ) {
      if (formik.values.account_status == "active") {
        urlParamss.cus_status = 1;
      }
      if (formik.values.account_status == "inactive") {
        urlParamss.cus_status = 0;
      }
      if (formik.values.account_status == "blocked") {
        urlParamss.cus_status = 3;
      }
      if (formik.values.account_status == "restricted") {
        urlParamss.cus_status = 2;
      }
    }
    try {
      const response = await getCustomerInfo(urlParamss).unwrap();
      setCount(response?.data?.pagination?.totalUsers);
      setCustomerInfo(response.data?.users);
      if (checkedItems.length > 0) {
        setCheckedItems([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckAll = () => {
    if (checkedItems.length === rows.length) {
      setCheckedItems([]);
    } else {
      const allCusIds = customerInfo.map((item) => item.cus_id);
      setCheckedItems(allCusIds);
    }
  };

  const handleDiscount = async (values) => {
    const body = {
      customer_ids: checkedItems,
      discount: [
        {
          discount_product_name: "SIP Trunk",
          discount_type: values.sip_trunk ? values.sip_trunk : "Fixed",
          discount_value: Number(values.sip_trunk_margin),
        },
        {
          discount_product_name: "International DID",
          discount_type: values.international_did ? values.international_did : "Fixed",
          discount_value: Number(values.international_did_margin),
        },
        {
          discount_product_name: "National DID",
          discount_type: values.national_did ? values.national_did : "Fixed",
          discount_value: Number(values.national_did_margin),
        },
      ],
    };
    try {
      const response = await customerBulkDiscount(body).unwrap();
      suiSnackbar(response.message, "success");
      setOpen(false);
      setCheckedItems([]);
      discountFormik.handleReset();
    } catch (error) {
      console.log(error);
      suiSnackbar(error.data.message, "error");
    }
  };
  const handlePostPaidSelection = () => { };
  useEffect(() => {
    getCustomerOnload()
  }, []);

  const handleSearchFunc = async (e) => {
    setSearchParams(e.target.value)
    if (e.target.value.length > 2) {
      setIsChecked(true)
      try {
        const response = await getCustomerInfo({ search: e.target.value, page: 1, limit: 10, })
        setCustomerInfo(response?.data?.data?.users)
        setCount(response?.data?.data?.pagination?.totalUsers);
        setPaginationObject(response?.data?.data?.pagination)
        setPerPage(10);
        setCurrentPage(1);
      } catch (error) {
        console.log(error)
      } finally { setIsChecked(false) }
    }

    if (e.target.value.length === 0) {
      setIsChecked(true)
      const response = await getCustomerInfo({ search: e.target.value, page: 1, limit: 10, })
      setCustomerInfo(response?.data?.data?.users)
      setCurrentPage(1)
      setCount(response?.data?.data?.pagination?.totalUsers);
      setPaginationObject(response?.data?.data?.pagination)
      setIsChecked(false)
    }
  }
  return (
    <>
      <SuiBox my={3}>
        <SuiDialog
          open={open}
          onClose={() => setOpen(false)}
          fullWidth
          maxWidth="lg"
          haveCloseIcon
          closeByBackdrop
        >
          <SuiBox px={{ xs: 1.5, lg: 2 }} py={2}>
            <SuiTypography fontWeight="semiBold" variant="h6">
              {`Add Discount for ${checkedItems.length} Customers `}
            </SuiTypography>
          </SuiBox>
          <hr style={{ marginTop: 0, marginBottom: 0 }} />
          <SuiBox sx={{ overflow: "clip overlay" }}>
            <Grid container px={{ xs: 1.5, lg: 2 }} py={1} columnSpacing={2} rowSpacing={1}>
              <Grid item xs={12} sm={4.5} xl={5}>
                <FormLabel>Product</FormLabel>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  inputProps={{
                    readOnly: true,
                  }}
                  type="text"
                  placeholder="Product"
                  name="discount_type"
                  value={"SIP Trunk"}
                />
              </Grid>
              <Grid item xs={12} sm={4.5} xl={5}>
                <FormLabel>Discount type</FormLabel>
                <DiscountTypeDropdown
                  name="sip_trunk"
                  value={discountFormik.values.sip_trunk}
                  setValue={discountFormik.handleChange}
                />
                <ErrorMessageComponent
                  touched={discountFormik.touched.sip_trunk}
                  error={discountFormik.errors.sip_trunk}
                />
              </Grid>
              <Grid item xs={12} sm={3} xl={2}>
                <FormLabel>Value</FormLabel>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="text"
                  placeholder="0"
                  name="sip_trunk_margin"
                  value={discountFormik.values.sip_trunk_margin}
                  onChange={discountFormik.handleChange}
                  onBlur={discountFormik.handleBlur}
                />
                <ErrorMessageComponent
                  touched={discountFormik.touched.sip_trunk_margin}
                  error={discountFormik.errors.sip_trunk_margin}
                />
              </Grid>
            </Grid>
            <Grid container px={{ xs: 1.5, lg: 2 }} py={1} columnSpacing={2} rowSpacing={1}>
              <Grid item xs={12} sm={4.5} xl={5}>
                <FormLabel>Product</FormLabel>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  inputProps={{
                    readOnly: true,
                  }}
                  type="text"
                  placeholder="Product"
                  name="discount_type"
                  value={"International DID"}
                />
              </Grid>
              <Grid item xs={12} sm={4.5} xl={5}>
                <FormLabel>Discount type</FormLabel>
                <DiscountTypeDropdown
                  name="international_did"
                  value={discountFormik.values.international_did}
                  setValue={discountFormik.handleChange}
                />
                <ErrorMessageComponent
                  touched={discountFormik.touched.international_did}
                  error={discountFormik.errors.international_did}
                />
              </Grid>
              <Grid item xs={12} sm={3} xl={2}>
                <FormLabel>Value</FormLabel>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="text"
                  placeholder="0"
                  name="international_did_margin"
                  value={discountFormik.values.international_did_margin}
                  onChange={discountFormik.handleChange}
                  onBlur={discountFormik.handleBlur}
                />
                <ErrorMessageComponent
                  touched={discountFormik.touched.international_did_margin}
                  error={discountFormik.errors.international_did_margin}
                />
              </Grid>
            </Grid>
            <Grid container px={{ xs: 1.5, lg: 2 }} py={1} columnSpacing={2} rowSpacing={1}>
              <Grid item xs={12} sm={4.5} xl={5}>
                <FormLabel>Product</FormLabel>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  inputProps={{
                    readOnly: true,
                  }}
                  type="text"
                  placeholder="Product"
                  name="discount_type"
                  value={"National DID"}
                />
              </Grid>
              <Grid item xs={12} sm={4.5} xl={5}>
                <FormLabel>Discount type</FormLabel>
                <DiscountTypeDropdown
                  name="national_did"
                  value={discountFormik.values.national_did}
                  setValue={discountFormik.handleChange}
                />
                <ErrorMessageComponent
                  touched={discountFormik.touched.national_did}
                  error={discountFormik.errors.national_did}
                />
              </Grid>
              <Grid item xs={12} sm={3} xl={2}>
                <FormLabel>Value</FormLabel>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="text"
                  placeholder="0"
                  name="national_did_margin"
                  value={discountFormik.values.national_did_margin}
                  onChange={discountFormik.handleChange}
                  onBlur={discountFormik.handleBlur}
                />
                <ErrorMessageComponent
                  touched={discountFormik.touched.national_did_margin}
                  error={discountFormik.errors.national_did_margin}
                />
              </Grid>
            </Grid>
            <Grid container px={{ xs: 1.5, lg: 2 }} py={2} columnSpacing={2} rowSpacing={1}>
              <Grid item>
                <SuiButton
                  type="reset"
                  size="small"
                  variant="outlined"
                  buttonColor="dark"
                  sx={{ px: "1rem", py: "0.4rem" }}
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </SuiButton>
              </Grid>
              <Grid item>
                <SuiButton
                  type="submit"
                  size="small"
                  buttonColor="primary"
                  sx={{ px: "1rem" }}
                  onClick={discountFormik.handleSubmit}
                >
                  Add
                </SuiButton>
              </Grid>
            </Grid>
          </SuiBox>
        </SuiDialog>
        <Card sx={{ width: "100%" }}>
          <TableToolbar title={"Users Listing"}></TableToolbar>
          <hr style={{ marginTop: 0, marginBottom: 0, borderColor: "#E9E9E966" }} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }} m={2} >
            <SuiInput
              inputSettungs={{ autoComplete: "off" }}
              // isIconVisible="true"
              type="text"
              placeholder="Search"
              name="discount_type"
              value={searchParams}
              onChange={handleSearchFunc}
              customClass='inputWithIcon'
              withIcon={{
                baseClass: "outlined",
                svg: <SearchIcon sx={{ color: '#999999', width: '16px', height: '14px', paddingRight: 0 }} />,
                direction: "left",
              }}
            />
            {permission && permission.includes("users:create") &&
              <SuiButton
                component={Link}
                size="small"
                buttonColor="info"
                sx={{ fontWeight: "500", px: "12px", ml: 2 }}
                to={"/users/add-user"}
                onClick={doCancel}
              >
                Add User
              </SuiButton>
            }
          </Box>
          <EnhancedTable
            tableMinWidth={1000}
            tablehead={CustomerTableHeader}
            rowsData={rows}
            isLoading={isChecked}
            overFlow='hidden !important'
          />
          <SuiDialog open={openModal} onClose={() => setOpenModal(false)} haveCloseIcon>
            <DialogContent
              sx={{
                fontWeight: "semiBold",
              }}
            >
              Are you sure you want delete this user
              <Grid container rowSpacing={2}>
                <Grid item xs={12} sx={{ marginTop: "20px" }}>
                  <Grid container justifyContent={"end"} spacing={2}>
                    <Grid item xs={12} sm="auto">
                      <SuiButton
                        size="small"
                        sx={{ px: "1rem" }}
                        onClick={() => setOpenModal(false)}
                        buttonColor="dark"
                        variant="outlined"
                        fullWidth
                      >
                        Cancel
                      </SuiButton>
                    </Grid>
                    <Grid item xs={12} sm="auto">
                      <SuiButton
                        size="small"
                        sx={{ px: "1rem" }}
                        buttonColor="info"
                        fullWidth
                        onClick={() => handleDeleteIconClick(deleteID)}
                      >
                        {loader ? <CircularProgress size="20px" color="inherit" /> : 'Delete'}
                      </SuiButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
          </SuiDialog>

          {!isChecked && (
            <Grid
              container
              p={3}
              spacing={1.5}
              flexDirection={{ xs: "column", md: "row" }}
              justifyContent={{ xs: "center", md: "space-between" }}
              alignItems="center"
            >
              <Grid item>
                <SuiTypography fontWeight='light' fontSize={14} variant="h6" >{getPageRangeText(paginationObject)} </SuiTypography>
              </Grid>

              <Grid item>
                <CommonPagination
                  mt={0}
                  totalData={count}
                  pageSize={perPage}
                  currentPage={Number(currentPage)}
                  onPageChange={handlePageChange}
                />
              </Grid>
            </Grid>
          )}
        </Card>
      </SuiBox>
    </>
  );
};

export default CustomerTable;
