import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import SuiBox from "components/SuiBox";
import PageLayout from "Container/LayoutContainers/PageLayout";
import styles from "layouts/authentication/CoverLayout/styles";
import Logo from "assets/images/answering-legal-web-logo-white.png";

function CoverLayout({ image, children }) {
  const classes = styles({ image });

  console.log(classes.pageLayout)

  return (

    <PageLayout customClass={classes.pageLayout} background="white">
         <SuiBox my={4} display="flex" justifyContent="center">
                  <SuiBox component="img" src={Logo} alt="Answering Legal Logo" customClass={classes.logo} />
                </SuiBox>
      <Grid container justifyContent="center" alignItems="center" className={classes.coverLayout}>
        <Grid item xs={12} sm={8} lg={6}>
          <SuiBox className={classes.coverLayout_formBox}>
            <SuiBox className={classes.coverLayout_item}>
              <SuiBox p={0}>
              </SuiBox>
              <SuiBox component="form" autoComplete="off" pt={1} pb={2} px={0}>
                {children}
              </SuiBox>
            </SuiBox>
          </SuiBox>
        </Grid>
      </Grid>
    </PageLayout>
  );
}

CoverLayout.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default CoverLayout;
