import PropTypes from "prop-types";
import Link from "@mui/material/Link";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import typography from "assets/theme/base/typography";
import styles from "Container/Footer/styles";

const Footer = ({ company, links }) => {
  const { size } = typography;
  const classes = styles();
  const currentYear = new Date().getFullYear();

  const renderLinks = () =>
    links.map((link) => (
      <SuiBox key={link.name} component="li" px={2}>
        <Link href={link.href} target="_blank">
          <SuiTypography variant="button" fontWeight="regular" textColor="text">
            {link.name}
          </SuiTypography>
        </Link>
      </SuiBox>
    ));

  return (
    ''
  );
};

Footer.defaultProps = {
  company: "Answering Legal",
};

Footer.propTypes = {
  company: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
