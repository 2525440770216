export const answersData = [
    {
        "id": 1,
        "value": "No_Answer",
        'name': 'No Answer'
    },
    {
        "id": 2,
        "value": "Input_box",
        "name": "Input Box"
    },
    {
        "id": 3,
        "value": "Selectable_Option",
        'name': 'Selectable Option'
    },
    {
        "id": 4,
        "value": "Searchable_Dropdown",
        "name": "Drop Down"
    },
    // {
    //     "id": 5,
    //     "value": "AI_answer_field",
    //     'name': 'AI Analyser'
    // },
    // {
    //     "id": 6,
    //     "value": "Calender",
    //     "name": "Calender"
    // },
    {
        "id": 7,
        "value": "End_Chat",
        "name": "End Chat"
    },
    {
        "id": 8,
        "value": "Lead_Collection_Form",
        "name": "Lead Collection Form"
    },

]
export const answersDataWithCalender = [
    {
        "id": 1,
        "value": "No_Answer",
        'name': 'No Answer'
    },
    {
        "id": 2,
        "value": "Input_box",
        "name": "Input Box"
    },
    {
        "id": 3,
        "value": "Selectable_Option",
        'name': 'Selectable Option'
    },
    {
        "id": 4,
        "value": "Searchable_Dropdown",
        "name": "Drop Down"
    },
    // {
    //     "id": 5,
    //     "value": "AI_answer_field",
    //     'name': 'AI Analyser'
    // },
    {
        "id": 6,
        "value": "Calender",
        "name": "Calendar"
    },
    {
        "id": 7,
        "value": "End_Chat",
        "name": "End Chat"
    },

]