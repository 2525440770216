import { makeStyles } from "@mui/styles";

export default makeStyles(({ functions, breakpoints, transitions }) => {
  const { pxToRem } = functions;

  return {
    layoutContainer: {
      position: "relative",
      padding: `${pxToRem(16)} ${pxToRem(10)}`,
      backgroundColor: "#FAFBFD",

      [breakpoints.up("xl")]: {
        padding: pxToRem(24),
        marginLeft: ({ direction, miniSidenav }) => {
          if (direction === "ltr") {
            return miniSidenav ? pxToRem(0) : pxToRem(250);
          }

          return false;
        },
        marginRight: ({ direction, miniSidenav }) => {
          if (direction === "rtl") {
            return miniSidenav ? pxToRem(0) : pxToRem(250);
          }

          return false;
        },
        transition: transitions.create(["margin-left", "margin-right"], {
          easing: transitions.easing.easeInOut,
          duration: transitions.duration.standard,
        }),
      },
    },
  };
});
