import React from "react";
import { Grid, FormLabel, CardMedia, Typography } from "@mui/material";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import SuiBox from "components/SuiBox";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";

export const Close = ({ onClose }) => (
  <SuiBox
    sx={{
      bgcolor: "#FC3C3C !important",
      position: "absolute",
      width: 16,
      height: 16,
      top: -8,
      right: -8,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50rem",
      cursor: "pointer",
    }}
    onClick={onClose}
  >
    <CloseIcon sx={{ width: 12, color: "#fff" }} />
  </SuiBox>
);

const SuiFileUploadInput = ({ id, label, accept, getFile, src, required = true,setCsvFile }) => {
  const [image, setImage] = React.useState(null);
  const [fileName, setFileName] = React.useState("");
  const [error, setError] = React.useState("");

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type !== "text/csv") {
        setError("Please upload a valid CSV file.");
        return;
      }
      setError("");
      const reader = new FileReader();
      reader.onloadend = () => {
        getFile(file);
        setImage(reader.result);
        setFileName(file.name);
        setCsvFile(file)
      };
      reader.readAsDataURL(file);
    }
  };

  const removeFile = () => {
    const fileInput = document.getElementById(id);
    if (fileInput) {
      fileInput.value = "";
    }
    getFile(null);
    setImage(null);
    setFileName("");
    setError("");
  };

  return (
    <>
      {label && (
        <FormLabel htmlFor={id} required={required}>
          {label}
        </FormLabel>
      )}
      <Grid container spacing={2}>
        <Grid item sx={{ flexGrow: 1 }}>
          {image || src ? (
            <SuiBox
              position="relative"
              borderRadius="5px"
              width="100%"
              my={2}
              p={1}
              sx={{ bgcolor: "#f4f4f4 !important" }}
            >
              <Close onClose={removeFile} />
              <Typography variant="body2" mt={1} sx={{ textAlign: "center" }}>
                {fileName}
              </Typography>
            </SuiBox>
          ) : (
            <SuiBox display="flex" title={"No file chosen"}>
              <SuiInput
                value=""
                type="text"
                placeholder="Upload File"
                inputProps={{
                  readOnly: true,
                  sx: { "&:focus": { border: "0.125rem solid #D3E0E5" } },
                }}
                sx={{ flexGrow: 1 }}
              />
              <SuiButton
                buttonColor="primary"
                size="small"
                sx={{
                  ml: -1.5,
                  px: 2,
                  fontWeight: 400,
                  backgroundColor: '#161740 !important',
                  "&:hover": { transform: "none" },
                }}
                component="label"
                htmlFor={id}
              >
                Choose File
              </SuiButton>
            </SuiBox>
          )}
        </Grid>
      </Grid>

      <SuiInput
        id={id}
        type="file"
        inputProps={{
          sx: { display: "none" },
          hidden: true,
          required: required,
          accept: accept,
        }}
        onChange={handleFileUpload}
      />

      {error && (
        <Typography color="error" variant="body2" mt={1}>
          {error}
        </Typography>
      )}
    </>
  );
};

SuiFileUploadInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  accept: PropTypes.string,
  getFile: PropTypes.func.isRequired,
  src: PropTypes.string,
  required: PropTypes.bool,
};

export default SuiFileUploadInput;
