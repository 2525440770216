import { CalendarIcon, DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box } from "@mui/material";
import colors from "../../assets/theme/base/colors";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";

const { inputColors, white, transparent } = colors;
const styles = makeStyles(() => ({
  inputBase: {
    "--bg-color": inputColors.borderColor.main,
    borderRadius: "8px",
    borderWidth: "0.125rem",
    borderStyle: "solid",
    borderColor: inputColors.borderColor.main,
    backgroundColor: `var(--bg-color) !important`,
    overflow: "hidden",
    "& input": {
      backgroundColor: `var(--bg-color) !important`,
      "&:-webkit-autofill": {
        WebkitBoxShadow: `inset 0rem 0rem 0rem 1.875rem var(--bg-color) !important`,
      },
    },
    "& .MuiInputBase-root": {
      backgroundColor: `${transparent.main} !important`,
    },
    "& .MuiIconButton-root": {
      backgroundColor: "#fff",
      padding: 0,
      marginRight: 3,
      width: "32px",
      height: "32px",
      boxShadow: "1px 2px 0 0 #D9DBE7",
    },
    "&:focus-within": {
      borderColor: inputColors.borderColor.focus,
      backgroundColor: `${white.main} !important`,
      "& input": {
        backgroundColor: `${white.main} !important`,
        "&:-webkit-autofill": {
          WebkitBoxShadow: "inset 0rem 0rem 0rem 1.875rem #fff !important",
        },
      },
    },
    "&:disabled": {
      cursor: "not-allowed",
      pointerEvents: "none",
    },
  },
  datePaper: {
    padding: 0,
  },
}));

const DatePickerUi = ({
  name,
  value,
  getValue,
  placeholder,
  required = false,
  readOnly = false,
  disabled = false,
  ...rest
}) => {
  const classes = styles();
  return (
    <Box data-disabled={Boolean(disabled)} className={classes.inputBase}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          format="YYYY-MM-DD"
          reduceAnimations
          disabled={Boolean(disabled)}
          readOnly={Boolean(readOnly)}
          slots={{
            openPickerIcon: CalendarIcon,
          }}
          slotProps={{
            textField: {
              id: name,
              name,
              inputProps: {
                placeholder,
                readOnly,
                disabled,
                required,
                style: {
                  border: 0,
                  borderRadius: 0,
                },
              },
            },
            openPickerIcon: {
              style: {
                color: "#80E6DC",
              },
            },
          }}
          {...rest}
          value={value}
          onChange={(date) => {
            if (date) {
              const formattedDate = dayjs(date).format("YYYY-MM-DD");
              getValue(formattedDate);
            }
          }}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default DatePickerUi;
