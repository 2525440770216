import React from 'react';

const SubscriptionPlanComponent = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path 
        d="M17.4665 2.67505H7.95656C6.79769 2.67505 6.21826 2.67505 5.75094 2.83766C4.86477 3.146 4.16903 3.86224 3.8695 4.77451C3.71155 5.2556 3.71155 5.8521 3.71155 7.04511V21.0492C3.71155 21.9075 4.69655 22.3628 5.31964 21.7927C5.68572 21.4577 6.23737 21.4577 6.60345 21.7927L7.08655 22.2347C7.72813 22.8218 8.69496 22.8218 9.33655 22.2347C9.97813 21.6476 10.945 21.6476 11.5865 22.2347C12.2281 22.8218 13.195 22.8218 13.8365 22.2347C14.4781 21.6476 15.445 21.6476 16.0865 22.2347C16.7281 22.8218 17.695 22.8218 18.3365 22.2347L18.8196 21.7927C19.1857 21.4577 19.7374 21.4577 20.1035 21.7927C20.7266 22.3628 21.7115 21.9075 21.7115 21.0492V7.04511C21.7115 5.8521 21.7115 5.2556 21.5536 4.77451C21.2541 3.86224 20.5583 3.146 19.6722 2.83766C19.2048 2.67505 18.6254 2.67505 17.4665 2.67505Z" 
        stroke="#999999" 
        strokeWidth="1.5"
        fill="none"
      />
      <path 
        d="M10.2115 11.075L11.6401 12.675L15.2115 8.67505" 
        stroke="#999999" 
        strokeWidth="1.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
      <path 
        d="M8.21155 16.175H17.2115" 
        stroke="#999999" 
        strokeWidth="1.5" 
        strokeLinecap="round"
      />
    </svg>
  );
};

export default SubscriptionPlanComponent;
