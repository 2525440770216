import moment from "moment";
import { getCountryCallingCode, getCountries } from "react-phone-number-input";
import { useSearchParams } from "react-router-dom";
import en from "react-phone-number-input/locale/en.json";

// Converts a string to a hexadecimal color code
const stringToColor = (string) => {
  let hash = 0;
  for (let i = 0; i < string?.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

// Generates avatar properties for Material-UI Avatar component
export const stringAvatar = (name, image, size, font) => ({
  sx: {
    bgcolor: image ? "transparent" : `${stringToColor(name)}44`,
    color: stringToColor(name),
    width: `${size || "40px"}`,
    height: `${size || "40px"}`,
    fontSize: `${font || "17px"}`,
  },
  children: `${name?.split(" ")[0][0]?.toUpperCase()}${name
    ?.split(" ")[name?.split(" ").length > 1 ? 1 : 0][0]?.toUpperCase()}`,
});

// Capitalizes the first letter of each word in a string
export const capitalize = (word) => 
  word?.toLowerCase()
    ?.split(" ")
    ?.map((text) => text ? text[0].toUpperCase() + text.substring(1) : "")
    ?.join(" ");

// Formats a date string (YYYYMMDD) into a human-readable date (DD/MM/YYYY)
export const formatDates = (inputDate) => {
  const year = inputDate.substring(0, 4);
  const month = inputDate.substring(4, 6);
  const day = inputDate.substring(6, 8);
  const date = new Date(`${year}-${month}-${day}`);
  const formattedDay = String(date.getDate()).padStart(2, "0");
  const formattedMonth = String(date.getMonth() + 1).padStart(2, "0");
  const formattedYear = date.getFullYear();
  return `${formattedDay}/${formattedMonth}/${formattedYear}`;
};

// Converts an image string to a base64-encoded URL
export const imageBase = (image) => 
  !image ? "" : image.includes("base64,") ? image : `data:image/jpeg;base64,${image}`;

// Ensures a number is two digits, prefixing with zero if necessary
export const setDigits = (num) => num ? (num <= 9 ? `0${num}` : num) : "--";

// Returns properties of the first object that are not present in the second object
export const getNewObjectProperties = (prevObj, newObj) => 
  prevObj.filter(obj => !newObj.some(obj2 => JSON.stringify(obj) === JSON.stringify(obj2)));

// Returns new properties or changes between two objects
export const getNewProperties = (prevObj, newObj) => {
  const resultObject = {};
  for (const key in prevObj) {
    if (prevObj[key] !== newObj[key]) {
      resultObject[key] = newObj[key]?.length > 0 || !isNaN(newObj[key]) || (newObj[key] && typeof newObj[key] === "object")
        ? newObj[key]
        : null;
    }
  }
  if (resultObject["mobile_no"]) {
    resultObject["country_code"] = newObj.country_code;
  }
  return resultObject;
};

// Checks if a value is not empty or null
export const checkIfEmpty = (value) => (value && value.length > 0 ? value : null);

// Checks if an object is empty
export const isObjectEmpty = (objectName) => Object.keys(objectName).length === 0;

// Extracts search query parameters from the URL
export const getSearchQueryParams = () => {
  // const [searchParams] = useSearchParams();
  const searchParams = {};
  let allSearchParams = {};
  for (const [param, value] of searchParams.entries()) {
    allSearchParams[param] = isNaN(+value) ? value : +value;
  }
  return allSearchParams;
};

// Removes HTML tags from a string
export const removeTags = (str) => str ? str.toString().replace(/(<([^>]+)>)/gi, "").trim() : false;

// Formats a JavaScript Date object into a YYYY-MM-DD string
export const formatDate = (date) => {
  const d = new Date(date);
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

// Generates a background color style object based on a string
export const bgColor = (name) => {
  let hash = 0;
  for (let i = 0; i < name?.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0x77;
    color += `f0${value.toString(16)}`.slice(-2);
  }

  return {
    p: "10px",
    objectFit: "contain",
    bgcolor: `${color}55`,
    borderRadius: "7px 7px 0 0",
    m: 0,
  };
};

// Prefixes a number with zero if it is less than 10
export const appendZero = (number) => number < 10 ? `0${number}` : number;

// Generates data from an object based on a specified key, formatting dates if necessary
export const generateData = (obj, valueKey) => 
  obj?.map(item => valueKey === "date" && !item[valueKey]?.split("-").every(isNaN) 
    ? moment(item[valueKey]).format("Do MMM YYYY") 
    : item[valueKey]);

// Generates a display label from an array of IDs and a display array
export const displayLabel = (apiArray, displayArray) => {
  const selectedJobTypeIds = apiArray.split(",").map(Number);
  const selectedJobTypes = displayArray.filter(jobType => selectedJobTypeIds.includes(jobType.id)).map(jobType => jobType.name);
  return selectedJobTypes.join(", ");
};

// Converts a color code to a URL-encoded format for use in SVGs
export const svgColorCode = (color) => color.replace("#", "%23");

// Generates breadcrumb headings with specific text transformations
export const breadcrumbsHeadingGen = (str) => {
  const splitArray = str?.toLowerCase()?.split(" ")?.map(text => text ? text[0].toUpperCase() + text.substring(1) : "");
  const specialCases = { Port: "Port-in", Did: "DID", Dids: "DIDs", Sip: "SIP", Cdr: "CDR" };

  for (let i = 0; i < splitArray.length; i++) {
    if (specialCases[splitArray[i]]) {
      splitArray[i] = specialCases[splitArray[i]];
      if (splitArray[i] === "Port-in") splitArray.splice(i + 1, 1);
    }
  }
  return splitArray.join(" ");
};

// Generates a sorted list of countries with their calling codes
export const countryCodes = getCountries()
  .sort((a, b) => en[a].localeCompare(en[b]))
  .map(country => ({
    countryShortCode: country,
    countryName: en[country],
    countryCallingCode: `+${getCountryCallingCode(country)}`,
  }));

// Extracts the country code from a string
export const getCountryCode = (countryCode) => countryCode.slice(countryCode.indexOf("+"));

// Finds the country short code for a given calling code
export const getCountryShortCode = (countryCode) => 
  countryCodes.find(obj => obj.countryCallingCode === countryCode)?.countryShortCode;
