import React, { useEffect, useState } from 'react';
import { Grid, Select, MenuItem, FormControl, Typography, styled } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useGetDefaultTemplateInfoMutation } from 'app/features/api/AuthApi';
import useSuiSnackbar from 'hooks/useSuiSnackbar';
import { useTemplateGetByIdMutation } from 'app/features/api/AuthApi';
import CreateTenantTemplate from './CreateTenantTemplate'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
    '&:not(.no-hover):hover': {
        backgroundColor: '#06174C',
        color: '#fff',
    },
    '&.Mui-selected': {
        backgroundColor: '#06174C !important',
        color: '#fff',
    },
    borderRadius: '4px !important',
    height: '33px'
}));

const TenantChatBot = () => {
    const [getDefaultTemplateInfo, { isLoading }] = useGetDefaultTemplateInfoMutation();
    const [templateGetById, { isLoading: loader }] = useTemplateGetByIdMutation();
    const [defaulttemplateData, setDefaulttemplateData] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const suiSnackbar = useSuiSnackbar();
    const [templateId, setTemplateId] = useState('')

    const fetchDefaultTemplates = async () => {
        try {
            const response = await getDefaultTemplateInfo();
            if (response.data.statusCode === 200) {
                const res = response?.data?.data?.templates?.map((item) => {
                    return { id: item.id, label: item.templateName };
                });
                setDefaulttemplateData(res);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const fetchDataById = async (id) => {
        try {
            const response = await templateGetById(id);
            if (response.data.statusCode === 200) {
                // Process the response data
            }
        } catch (err) {
            console.log(err);
            suiSnackbar('Error fetching template data', 'error');
        }
    };

    useEffect(() => {
        fetchDefaultTemplates();
    }, []);

    const handleChange = (event) => {
        const selectedId = event.target.value;
        setSelectedTemplate(selectedId);
        setTemplateId(selectedId)
        if (selectedId && selectedId !== 'createNew') {
            fetchDataById(selectedId);
        }
    };
    return (
        <Grid container>
            <Grid item xs={4} mt={2} >
                <FormControl fullWidth variant="outlined" >
                    <Select
                        displayEmpty
                        value={selectedTemplate}
                        onChange={handleChange}
                        style={{ borderRadius: 0 }}
                        sx={{
                            '&.MuiInputBase-root': {
                              '& .MuiInputBase-input': {
                               display:'flex !important'
                              },
                            },
                          }}
                    >
                        <CustomMenuItem value="" > Select Chatbot Templates </CustomMenuItem>
                        {defaulttemplateData.map((template) => (
                            <CustomMenuItem key={template.id} value={template.id}>
                                {template.label}
                            </CustomMenuItem>
                        ))}
                        <CustomMenuItem value="createNew">
                            <AddCircleOutlineRoundedIcon style={{ marginRight: 8 }} />
                            Create New
                        </CustomMenuItem>
                    </Select>
                </FormControl>
            </Grid>
            {selectedTemplate &&
                <Grid item xs={12} lg={12}>
                    <CreateTenantTemplate  id={selectedTemplate} />
                </Grid>
            }
        </Grid>
    );
};

export default TenantChatBot;
