import React from "react";
import PropTypes from "prop-types";
import { Card } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import colors from "assets/theme/base/colors";
import CommonSpinner from "components/CommonSpinner";
import SuiButton from "components/SuiButton";

const WrapCardCalendly = ({ title, children, isFetching = true, button, onClick, disabled, borderBottom = '1px solid #D3E0E5' }) => {
  return (
    <Card sx={{ my: "30px", borderRadius: '5px !important' }}>
      <SuiBox
        px={{ xs: 1, md: 2, lg: 3 }}
        py={2}
        sx={{ borderBottom: borderBottom, display: "flex", justifyContent: "space-between", alignItems: "center" }}
      >
        <SuiTypography fontSize="18px" fontWeight="semiBold">
          {title}
        </SuiTypography>
        {button && (
          <SuiButton
            size="small"
            onClick={onClick}
            disabled={disabled}
            buttonColor="info"
            sx={{ fontWeight: "500", px: "12px", backgroundColor: '#161740 !important' }}
          >
            {button}
          </SuiButton>
        )}
      </SuiBox>
      <SuiBox component="form" px={{ xs: 1, md: 2, lg: 3 }} py={2}>
        {isFetching ? (
          children
        ) : (
          <SuiBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="calc(100vh - 390px)"
          >
            <CommonSpinner size="100px" color={colors.primary.main} />
          </SuiBox>
        )}
      </SuiBox>
    </Card>
  );
};

WrapCardCalendly.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  isFetching: PropTypes.bool,
  button: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  borderBottom: PropTypes.string,
};

export default WrapCardCalendly;
