import { makeStyles } from "@mui/styles";

export default makeStyles(({ palette, transitions, typography, boxShadows, functions }) => {
  const { white, text2, gradients, transparent } = palette;
  const { fontWeightSemiBold, size } = typography;
  const { regular } = boxShadows;
  const { pxToRem } = functions;

  return {
    collapse_item: {
      background: ({ active }) => (active ? "#161740 !important" : transparent.main),
      display: "flex",
      width: "100%",
      padding: `${pxToRem(10.8)} ${pxToRem(8)}`,
      paddingLeft: pxToRem(24),
      margin: `0 ${pxToRem(8)}`,
      borderRadius: 4,
      cursor: "pointer",
      userSelect: "none",
      whiteSpace: "nowrap",
      boxShadow: "none",
    },

    collapse_iconBox: {
      "&.MuiListItemIcon-root": {
        minWidth: "32px !important",
        boxShadow: "none",
        color: ({ active }) => (active ? "#fff !important" : text2.focus),
      },
      borderRadius: 0,
      display: "grid",
      placeItems: "center",
      boxShadow: regular,
      transition: transitions.create("color", {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),

      "& svg, svg g": {
        fill: ({ active }) => (active ? "#fff" : text2.focus),
      },
    },

    collapse_icon: {
      color: ({ active }) => (active ? white.main : gradients.dark.state),
    },

    collapse_text: {
      marginLeft: pxToRem(12.8),

      "& span": {
        fontWeight: fontWeightSemiBold,
        fontSize: size.sm,
        lineHeight: 0,
        color: ({ active }) => (active ? "#fff !important" : text2.focus),
      },
    },
  };
});
