import { lazy } from "react";
import RootLayout from "layouts/RootLayout";
import RootOutlet from "layouts/RootLayout/RootOutlet";
import Dashboard from "layouts/dashboard";
import CommonCustomerModules from "layouts/customer/CommonCustomerModules";
// 
import LogIn from "layouts/authentication/log-in";
import LoginAuth from "layouts/authentication/log-in/LoginAuth";
import LoginWithPass from "layouts/authentication/log-in/Forms/LoginWithPass";
import EnterEmail from "layouts/authentication/log-in/Forms/EnterEmail";
import ForgetPassword from "layouts/authentication/log-in/Forms/ForgetPassword";

import TenantLogIn from "layouts/authentication/tenant-log-in";
import TenantLoginAuth from "layouts/authentication/tenant-log-in/TenantLoginAuth";
import TenantLoginWithPass from "layouts/authentication/tenant-log-in/Forms/LoginWithPass";
import TenantEnterEmail from "layouts/authentication/tenant-log-in/Forms/EnterEmail";
import TenantForgetPassword from "layouts/authentication/tenant-log-in/Forms/ForgetPassword";

import NotFound from "layouts/NotFound";
import CreateCustomer from "layouts/create-customer/CreateCustomer";
import AdminTable from "layouts/Admin/AdminTable";
import CreateAdmin from "layouts/Admin/Add-admin";
import EditAdmin from "layouts/Admin/Edit-admin";
import CreateRole from "layouts/roles-and-permission/CreateRole";
import Roles from "layouts/Roles/Roles";
import CreateTenant from "layouts/tenant/CreateTenant";
import CreateTemplate from "layouts/template/CreateTemplate";
import CreatePlan from "layouts/subscription-plan/CreatePlan";
import TenantCsvImport from "layouts/tenant/Import";
import TenantTemplateTable from "layouts/template/TenantTemplateTable";


const Profile = lazy(() => import("layouts/profile"));
const ProfileDetails = lazy(() => import("layouts/profile/components/ProfileDetails"));
const ChangePassword = lazy(() => import("layouts/profile/components/ChangePassword"));

const CustomerTable = lazy(() => import("layouts/customer/CustomerTable"));
const RolesAndPermissionTable = lazy(() => import("layouts/roles-and-permission/RolesAndPermissionTable"));
;
const EditCustomer = lazy(() => import("layouts/customer/components/EditCustomer"));
const Editintegration = lazy(() => import("layouts/integration/Editintegration"));
const LogOut = lazy(() => import("layouts/authentication/log-out"));

// TENANT
const TenantTable = lazy(() => import("layouts/tenant/TenantTable"));
const ChatbotTable = lazy(() => import("layouts/template/TemplateTable"));

// Subscrition Plan
const PlanTable = lazy(() => import("layouts/subscription-plan/PlanTable"));

// reports
const ReportTable = lazy(() => import("layouts/reports/ReportTable"));

const routes = [
  {
    // Tenant
    element: <TenantLoginAuth />,
    errorElement: <NotFound />,
    children: [
      {
        path: "/",
        element: <TenantLogIn />,
        children: [
          {
            index: true,
            element: <TenantLoginWithPass />,
          },
          {
            element: <TenantEnterEmail />,
            path: "/forgot-password",
          },
          {
            element: <TenantForgetPassword />,
            path: "/reset-password/:token",
          },
        ],
      },
    ],
  },
  {
    // Admin
    element: <LoginAuth />,
    errorElement: <NotFound />,
    children: [
      {
        path: "/admin-login",
        element: <LogIn />,
        children: [
          {
            index: true,
            element: <LoginWithPass />,
          },
          // {
          //   element: <EnterEmail />,
          //   path: "forgot-password",
          // },
          // {
          //   element: <ForgetPassword />,
          //   path: "reset-password",
          // },
        ],
      },
    ],
  },
  {
    path: "",
    element: <RootLayout />,
    errorElement: <NotFound />,
    protected: true,
    children: [
      {
        path: "/dashboard",
        element: <Dashboard />,
        index: true,
      },
      {
        path: "/users",
        element: <CommonCustomerModules />,
        children: [
          {
            element: <CustomerTable />,
            index: true,
          },
          {
            element: <CreateCustomer />,
            path: "add-user",
          },
          {
            element: <EditCustomer />,
            path: "edit-user/:id",
          },
        ],
      },
      {
        path: "/roles-and-permissions",
        element: <CommonCustomerModules />,
        children: [
          {
            element: <RolesAndPermissionTable />,
            index: true,
          },
          {
            element: <CreateRole />,
            path: "add-role",
          },
          {
            element: <CreateRole />,
            path: "edit-role/:id",
          },
        ],
      },
      {
        element: <CreateCustomer />,
        path: "/add-customer",
        index: true,
      },
      {
        path: "/roles",
        element: <Roles />,
        index: true,
      },
      {
        path: "/user-management",
        element: <RootOutlet />,
        children: [
          {
            element: <AdminTable />,
            index: true,
          },
          {
            element: <CreateAdmin />,
            path: "create-user",
          },
          {
            element: <EditAdmin />,
            path: "update-user",
          },
        ],
      },
      {
        path: "/tenant",
        element: <RootOutlet />,
        children: [
          {
            element: <TenantTable />,
            index: true,
          },
          {
            element: <CreateTenant />,
            path: "add-tenant",
          },
          {
            element: <CreateTenant />,
            path: "edit-tenant/:id",
          },
          {
            element: <TenantCsvImport />,
            path: "import",
          },
        ],
      },      
      {
        path: "/chatbot-template",
        element: <RootOutlet />,
        children: [
          {
            element: <ChatbotTable />,
            index: true,
          },
          {
            element: <CreateTemplate />,
            path: "add-template",
          },
          {
            element: <CreateTemplate />,
            path: "edit-template/:id",
          },
        ],
      },   
      {
        path: "/tenant-chatbot-template/:id",
        element: <RootOutlet />,
        children: [
          {
            element: <TenantTemplateTable />,
            index: true,
          },
        ],
      },     
      {
        path: "/subscription-plan",
        element: <RootOutlet />,
        children: [
          {
            element: <PlanTable />,
            index: true,
          },
          {
            element: <CreatePlan />,
            path: "add-plan",
          },
          {
            element: <CreatePlan />,
            path: "edit-plan/:id",
          },
        ],
      },  
      {
        path: "/reports",
        element: <RootOutlet />,
        children: [
          {
            element: <ReportTable />,
            index: true,
          },
        ],
      },  
      // {
      //   element: <Editintegration />,
      //   path: "/integration",
      //   index: true,
      // },    
      {
        path: "profile",
        element: <Profile />,
        children: [
          {
            element: <ProfileDetails />,
            index: true,
          },
          {
            element: <ChangePassword />,
            path: "change-password",
          },
        ],
      },
      {
        path: "/log-out",
        element: <LogOut />,
      },
    ],
  },
];

export default routes;
