import CustomerIcon from "Container/Icons/CustomerIcon";
import DashboardIcon from "Container/Icons/DashboardIcon";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SubscriptionPlanComponent from "Container/SubscriptionPlanIcon";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AccountIcon from "Container/Icons/AccountIcon";
import ChatbotIcon from "Container/Icons/ChatbotIcon";
import DescriptionIcon from '@mui/icons-material/Description';
const integration = localStorage.getItem('type')
console.log('Function Called');
const getSidenavData = (permissions, type) => [
  {
    id: 1,
    type: "link",
    label: "Dashboard",
    link: "/dashboard",
    icon: <DashboardIcon size={18} />,
    visible: type === 'tenant' ? true :  permissions.includes('dashboard:read'),
  },
  {
    id: 2,
    type: "collapse",
    label: "Sub Admin",
    icon: <AccountIcon size={18}  />,
    visible: permissions.includes('users:read') || permissions.includes("roles and permissions:read"),
    sub_menu: [
      {
        id: 2.1,
        order: 1,
        label: "Users",
        link: "/users",
        visible: permissions.includes('users:read'),
      },
      {
        id: 2.2,
        order: 2,
        label: "Roles & Permissions",
        link: "/roles-and-permissions",
        visible: permissions.includes('roles and permissions:read'),
      },
    ],
  },
  {
    id: 3,
    type: "link",
    label: "Tenant",
    link: "/tenant",
    icon: <PeopleAltOutlinedIcon sx={{ fontSize: 20}} fontSize="20"  />,
    visible: permissions.includes('tenant:read'),
  },
  {
    id: 4,
    type: "link",
    label: "Chatbot",
    link: "/chatbot-template",
    icon: <ChatbotIcon sx={{ fontSize: 20 }} fontSize="20"  />,
    visible: type === 'tenant' ? true :  permissions.includes('chatbot:read'),
  },
  // {
  //   id: 5,
  //   type: "link",
  //   label: "Integration",
  //   link: "/integration",
  //   icon: <SettingsOutlinedIcon sx={{ fontSize: 20, strokeWidth: 0.2 }} fontSize="20"  />,
  //   visible: permissions.includes("integration:create"),
  // },
  {
    id: 6,
    type: "link",
    label: "Subscription Plan",
    link: "/subscription-plan",
    icon: <SubscriptionPlanComponent  sx={{ fontSize: 20, strokeWidth: 0.2 }} fontSize="20"  />,
    visible: permissions.includes("subscription plan management:read"),
  },
  {
    id: 7,
    type: "collapse",
    label: "Reports",
    icon: <DescriptionIcon fontSize="20" sx={{ fontSize: 20}} />,
    visible: type === 'tenant' ? true :  permissions.includes('report:read') ,
    sub_menu: [
      {
        id: 7.1,
        order: 1,
        label: type === 'tenant' ? "API Report" : "Tenant Reports",
        link: "/reports",
        visible:  type === 'tenant' ? true : permissions.includes('report:read'),
      },
    ],
  },
];

export default getSidenavData;
