import pxToRem from "assets/theme/functions/pxToRem";

const DashboardIcon = ({ size }) => {
  return (
    <svg height={pxToRem(size)} viewBox="0 0 25 25" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.21155 7.16895C3.21155 4.95981 5.00241 3.16895 7.21155 3.16895C9.42069 3.16895 11.2115 4.95981 11.2115 7.16895C11.2115 9.37808 9.42069 11.1689 7.21155 11.1689C5.00241 11.1689 3.21155 9.37808 3.21155 7.16895Z" fill="none" strokeWidth="1.5" />
      <path d="M14.2115 18.1689C14.2115 15.9598 16.0024 14.1689 18.2115 14.1689C20.4207 14.1689 22.2115 15.9598 22.2115 18.1689C22.2115 20.3781 20.4207 22.1689 18.2115 22.1689C16.0024 22.1689 14.2115 20.3781 14.2115 18.1689Z" fill="none" strokeWidth="1.5" />
      <path d="M22.2115 7.16895C22.2115 5.28333 22.2115 4.34052 21.6258 3.75473C21.04 3.16895 20.0972 3.16895 18.2115 3.16895C16.3259 3.16895 15.3831 3.16895 14.7973 3.75473C14.2115 4.34052 14.2115 5.28333 14.2115 7.16895C14.2115 9.05456 14.2115 9.99737 14.7973 10.5832C15.3831 11.1689 16.3259 11.1689 18.2115 11.1689C20.0972 11.1689 21.04 11.1689 21.6258 10.5832C22.2115 9.99737 22.2115 9.05456 22.2115 7.16895Z" fill="none" strokeWidth="1.5" />
      <path d="M11.2115 18.1689C11.2115 16.2833 11.2115 15.3405 10.6258 14.7547C10.04 14.1689 9.09717 14.1689 7.21155 14.1689C5.32593 14.1689 4.38312 14.1689 3.79733 14.7547C3.21155 15.3405 3.21155 16.2833 3.21155 18.1689C3.21155 20.0546 3.21155 20.9974 3.79733 21.5832C4.38312 22.1689 5.32593 22.1689 7.21155 22.1689C9.09717 22.1689 10.04 22.1689 10.6258 21.5832C11.2115 20.9974 11.2115 20.0546 11.2115 18.1689Z" fill="none" strokeWidth="1.5" />
    </svg>
  );
};

export default DashboardIcon;
