import { CircularProgress, Grid, Icon, InputLabel } from "@mui/material";

import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import SuiPasswordInput from "components/SuiPasswordInput";
import SuiButton from "components/SuiButton";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { forgotPasswordSchema } from "Validation";
import ErrorMessageComponent from "components/FormikErrorMessage";
import { useState } from "react";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import { useResetPasswordMutation } from "app/features/api/AuthApi";
import LockIcon from "@mui/icons-material/Lock";
import { useParams } from 'react-router-dom';

const TenantForgetPassword = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const { token } = useParams();
  const snackbarFn = useSuiSnackbar();
  const [resetPassword] = useResetPasswordMutation();

  const formik = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
    },
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values) => {
      const body = {
        newPassword: values.password,
        confirmPassword: values.confirm_password,
        token: token,
      };
      try {
        const response = await resetPassword(body).unwrap();
        if (response.statusCode === 200) {
          formik.resetForm();
          setError("");
          navigate("/");
          snackbarFn(response?.message, "success");
        }
      } catch (error) {
        formik.resetForm();
        snackbarFn(error?.data.message, "error");
      }
    },
  });

  return (
    <>
      <SuiTypography component="h1" variant="formHeading">
        Reset New Password
      </SuiTypography>
      
      <SuiBox mt={3} mb={3.5}>
        <SuiBox mb={2.5}>
        <SuiBox mb={1}>
            <InputLabel required>New Password</InputLabel>
          </SuiBox>
          <SuiBox mb={2} style={{ position: "relative" }}>
            <LockIcon
              style={{
                fill: "none",
                stroke: "currentColor",
                strokeWidth: 2,
                position: "absolute",
                left: 10,
                top: 11,
                zIndex: 9,
                fontSize:"15px !important",
              }}
            />
            <SuiPasswordInput
              inputProps={{ pl: 4 }}
              index={1}
              attr={{ name: "password", placeholder: "Enter New Password" }}
              value={formik.values.password}
              onBlur={formik.handleBlur}
              getValue={formik.handleChange}
              isIconVisible="true"
            />
            <ErrorMessageComponent touched={formik.touched.password} error={formik.errors.password} />
            <SuiBox display="flex" alignItems="flex-start" pt={0.5}>
              {/* <Icon fontSize="small"
                sx={{ fontSize: "0.85rem !important", marginTop: "2px", color: "#6E828A" }}>
                info_outlined
              </Icon> */}
              {/* <SuiTypography
                fontWeight="medium"
                textColor="text2"
                sx={{ fontSize: "0.7rem" }}
                px={0.5}
                py={0}
              >
                Note: Password must be 7 to 20 characters long, contain at least one uppercase, one lowercase,, one number, one special character, and must not include any spaces.
                character.
              </SuiTypography> */}
            </SuiBox>
          </SuiBox>
        </SuiBox>

        <SuiBox mb={2.5}>
          <SuiBox mb={1}>
            <InputLabel required>Confirm New Password</InputLabel>
          </SuiBox>
          <SuiBox mb={2} style={{ position: "relative" }}>
            <LockIcon
              style={{
                fill: "none",
                stroke: "currentColor",
                strokeWidth: 2,
                position: "absolute",
                left: 10,
                top: 11,
                zIndex: 9,
                fontSize:"15px !important",
              }}
            />
            <SuiPasswordInput
              index={2}
              inputProps={{ pl: 4 }}
              attr={{ name: "confirm_password", placeholder: "Enter Confirm Password" }}
              value={formik.values.confirm_password}
              onBlur={formik.handleBlur}
              getValue={formik.handleChange}
              isIconVisible="true"
            />
            <ErrorMessageComponent
              touched={formik.touched.confirm_password}
              error={formik.errors.confirm_password}
            />
          </SuiBox>
        </SuiBox>
      </SuiBox>
      <Grid item sx={{ pb: 1 }}>
        {/* {Object.keys(formik.errors)?.length === 0 && error && ( */}
        <ErrorMessageComponent touched={error} error={error} />
        {/* )} */}
      </Grid>
      <SuiBox my={2}>
        <SuiButton
          tabIndex={3}
          // type="submit"
          // disabled={formik.isSubmitting || !formik.dirty}
          buttonColor="primary"
          fullWidth
          onClick={formik.handleSubmit}
        >
          {formik.isSubmitting ? (
            <CircularProgress size="25px" sx={{ color: "blue" }} />
          ) : (
            "Reset Password"
          )}
        </SuiButton>
      </SuiBox>
      <SuiTypography
        textAlign="center"
        component="p"
        buttonColor="primary"
        pt={2}
        textColor="text2"
        variant="formSubHeading"
      >
        Back to
        <SuiTypography
          component={Link}
          to="/"
          pl={1}
          textColor="info"
          fontWeight="semiBold"
          variant="formSubHeading"
          sx={{ "&.MuiTypography-root": { textDecoration: "underline !important" } }}
        >
          Sign in
        </SuiTypography>
      </SuiTypography>
    </>
  );
};
export default TenantForgetPassword;
