import { forwardRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Button from "@mui/material/Button";
import styles from "components/SuiButton/styles";
import { CircularProgress } from "@mui/material";

const SuiButton = forwardRef(
  (
    {
      buttonColor,
      variant,
      size,
      circular,
      iconOnly,
      isLoading = false,
      children,
      customClass,
      ...rest
    },
    ref
  ) => {
    const classes = styles({ buttonColor, variant, size, iconOnly });
    return (
      <Button
        {...rest}
        ref={ref}
        color="info"
        variant={variant === "gradient" ? "contained" : variant}
        size={size}
        className={clsx(classes[variant], customClass, {
          [classes.circular]: circular,
          [classes.iconOnly]: iconOnly,
        })}
      >
        {isLoading ? <CircularProgress size={20} color="white" /> : children}
      </Button>
    );
  }
);

SuiButton.defaultProps = {
  size: "medium",
  variant: "contained",
  buttonColor: "white",
  circular: false,
  iconOnly: false,
  customClass: "",
  
};

SuiButton.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  variant: PropTypes.oneOf(["text", "contained", "outlined", "gradient"]),
  buttonColor: PropTypes.oneOf([
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "text2",
  ]),
  circular: PropTypes.bool,
  iconOnly: PropTypes.bool,
  children: PropTypes.node.isRequired,
  customClass: PropTypes.string,
};

export default SuiButton;
