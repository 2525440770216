import colors from "../../base/colors";

import pxToRem from "../../functions/pxToRem";
import linearGradient from "../../functions/linearGradient";

const { transparent, white, text, success } = colors;

export default {
  styleOverrides: {
    root: {
      backgroundPosition: "center",
      backgroundSize: "contain",
      width: pxToRem(18),
      height: pxToRem(18),
      marginRight: pxToRem(6),
      padding: 0,
      color: transparent.main,
      border: "none",
      outline: `1.5px solid ${success.main}`,
      borderRadius: pxToRem(4),
      transition: "all 250ms ease",

      "&:hover": {
        backgroundColor: transparent.main,
      },

      "& .MuiSvgIcon-root": {
        fill: transparent.main,
        width: "auto",
        height: "auto",
      },

      "&.Mui-focusVisible": {
        outline: `2px solid ${success.main} !important`,
      },
    },

    colorPrimary: {
      backgroundColor: transparent.main,
      "&.Mui-checked": {
        backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -1 22 22'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"), ${linearGradient(
          success.main,
          success.main
        )}`,
        outlineColor: success.main,
      },
      "&.MuiCheckbox-indeterminate": {
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22px' height='22px' fill='%23fff' viewBox='0 0 16 16'%3E%3Cpath d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/%3E%3C/svg%3E"), ${linearGradient(
          success.main,
          success.main
        )}`,
        outlineColor: success.main,
      },

      "&:hover": {
        backgroundColor: white.main,
      },
    },

    colorSecondary: {
      backgroundColor: white.main,
      outlineColor: text.focus,
      "&.Mui-checked": {
        backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -1 22 22'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"), ${linearGradient(
          "#1264E2",
          "#1264E2"
        )}`,
        outlineColor: "#1264E2",
      },
      "&.MuiCheckbox-indeterminate": {
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22px' height='22px' fill='%23fff' viewBox='0 0 16 16'%3E%3Cpath d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/%3E%3C/svg%3E"), ${linearGradient(
          "#1264E2",
          "#1264E2"
        )}`,
        outlineColor: "#1264E2",
      },
      "&:hover": {
        backgroundColor: white.main,
      },
    },

    colorInfo: {
      backgroundImage: `url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='-4.1 -4.1 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.59375 1.27667V12.3383' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1.0625 6.8075H12.1241' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A"), ${linearGradient(
        "#1264E2",
        "#1264E2"
      )}`,
      outlineColor: "#1264E2",
      "&.Mui-checked": {
        backgroundImage: `url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='-4.1 -0.5 22 3' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.0625 1.14423H12.1241' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A"), ${linearGradient(
          "#C02B0A",
          "#C02B0A"
        )}`,
        outlineColor: "#C02B0A",
      },
      "&:hover": {
        backgroundColor: white.main,
      },
    },
  },
};
