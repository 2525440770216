import pxToRem from "assets/theme/functions/pxToRem";

const AccountIcon = ({ size }) => {
  return (
    <svg height={pxToRem(size)} viewBox="0 0 25 25" stroke="currentColor" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12.7115" cy="9.7749" r="3" fill="none" strokeWidth="1.5" />
      <circle cx="12.7115" cy="12.7749" r="10" fill="none" strokeWidth="1.5" />
      <path d="M18.6807 20.7749C18.5216 17.8834 17.6363 15.7749 12.7115 15.7749C7.78681 15.7749 6.90151 17.8834 6.74236 20.7749" fill="none" strokeWidth="1.5" stroke-linecap="round" />
    </svg>
  );
};

export default AccountIcon;
