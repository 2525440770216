import React from 'react'

const DashboardGreenIcon = () => {
    return (
        <>
            <svg width="22" height="22" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_613_4937)">
                    <circle cx="14.0457" cy="10.3589" r="9.93579" fill="#AEFF40" />
                    <circle cx="14.0457" cy="10.3589" r="8.93579" stroke="#86CC27" stroke-width="2" />
                </g>
                <g filter="url(#filter1_d_613_4937)">
                    <circle cx="14.0457" cy="10.3589" r="5.88452" fill="#86CC27" />
                    <circle cx="14.0457" cy="10.3589" r="5.38452" stroke="#DFFFB3" />
                </g>
                <defs>
                    <filter id="filter0_d_613_4937" x="0.109863" y="0.423157" width="27.8716" height="27.8716" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="2" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_613_4937" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_613_4937" result="shape" />
                    </filter>
                    <filter id="filter1_d_613_4937" x="4.16113" y="4.47443" width="19.769" height="19.769" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="2" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_613_4937" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_613_4937" result="shape" />
                    </filter>
                </defs>
            </svg>

        </>
    )
}

export default DashboardGreenIcon