import { useEffect, useLayoutEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import Icon from "@mui/material/Icon";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SidenavLink from "Container/Sidenav/SidenavLink";
import SidenavCollapse from "Container/Sidenav/SidenavCollapse";
import styles from "Container/Sidenav/styles/sidenav";
import Logo from "assets/images/answering-legal-web-logo.png";
import { updateUI } from "app/features/UIControllerSlice";
import { useMediaQuery, useTheme } from "@mui/material";
import getSidenavData from "./data/sidenavData";
import { useGetAllPermissionAdminMutation } from "app/features/api/AuthApi";
import { setPermission } from "app/features/AuthSlice";

const Sidenav = ({ ...rest }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const tabView = useMediaQuery(theme.breakpoints.down("xl"));
  const { uiControl } = useSelector((store) => store);
  const { miniSidenav, transparentSidenav } = uiControl;
  const classes = styles({ miniSidenav, transparentSidenav });
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const [getAllPermissionAdmin] = useGetAllPermissionAdminMutation();
  const authToken = `Bearer ${localStorage.getItem('auth_token_admin')}`;
  const [permissions, setPermissions] = useState([]);
  const type = localStorage.getItem('type')
  useLayoutEffect(() => {
    if(localStorage.getItem('type') === 'admin') fetchAdminInfo();
  }, []);

  const fetchAdminInfo = async () => {
    try {
      const response = await getAllPermissionAdmin(authToken).unwrap();
      let result = [];

      if (response.statusCode === 200) {
        response.data.roles.forEach((item) => {
          item.permissions.forEach((module) => {
            Object.keys(module).forEach((key) => {
              if (module[key] === true && key !== 'moduleId' && key !== 'moduleName' && key !== 'slug' && key !== 'sidebarIcon') {
                result.push(`${module.moduleName.toLowerCase()}:${key}`);
              }
            });
          });
        }); 
        dispatch(setPermission(result));
        setPermissions(result);
      } 
    } catch (error) {
      console.error('Error fetching admin info:', error);
    }
  };

  const closeSizenav = () => dispatch(updateUI({ type: "miniSidenav", value: true }));

  useEffect(() => {
    dispatch(updateUI({ type: "miniSidenav", value: tabView }));
  }, [dispatch, location, tabView]);

  const showSideBarToTenant = localStorage.getItem('type') === 'tenant' ? true : false
  const sidenavData = getSidenavData(permissions, type);

  const renderRoutes = sidenavData
    .filter(({ visible }) => visible)
    .map(({ id, icon, link, label, sub_menu, type }) => {
      console.log(link);
      let returnValue;
      if (type === "link") {
        returnValue = (
          <SidenavLink to={link} name={label} icon={icon} active={link === `/${collapseName}` || (link === '/tenant' && `/${collapseName}` === '/tenant-chatbot-template')} />
        );
      } else if (type === "collapse") {
        returnValue = <SidenavCollapse name={label} icon={icon} subMenu={sub_menu.filter(({ visible }) => visible)}  />;
      } else if (type === "title") {
        returnValue = (
          <SuiTypography
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            customClass={classes.sidenav_title}
          >
            {label}
          </SuiTypography>
        );
      } else if (type === "divider") {
        returnValue = <Divider />;
      }
      return (
        <ListItem component="li" key={`${id}`} sx={{ order: id }}>
          {returnValue}
        </ListItem>
      );
    });

  return (
    <Drawer
      {...rest}
      variant="permanent"
      classes={{
        paper: clsx(classes.sidenav, {
          [classes.sidenav_open]: !miniSidenav,
          [classes.sidenav_close]: miniSidenav,
        }),
      }}
    >
      <SuiBox customClass={classes.sidenav_header}>
        <SuiBox component={Link} to="/dashboard">
          <SuiBox
            component="img"
            src={Logo}
            alt="Answering Legal Logo" sx={{ maxWidth: "200px" }}
          />
        </SuiBox>
        <SuiBox
          display={{ xs: "block", xl: "block" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          customClass="cursor-pointer"
          onClick={closeSizenav}
        >
          {/* <SuiTypography variant="h6" textColor="dark" sx={{ cursor: "pointer" }}>
            <Icon className="font-bold">close</Icon>
          </SuiTypography> */}
        </SuiBox>
      </SuiBox>
      <List className={classes.sidenav_list}>{renderRoutes}</List>
    </Drawer>
  );
};

export default Sidenav;
