import React from "react";
import PropTypes from "prop-types";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { CircularProgress, MenuItem, Select } from "@mui/material";
import colors from "assets/theme/base/colors";

const SuiDropDownList = ({ data, select, disabled, readOnly = false, isLoading, onBlur }) => {
  return (
    <Select
      onBlur={onBlur}
      readOnly={readOnly}
      disabled={disabled}
      name={select.name}
      value={isLoading ? "" : select.val}
      IconComponent={({ className }) => {
        const icon = isLoading ? (
          <span style={{ position: "absolute", right: 6, top: 7 }}>
            <CircularProgress size={30} sx={{ color: colors.info.main }} />
          </span>
        ) : (
          <KeyboardArrowDownRoundedIcon
            className={className}
            sx={{ fontSize: 12, color: "#848484" }}
          />
        );
        return icon;
      }}
      displayEmpty
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      }}
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: '2px !important',
          fontSize:'small !important'
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderRadius: '2px !important',
           fontSize:'small !important'
        },
        "& .MuiSelect-select": {
          borderRadius: '2px !important',
           fontSize:'small !important'
        },
        "& .MuiSelect-icon": {
          borderRadius: '2px !important',
           fontSize:'small !important'
        },
        "& .css-mlog3m-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
          borderRadius: '2px !important',
           fontSize:'small !important'
        },
      }}
      onChange={(e) => {
        select.fn(e);
      }}
    >
      <MenuItem value="" disabled sx={{ fontSize: "0.875rem" }}>
        {select.placeholder || select.label}
      </MenuItem>
      {data &&
        data.map((item) => (
          <MenuItem
            key={item[select.key] || item}
            value={select.valueKey ? item[select.valueKey] : item}
            disabled={select.enableId && (select.enableId === item ? false : true)}
            sx={{ fontSize: "0.875rem" }} // Font size set to small
          >
            {item[select.labelName] || item}
          </MenuItem>
        ))}
    </Select>
  );
};

SuiDropDownList.propTypes = {
  data: PropTypes.any,
  select: PropTypes.shape({
    label: PropTypes.string,
    name: PropTypes.string,
    val: PropTypes.any,
    fn: PropTypes.func,
    key: PropTypes.string,
    valueKey: PropTypes.string,
    labelName: PropTypes.string,
    enableId: PropTypes.any,
    placeholder: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  isLoading: PropTypes.bool,
  onBlur: PropTypes.func,
};

export default SuiDropDownList;
