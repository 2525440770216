import React, { useEffect, useState } from "react";
import { Box, Card, Grid, Skeleton, Typography } from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
} from "recharts";
import DashboardAiIcon from "Container/Icons/DashboardAiIcon";
import DashboardChatBotIcon from "Container/Icons/DashboardChatBotIcon";
import { useGetAdminDashboardInfoMutation } from "app/features/api/AuthApi";
import { PieChart } from "@mui/x-charts";
import DashboardGreenIcon from "Container/Icons/DashboardGreenIcon";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const AdminDashboardUi = () => {
  const [getAdminDashboardInfo, { isLoading }] = useGetAdminDashboardInfoMutation();
  const [data, setData] = useState([])
  const [totalCounts, setTotalCounts] = useState('00')
  const processChatData = (data) => {
    if (!data) return [];
    return data.map((item) => {
      const hour = item.hour.split(' ')[1].substring(0, 5);
      return { hour, count: item.chatCount !== undefined ? item.chatCount : item.aiRequestCount };
    });
  };
  const { permission } = useSelector((state) => state.auth)
  const navigate = useNavigate()
  useEffect(() => {
    console.log(permission)
    if (permission && !permission?.includes("dashboard:read")) {
      navigate('/profile')
    }
  }, [permission])
  const data2 = [
    {
      label: "Active Tenant",
      value: data?.tenantCounts?.active,
      color: "#161740",
      fontSize: "small",
      margin: 20,
    },
    { label: "Inactive Tenant", value: data?.tenantCounts?.inactive, color: "#1264E2" },
  ];

  const getCustomerOnload = async () => {
    try {
      const response = await getAdminDashboardInfo().unwrap();
      setData(response?.data)
      const total = response?.data?.tenantCounts?.active + response?.data?.tenantCounts?.inactive
      setTotalCounts(total)
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCustomerOnload()
  }, [])
  const totalChatData = processChatData(data?.hourlyChatData)
  const totalAIReqData = processChatData(data?.hourlyAiRequestData)

  return (
    <>
      {isLoading ?
        <>
          <Grid item xs={12} lg={4}>
            <Skeleton variant="rounded" animation='wave' width={400} height={400} />
          </Grid>
        </>
        :
        <Grid item xs={12} lg={4}>
          <Card sx={{ pb: "20px", borderRadius: "5px !important" }}>
            <Typography
              fontWeight={600}
              fontSize={{ xs: 16, md: 17, lg: 18 }}
              p={2}
              borderBottom="2px solid #E4E9F0"
            >
              Total Tenant
            </Typography>
            <Box display="flex" justifyContent="center" alignItems="center">
              <PieChart
                margin={{
                  left: 35,
                  right: 40,
                  top: 40,
                  bottom: 40,
                }}
                sx={{
                  "& .MuiChartsLegend-mark": {
                    height: "10px",
                    width: "10px",
                    transform: "translateY(6px) !important",
                  },
                  "& .MuiChartsLegend-series tspan": {
                    fontSize: "0.7em ",
                    height: "2px",
                    transform: 'translateX(30px) !important'
                  },
                  '& .css-1u0lry5-MuiChartsLegend-root': {
                    transform: ' translate(18px, 0px) !important'
                  },
                }}
                series={[
                  {
                    data: data2.map((item) => ({
                      ...item,
                      label: item.label,
                    })),
                    cx: 115,
                    cy: 85,
                    innerRadius: 70,
                    outerRadius: 110,
                  },
                ]}
                height={320}
                width={300}

                slotProps={{
                  legend: {
                    direction: "column",
                    position: { vertical: "bottom", horizontal: "center" },
                    padding: 0,
                    itemTextStyle: { fontSize: "14px !important" },
                    itemSymbolStyle: {
                      width: "14px !important",
                      height: "14px !important",
                    },
                  },
                  popper: {
                    sx: {
                      width:'fit-content',
                      display:'block',
                      minWidth:'unset !important',
                      '& .MuiChartsTooltip-root': {
                        fontSize:'12px',
                        '& table, tbody, tr':{
                          display:'flex !important',
                          flexWrap:'wrap',
                          width:'min-content'
                        },
                        '& .MuiChartsTooltip-row':{
                          '&> td:nth-child(1)':{
                            order:2,
                            paddingLeft:'8px',
                            paddingRight:'4px',
                          },
                          '&> td:nth-child(2)':{
                            flex:'0 0 100%',
                            order:1,
                            width:"100%",
                            paddingRight:'8px',
                            backgroundColor:'#E4E9F0',
                            borderRadius:'5px',
                            borderBottomLeftRadius: "0px",
                            borderBottomRightRadius: "0px",

                          },
                          '&> td:nth-child(3)':{
                            order:3,
                            paddingLeft:'0'
                          },
                        },
                      '& .MuiTypography-root': {
                          color: 'blue',
                        },

                      },
                    },
                  },
                }}
              >
                <text
                  x={150}
                  y={100}
                  textAnchor="middle"
                  dominantBaseline="middle"
                  style={{ color: "#161740", fontWeight: 500, fontSize: "18px" }}
                >
                  Total
                </text>
                <text
                  x={150}
                  y={140}
                  textAnchor="middle"
                  dominantBaseline="middle"
                  style={{ color: "#161740", fontWeight: 800, fontSize: "42px" }}
                >
                  {totalCounts}
                </text>
              </PieChart>
            </Box>
          </Card>

        </Grid>
      }
      {isLoading ?
        <>
          <Grid item xs={12} lg={8}>
            <Skeleton variant="rounded" animation='wave' width={800} height={400} />
          </Grid>
        </>
        :
        <Grid item xs={12} lg={8}>
          <Card sx={{ pb: "20px", borderRadius: "5px !important" }}>
            <Typography
              fontWeight={600}
              fontSize={{ xs: 16, md: 17, lg: 18 }}
              p={2}
              borderBottom="2px solid #E4E9F0"
            >
              Total Chats Per Day
            </Typography>

            <Box sx={{ width: "100%", height: 320, overflow: 'visible !important' }} py={1} px={2}>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={totalChatData} margin={{ top: 30, right: 10, left: 0, bottom: 25 }} >
                  <CartesianGrid strokeDasharray="5 3" vertical={false} />
                  <XAxis
                    dataKey="hour"
                    tickLine={false}
                    axisLine={false}
                    minTickGap={-200}
                    angle={45}
                    fontSize={12}
                    dy={10}
                  > <Label value='Hours' fill="#161740" dy={35} offset={-15} position='centerBottom' fontSize={14} fontWeight={500} /> </XAxis>
                  <YAxis
                    tickLine={false}
                    axisLine={false}
                    fontSize={12}
                    dx={-10}
                    fill="#161740"
                  >
                    <Label value="Count" angle={-90} fill="#161740" dx={-20} fontSize={14} fontWeight={500} />
                  </YAxis>
                  <Tooltip
                    contentStyle={{
                      backgroundColor: "#f5f5f5",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                      width: 'auto',
                      padding:'0'
                    }}
                    labelStyle={{ color: "#4F6D7A", fontSize: '14px', background: '#E4E9F0', padding:'10px' }}
                    itemStyle={{ color: "#1264E2", padding:'10px' }}
                  />
                  <Tooltip />
                  <Line
                    type="monotone"
                    dataKey="count"
                    stroke="#1264E2"
                    strokeWidth={4}
                    dot={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            </Box>
          </Card>
        </Grid>
      }
      {isLoading ?
        <>
          <Grid item xs={12} lg={9} xxl={10}>
            <Skeleton variant="rounded" animation='wave' width={1000} height={400} />
          </Grid>
        </>
        :
        <Grid item xs={12} lg={9} xxl={10}>
          <Card sx={{ pb: "20px", borderRadius: "5px !important" }}>
            <Typography
              fontWeight={600}
              fontSize={{ xs: 16, md: 17, lg: 18 }}
              p={2}
              borderBottom="2px solid #E4E9F0"
            >
              AI request usage per day
            </Typography>

            <Box sx={{ width: "100%", height: 340 }} p={2}>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={totalAIReqData} margin={{ top: 30, right: 10, left: 0, bottom: 25 }} >
                  <CartesianGrid strokeDasharray="5 3" vertical={false} />
                  <XAxis
                    dataKey="hour"
                    tickLine={false}
                    axisLine={false}
                    minTickGap={-200}
                    angle={45}
                    fontSize={12}
                    dy={10}
                  > <Label value='Hours' fill="#161740" dy={35} offset={-15} position='centerBottom' fontSize={14} fontWeight={500} /> </XAxis>
                  <YAxis
                    tickLine={false}
                    axisLine={false}
                    fontSize={12}
                    dx={-10}
                    fill="#161740"
                  >
                    <Label value="Count" angle={-90} fill="#161740" dx={-20} fontSize={14} fontWeight={500} />
                  </YAxis>
                  <Tooltip
                    contentStyle={{
                      backgroundColor: "#f5f5f5",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                      width: 'auto',
                      padding:'0'
                    }}
                    labelStyle={{ color: "#4F6D7A", fontSize: '14px', background: '#E4E9F0', padding:'10px' }}
                    itemStyle={{ color: "#1264E2", padding:'10px' }}
                  />
                  
                  <Tooltip />
                  <Line
                    type="monotone"
                    dataKey="count"
                    stroke="#1264E2"
                    strokeWidth={4}
                    dot={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            </Box>
          </Card>
        </Grid>
      }

      <Grid item xs={12} lg={3} xxl={2}>
        <Box
          display="flex"
          flexDirection="column"
          gap={3}
          sx={{ height: "100%" }}
        >
          {isLoading ?
            <Grid item xs={12} lg={3} xxl={2}>
              <Skeleton variant="rounded" width={188} height={188} />
            </Grid>
            :
            <Card
              sx={{
                py: 2,
                px: 2,
                borderRadius: "5px !important",
                bgcolor: "#1264E2",
                alignContent: "center",
                display: "flex",
                justifyContent: "space-between",
                height: "200px",
                position: 'relative'
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: 12,
                  right: 22,
                  height: "12px",
                  width: "12px",
                  zIndex: 3,
                }}
              >
                <DashboardGreenIcon />
              </Box>
              <Box sx={{ position: "relative", height: "40px", width: "40px" }}>
                <Box
                  sx={{
                    opacity: 0.1,
                    height: "100%",
                    width: "100%",
                    background: "#fff",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 1,
                    borderRadius: "5px",
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    height: "70%",
                    width: "70%",
                    zIndex: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <DashboardChatBotIcon />
                </Box>
              </Box>
              <Typography
                fontWeight={500}
                fontSize={16}
                sx={{ color: "#E4E9F0" }}
                mt={2}
              >
                No. of Live Chats{" "}
                {/* <Typography fontSize="small" sx={{ color: "#E4E9F0" }}>
                (per day)
              </Typography> */}
              </Typography>
              <Typography
                sx={{ color: "#E4E9F0" }}
                fontWeight={700}
                fontSize={30}
              >
                {data?.liveUsers}
              </Typography>
            </Card>
          }
          {isLoading ?
            <Grid item xs={12} lg={3} xxl={2}>
              <Skeleton variant="rounded" width={188} height={188} />
            </Grid>
            :
            <Card
              sx={{
                py: 2,
                px: 2,
                borderRadius: "5px !important",
                bgcolor: "#1264E2",
                display: "flex",
                justifyContent: "space-between",
                height: "200px",
              }}
            >
              <Box sx={{ position: "relative", height: "40px", width: "40px" }}>
                <Box
                  sx={{
                    opacity: 0.1,
                    height: "100%",
                    width: "100%",
                    background: "#fff",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 1,
                    borderRadius: "5px",
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    height: "70%",
                    width: "70%",
                    zIndex: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <DashboardAiIcon />
                </Box>
              </Box>
              <Typography
                fontWeight={500}
                fontSize={16}
                sx={{ color: "#E4E9F0" }}
                mt={2}
              >
                Total AI requests used{" "}
                <Typography fontSize="small" sx={{ color: "#E4E9F0" }}>
                  (Today)
                </Typography>
              </Typography>
              <Typography
                sx={{ color: "#E4E9F0" }}
                fontWeight={700}
                fontSize={30}
              >
                {data?.totalAiRequestsUsed}
              </Typography>
            </Card>
          }
        </Box>
      </Grid>

    </>
  );
};

export default AdminDashboardUi;
