import { Card, Grid, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";

import PropTypes, { object } from "prop-types";
import SuiBox from "components/SuiBox";
import styles from "./styles";
import { Link, useLocation } from "react-router-dom";

const CollapseFormLayout = ({ routes, children }) => {
  const classes = styles();
  const loc = useLocation();
  const collapseName = `/${loc.pathname.slice(1)}`;
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Card className={classes.card_Box}>
      <Grid container>
        <Grid item xs={12} sm={4} lg={3}>
          <Card className={classes.side_card_Box}>
            <Tabs
              orientation={mobileView ? "horizontal" : "vertical"}
              variant="scrollable"
              scrollButtons={mobileView}
              value={collapseName}
            >
              {routes.map(({ to, name }) => {
                return <Tab component={Link} key={to} label={name} to={to} value={to} />;
              })}
            </Tabs>
          </Card>
        </Grid>
        <Grid item xs={12} sm={8} lg={9}>
          <SuiBox className={classes.form_box}>{children}</SuiBox>
        </Grid>
      </Grid>
    </Card>
  );
};

CollapseFormLayout.propTypes = {
  routs: PropTypes.arrayOf(object),
  children: PropTypes.any,
};

export default CollapseFormLayout;
