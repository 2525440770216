import React, { useState, useCallback, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';


export default function BasicTable({ modules, setModules }) {

  const isEvery = useCallback(() => {
    return modules.every(row => row.create && row.delete && row.view);
  }, [modules]);

  const isEveryIndividual = useCallback((args) => {
    return modules.every(row => row[args]);
  }, [modules]);

  const handleSelectAllHeader = (e) => {
    const isChecked = e.target.checked;
    setModules(prev =>
      prev.map(item => ({
        ...item,
        create: isChecked,
        delete: isChecked,
        view: isChecked
      }))
    );
  };

  const handleCheckboxChange = (e, id, columnId) => {
    if (columnId === 'selectAll') {
      const isChecked = e.target.checked;
      setModules(prev =>
        prev.map(object =>
          object.id === id
            ? {
              ...object,
              create: isChecked,
              delete: isChecked,
              view: isChecked,
            }
            : object
        )
      );
    }

    const isChecked = e.target.checked;
    setModules(prev =>
      prev.map(object => {
        if (object.id === id) {
          if (columnId === 'create') {
            return { ...object, create: isChecked, view: true };
          }
          if (columnId === 'delete') {
            return { ...object, delete: isChecked, view: true };
          }
          if (columnId === 'view' && (object.create || object.delete)) {
            return {
              ...object,
              create: false,
              delete: false,
              view: false,
            };
          }
          return { ...object, [columnId]: isChecked };
        }
        return object;
      })
    );
  };

  const handleSelectAllByOperation = (e, columnName) => {
    const isChecked = e.target.checked;
    setModules(prev =>
      prev.map(object => {
        if (columnName === 'create') {
          return { ...object, create: isChecked, view: true };
        }
        if (columnName !== 'view' && !object.view) {
          return { ...object, [columnName]: isChecked, view: isChecked };
        }
        if (columnName === 'view' && (object.create || object.delete)) {
          return {
            ...object,
            create: isChecked,
            delete: isChecked,
            view: isChecked,
          };
        }
        return { ...object, [columnName]: isChecked };
      })
    );
  };

  return (
    <TableContainer component={Paper} sx={{ padding: '15px' }}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="module table">
        <TableHead sx={{whiteSpace:'nowrap'}}>
          <TableRow>
            <TableCell>Module Name</TableCell>
            <TableCell align="center">
              {/* <Checkbox
                onChange={handleSelectAllHeader}
                checked={isEvery()}
                color='secondary'
              /> */}
              <span> All </span>
            </TableCell>
            <TableCell align="center">
              {/* <Checkbox
                color='secondary'
                onChange={e => handleSelectAllByOperation(e, 'delete')}
                checked={isEveryIndividual('delete')}
              /> */}
              <span> Delete </span>
            </TableCell>
            <TableCell align="center">
              {/* <Checkbox
                color='secondary'
                onChange={e => handleSelectAllByOperation(e, 'create')}
                checked={isEveryIndividual('create')}
              /> */}
              <span> Create/Update </span>
            </TableCell>
            <TableCell align="center">
              {/* <Checkbox
                color='secondary'
                onChange={e => handleSelectAllByOperation(e, 'view')}
                checked={isEveryIndividual('view')}
              /> */}
              <span> View </span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {modules.map((module, index) => (
            <TableRow key={module.id}>
              <TableCell component="th" scope="row">
                {module.name}
              </TableCell>
              <TableCell align="center">
                {module.name !== 'Dashboard' && module.name !== 'Report' ? (
                  <Checkbox
                    checked={module.create && module.delete && module.view}
                    onChange={(e) => handleCheckboxChange(e, module.id, 'selectAll')}
                    color='secondary'
                  />
                ) : <span>--</span>}
              </TableCell>
              <TableCell align="center">
                {module.name !== 'Dashboard' && module.name !== 'Report' ?
                  <Checkbox
                    checked={module.delete}
                    onChange={(e) => handleCheckboxChange(e, module.id, 'delete')}
                    color='secondary'
                  /> : <span>--</span>
                }
              </TableCell>
              <TableCell align="center">
                {module.name !== 'Dashboard' && module.name !== 'Report' ?
                  <Checkbox
                    checked={module.create}
                    onChange={(e) => handleCheckboxChange(e, module.id, 'create')}
                    color='secondary'
                  /> : <span>--</span>
                }
              </TableCell>
              <TableCell align="center">
                <Checkbox
                  checked={module.view}
                  onChange={(e) => handleCheckboxChange(e, module.id, 'view')}
                  color='secondary'
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
