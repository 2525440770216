import { Link, useLocation, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import Icon from "@mui/material/Icon";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import styles from "Container/Breadcrumbs/styles";
import { breadcrumbsHeadingGen } from "helper";
import { useEffect, useState } from "react";

function Breadcrumbs({ icon, title, route, light }) {
  const location = useLocation();
  const { id, accnt_num } = useParams();
  const [pathChanges, setPathChange] = useState("");
  const [navigate, setNavigate] = useState("");
  const classes = styles({ light });
  let routes = route.map((item) => {
    if (isNaN(+item)) {
      return breadcrumbsHeadingGen(item.split("-").join(" "));
    }
  });
  const pagePath = [`/pages/edit-pages/${id}`];
  const profilePath = ["/profile/change-password"];
  const currentPath = location.pathname;
  if (profilePath == currentPath) {
    routes = routes.slice(0, route.length < 2 ? 0 : 0);
  }
  if (pagePath == currentPath) {
    routes = routes.slice(0, route.length < 2 ? 0 : 2);
  } else {
    routes = routes.slice(0, route.length < 3 ? -1 : -2);
  }
  const heading = isNaN(+title)
    ? breadcrumbsHeadingGen(title.split("-").join(" "))
    : routes[routes.length - 1];
  useEffect(() => {
    const currentPath = location.pathname;
    const editPaths = [
      `/customer/edit-customer/account-details/${id}/${accnt_num}`,
      `/customer/edit-customer/discount-details/${id}/${accnt_num}`,
      `/customer/edit-customer/did-details/${id}/${accnt_num}`,
      `/customer/edit-customer/sip-trunk-details/${id}/${accnt_num}`,
      `/customer/edit-customer/number-port-in-details/${id}/${accnt_num}`,
      `/customer/edit-customer/transaction-details/${id}/${accnt_num}`,
      `/customer/edit-customer/order-details/${id}/${accnt_num}`,
      `/customer/edit-customer/cdr-details/${id}/${accnt_num}`,
      `/customer/edit-customer/invoice-details/${id}/${accnt_num}`,
    ];

    const isEditCustomerDetails = editPaths.some((path) => currentPath === path);
    const isPagePath = pagePath.some((path) => currentPath === path);

    if (isPagePath) {
      setNavigate(pagePath[0]);
      setPathChange(routes[1]);
    }
    if (isEditCustomerDetails) {
      setNavigate(editPaths[0]);
      setPathChange(routes[1]);
    } else {
      setPathChange("");
      if (routes[0] && routes[0].split(" ").join) {
        setNavigate(routes[0].split(" ").join("-").toLowerCase());
      }
      if (routes[0] === "Customer" && isEditCustomerDetails) {
        setNavigate(routes[0]);
      }
    }
  }, [location]);

  return (
    <SuiBox mr={{ xs: 0, xl: 8 }}>
      <MuiBreadcrumbs className={classes.breadcrumbs}>
        <Link to="/dashboard">
          <SuiTypography
            component="span"
            variant="body2"
            textColor={light ? "white" : "dark"}
            opacity={light ? 0.8 : 0.5}
            customClass="line-height-0"
          >
            <Icon>{icon}</Icon>
          </SuiTypography>
        </Link>

        {routes.map(
          (el) =>
            heading !== el && (
              <Link
                to={
                  el === "Customer"
                    ? "/customer"
                    : `/${el.split(" ").join("-")}`.toLowerCase()
                    ? navigate
                    : navigate
                }
                key={el}
                className="decoration-none"
              >
                <SuiTypography
                  component="span"
                  variant="button"
                  fontWeight="regular"
                  textColor={light ? "white" : "dark"}
                  opacity={light ? 0.8 : 0.5}
                  customClass="line-height-0"
                >
                  {el === "Edit Pages" ? "Sites & pages" : el}
                </SuiTypography>
              </Link>
            )
        )}
        <SuiTypography
          variant="button"
          fontWeight="regular"
          textColor={light ? "white" : "dark"}
          customClass="line-height-0"
        >
          {heading === "Pages" && pagePath[0] === currentPath
            ? "Edit Sites & pages"
            : heading === "Pages"
            ? "Sites & pages"
            : heading}
        </SuiTypography>
      </MuiBreadcrumbs>
      <SuiTypography
        fontWeight="bold"
        variant="h6"
        textColor={light ? "white" : "dark"}
        noWrap
        mt={0}
      >
        {pathChanges
          ? pathChanges
          : heading === "Pages" && pagePath[0] === currentPath
          ? "Edit Sites & pages"
          : heading === "Pages"
          ? "Sites & pages"
          : heading}
      </SuiTypography>
    </SuiBox>
  );
}

Breadcrumbs.defaultProps = {
  light: false,
};

Breadcrumbs.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  light: PropTypes.bool,
};

export default Breadcrumbs;
