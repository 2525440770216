import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import {
  Box,
  Avatar,
  MenuItem,
  Menu,
  IconButton,
  Toolbar,
  AppBar,
  Skeleton,
  Stack,
  Divider,
  Typography,
} from "@mui/material";
import SuiBox from "components/SuiBox";
import breakpoints from "assets/theme/base/breakpoints";
import Breadcrumbs from "Container/Breadcrumbs";
import styles from "Container/DashboardNavbar/styles";
import { stringAvatar, capitalize } from "helper";
import { updateUI } from "app/features/UIControllerSlice";
import profileImage from "assets/images/profile-img.png";
import colors from "assets/theme/base/colors";
import SuiTypography from "components/SuiTypography";
import { Logout } from "app/features/AuthSlice";
import { useLogoutMutation } from "app/features/api/AuthApi";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import { useGetAdminInfoMutation } from "app/features/api/AuthApi";
import { useHandleGetApiQuery } from "app/features/api/CommonGetApi";
import { Padding } from "@mui/icons-material";
import AccountIcon from "Container/Icons/AccountIcon";
import SignOutIcon from "Container/Icons/SignOutIcon";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const suiSnackbar = useSuiSnackbar();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [getAdminInfo, { isLoading }] = useGetAdminInfoMutation();
  const loginType = localStorage.getItem('type')
  const [getUser, setGetUser] = useState("");
  const fetchAdminInfo = async () => {
    const response = await getAdminInfo().unwrap();
    try {
      if (response.statusCode === 200) {
        setGetUser(response.data);
        localStorage.setItem('subscriptionType', response.data.type)
        localStorage.setItem('name', response?.data?.name)
        if (loginType === 'tenant') {
          localStorage.setItem('subscriptionName', response.data.subscriptionName)
        }
      }
    } catch (error) {
      console.log(response);
    }
  };
  const controller = useSelector((store) => store.uiControl);
  const { miniSidenav, transparentNavbar, fixedNavbar } = controller;
  const classes = styles({ transparentNavbar, absolute, light, isMini });
  const route = useLocation().pathname.split("/").slice(1);
  let name = getUser.admin_name;
  if (getUser && !isLoading) {
    name = `${getUser.admin_name} `;
  }
  useEffect(() => {
    fetchAdminInfo();
    setNavbarType("absolute");
    function handleTransparentNavbar() {
      dispatch({
        type: "transparentNavbar",
        value: (fixedNavbar && window.scrollY !== 0) || !fixedNavbar,
      });
    }
    window.addEventListener("scroll", handleTransparentNavbar);
    handleTransparentNavbar();
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    const displayMobileNavbar = () => {
      if (window.innerWidth < breakpoints.values.sm) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
    };

    window.addEventListener("resize", displayMobileNavbar);

    displayMobileNavbar();
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  const handleMiniSidenav = () => dispatch(updateUI({ type: "miniSidenav", value: !miniSidenav }));
  const datas = isLoading;
  const [logout] = useLogoutMutation();
  const accountMenu = () => {

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const token = localStorage.getItem("auth_token_admin");
    const logoutHandler = async () => {
      localStorage.removeItem('auth_token_admin')
      // const headers = {
      //   Authorization: token,
      // };
      // const response = await logout(headers).unwrap();
      // try {
      //   if (response.status === 200) {
      //     suiSnackbar(response.message, "success");
      //   }
      // } catch (error) {
      //   console.log(error);
      // }
      dispatch(Logout());
      if (localStorage.getItem('type') === 'admin') {
        navigate('/admin-login')
        localStorage.removeItem('type')
        localStorage.removeItem('name')
      } else {
        navigate("/");
        localStorage.removeItem('type')
        localStorage.removeItem('name')
        localStorage.removeItem('subscriptionName')
      }
    };
    const loginType = localStorage.getItem('type')
    const subscriptionName = localStorage.getItem('subscriptionName')
    const name = localStorage.getItem('name')
    const capatalizedName = name?.charAt(0).toUpperCase() + name?.slice(1);
    return (
      <>
        <Box className={classes.avatar_container}>
          {/* {!mobileView ? (
            datas ? (
              <Skeleton height={25} width={80} />
            ) : (
              <Box className="userName" title={name} component="span">
                {capitalize(name)}
              </Box>
            )
          ) : null} */}
          {loginType === 'tenant' &&
            <SuiTypography sx={{ backgroundImage: "linear-gradient(45deg, #e1249e, #8b5bcf)", color: '#fff !important', fontSize: '14px', fontWeight: 'bold !important', padding: '5px 10px !important', marginRight: '5px !important', borderRadius: '4px', cursor: 'default !important' }}>
              {subscriptionName}
            </SuiTypography>
          }
          {datas ? (
            <Skeleton variant="circular" sx={{ marginLeft: "10px" }}>
              <Avatar />
            </Skeleton>
          ) : (
            <IconButton
              onClick={!datas ? handleClick : () => null}
              size="small"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              title={name}
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar src={profileImage} alt={name} {...stringAvatar(name, profileImage, "35px")} sx={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }} />
            </IconButton>
          )}
          <SuiBox sx={{ height: "10px", width: "10px", borderRadius: "50%", backgroundColor: "#3CF0C0 !important", position: "absolute", bottom: "9px", right: "5px", outline: "3px solid #fff" }}>

          </SuiBox>
          {/* <Icon fontSize="small">keyboard_arrow_down</Icon> */}
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          disableScrollLock={true}
        >
          {mobileView ? (
            <>
              <Stack direction="row" sx={{ alignItems: "center" }} spacing={1.2}>
                <Avatar
                  src={profileImage}
                  alt={name}
                  {...stringAvatar(name, profileImage, "32px")}
                />
                <SuiTypography fontSize={14} fontWeight="semiBold">
                  {capitalize(name)}
                </SuiTypography>
              </Stack>
              <Divider />
            </>
          ) : null}
          {/* <MenuItem
            // component={Link}
            sx={{ "&:hover": { bgcolor: colors.info.main, color: "#fff" } }}
            // to="/profile"
          >
              {capatalizedName}
          </MenuItem> */}
          <Box px={2} > <Typography sx={{ color: '#1264E2', fontWeight: 600 }} variant="h6">{capatalizedName}</Typography> </Box>
          <Box sx={{ borderBottom: '2px solid rgba(233, 233, 233, 0.4)' }} my={1} ml={-1} width="calc(100% + 16px)" />
          <MenuItem
            component={Link}
            sx={{ "&:hover": { bgcolor: '#F2F4FF', color: "#000" },  display: 'flex', justifyContent: 'flex-start', alignItems: 'center', textAlign: 'center', fontWeight: 600 }}
            to="/profile"
          >
            <span style={{ marginRight: '8px' }}>
              <AccountIcon size={18} />
            </span>
            Profile
          </MenuItem>
          <MenuItem
            sx={{
              color: "#FC3C3C",
              "&:hover": { bgcolor: "#FC3C3C", color: "#fff" },
               display: 'flex', justifyContent: 'flex-start', alignItems: 'center', textAlign: 'center', fontWeight: 600
            }}
            onClick={logoutHandler}
          >
            <span style={{ marginRight: '8px' }}>
              <SignOutIcon size={18} />
            </span>
            Logout
          </MenuItem>
        </Menu>
      </>
    );
  };
  return (
    <>
      <Box height={50} />
      <Box position={"absolute"} top={0} left={0} width={"100%"} zIndex={10}>
        <AppBar
          position={absolute ? "absolute" : navbarType}
          color="inherit"
          className={classes.navbar}
        >
          <Toolbar className={classes.navbar_container}>
            {/* <SuiBox component={Link} display={{ xs: "none", md: "block" }} to="/dashboard">
            <SuiBox
              component="img"
              src={Logo}
              alt="Answering Legal Logo"
              customClass={classes.sidenav_logo}
            />
          </SuiBox>
          <SuiBox customClass={classes.navbar_row} color="inherit" mr="auto" mb={{ xs: 1, md: 0 }}>
            
          </SuiBox> */}
            {/* {!mobileView && <CronStatus status={cronStatus?.data} />} */}



            <IconButton
              size="medium"
              color="inherit"
              className={classes.navbar_mobile_menu}
              onClick={handleMiniSidenav}
            >
              <Icon>{miniSidenav ? "menu_open" : "menu"}</Icon>
            </IconButton>
            {accountMenu()}

          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
}

DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
