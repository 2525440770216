export default {

  label:{
    main:"#161740",
  },

  background: {
    default: "#fff",
  },
  text2: {
    main: "#6E828A",
    focus: '#999',
  },
  text: {
    main: "#2B2B2B",
    focus: "#848484",
  },

  transparent: {
    main: "transparent",
  },

  white: {
    main: "#ffffff",
  },

  black: {
    light: "#333333",
    main: "#000000",
    focus: "#1a1a1a",
  },

  primary: {
    // main: "#55DED0",
    main: "#1264E2",
    focus: "#49D9C6",
  },

  secondary: {
    main: "#F2F4FF",
    focus: "#dadce6",
  },

  accous: {
    main: "#E9E9E9",
    focus: "#E2E2E2",
  },

  info: {
    main: "#1264E2",
    focus: "#1264E2",
  },

  success: {
    main: "#2DC573",
    focus: "#53a653",
  },

  warning: {
    main: "#ff6900",
    focus: "#e65f00",
  },

  error: {
    main: "#C02B0A",
    focus: "#FF1733",
  },

  light: {
    main: "#ffffff",
    focus: "#e6e6e6",
  },

  dark: {
    main: "#2B2B2B",
    focus: "#131313",
  },

  grey: {
    100: "#f8f9fa",
    200: "#e9ecef",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#6c757d",
    700: "#495057",
    800: "#343a40",
    900: "#212529",
  },

  gradients: {
    text2: {
      main: "#848484",
      state: "#97AFB8",
    },

    primary: {
      main: "#0B409C",
      state: "#447BEB",
    },

    secondary: {
      main: "#627594",
      state: "#a8b8d8",
    },

    info: {
      main: "#156CDA",
      state: "#42269F",
    },

    success: {
      main: "#17ad37",
      state: "#98ec2d",
    },

    warning: {
      main: "#ed952f",
      state: "#f53939",
    },

    error: {
      main: "#ea0606",
      state: "#ff667c",
    },

    light: {
      main: "#ced4da",
      state: "#ebeff4",
    },

    dark: {
      main: "#141727",
      state: "#3a416f",
    },
  },

  socialMediaColors: {
    facebook: {
      main: "#3b5998",
      dark: "#344e86",
    },

    twitter: {
      main: "#55acee",
      dark: "#3ea1ec",
    },

    instagram: {
      main: "#125688",
      dark: "#0e456d",
    },

    linkedin: {
      main: "#0077b5",
      dark: "#00669c",
    },

    pinterest: {
      main: "#cc2127",
      dark: "#b21d22",
    },

    youtube: {
      main: "#e52d27",
      dark: "#d41f1a",
    },

    vimeo: {
      main: "#1ab7ea",
      dark: "#13a3d2",
    },

    slack: {
      main: "#3aaf85",
      dark: "#329874",
    },

    dribbble: {
      main: "#ea4c89",
      dark: "#e73177",
    },

    github: {
      main: "#24292e",
      dark: "#171a1d",
    },

    reddit: {
      main: "#ff4500",
      dark: "#e03d00",
    },

    tumblr: {
      main: "#35465c",
      dark: "#2a3749",
    },
  },

  alertColors: {
    primary: {
      main: "#7928ca",
      state: "#d6006c",
      border: "#efb6e2",
    },

    secondary: {
      main: "#627594",
      state: "#8ca1cb",
      border: "#dadee6",
    },

    info: {
      main: "#2152ff",
      state: "#02c6f3",
      border: "#b9ecf8",
    },

    success: {
      main: "#17ad37",
      state: "#84dc14",
      border: "#daf3b9",
    },

    warning: {
      main: "#f53939",
      state: "#fac60b",
      border: "#fef1c2",
    },

    error: {
      main: "#ea0606",
      state: "#ff3d59",
      border: "#f9b4b4",
    },

    light: {
      main: "#ced4da",
      state: "#d1dae6",
      border: "#f8f9fa",
    },

    dark: {
      main: "#141727",
      state: "#2c3154",
      border: "#c2c8d1",
    },
  },

  badgeColors: {
    primary: {
      background: "#f883dd",
      text: "#a3017e",
    },

    secondary: {
      background: "#e4e8ed",
      text: "#5974a2",
    },

    info: {
      background: "#abe9f7",
      text: "#08a1c4",
    },

    success: {
      background: "#cdf59b",
      text: "#67b108",
    },

    warning: {
      background: "#fef5d3",
      text: "#fbc400",
    },

    error: {
      background: "#fc9797",
      text: "#bd0000",
    },

    light: {
      background: "#ffffff",
      text: "#c7d3de",
    },

    dark: {
      background: "#8097bf",
      text: "#1e2e4a",
    },
  },

  inputColors: {
    borderColor: { main: "#fff", focus: "#151515" },
    boxShadow: "#d0d0d0",
    error: "#cf2e2e",
    success: "#5cb85c",
  },

  sliderColors: {
    thumb: { borderColor: "#d9d9d9" },
  },

  circleSliderColors: {
    background: "#d3d3d3",
  },

  tabs: {
    indicator: { boxShadow: "#ddd" },
  },
};
