import { DialogContent, FormLabel, Grid } from "@mui/material";
import WrapCard from "Container/Cards/WrapCard";
import { createAdmin } from "Validation";
import { useCreateAdminsMutation } from "app/features/api/AuthApi";
import { useGetRolesDropdownMutation } from "app/features/api/AuthApi";
import ErrorMessageComponent from "components/FormikErrorMessage";
import AutocompleteSingleSelect from "components/SuiAutocomplete/AutoComplete";
import SuiButton from "components/SuiButton";
import SuiDialog from "components/SuiDialog";
import StatusDropdown from "components/SuiDropDownList/StatusDropdown";
import SuiInput from "components/SuiInput";
import SuiPasswordInput from "components/SuiPasswordInput";
import SuiTypography from "components/SuiTypography";
import { useFormik } from "formik";
import useNavigateSearch from "hooks/useNavigateSearch";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const CreateAdmin = () => {
  const navigateSearch = useNavigateSearch();
  const suiSnackbar = useSuiSnackbar();
  const [err, setError] = useState("");
  const [getRolesDropdown] = useGetRolesDropdownMutation();
  const [createAdmins] = useCreateAdminsMutation();

  const formik = useFormik({
    initialValues: {
      admin_name: "",
      Last_Name: "",
      Email_id: "",
      admin_role: "",
      status_type: "",
      admin_role1: "",
      Username: "",
      password: "",
    },
    enableReinitialize: true,
    validationSchema: createAdmin,
    onSubmit: async (values) => {
      const body = {
        admin_name: capitalizeFirstLetter(values.admin_name.trim()),
        admin_last_name: capitalizeFirstLetter(values.Last_Name.trim()),
        admin_email: values.Email_id,
        admin_phone_number: values.First_Name,
        admin_password: values.password,
        admin_role_id: values.admin_role.id,
        admin_status: values.status_type,
      };
      try {
        const response = await createAdmins(body).unwrap();
        navigateSearch("/user-management", { page: 1 });
        suiSnackbar(response.message, "success");
      } catch (error) {
        setError(error?.data.errorMessages.fieldErrors);
        console.log(error);
      }
    },
  });

  const handlePostPaidSelection = () => {};

  console.log(formik.errors, "errors");
  const [getRoles, setGetRoles] = useState();
  const getRolesDetails = async () => {
    try {
      const response = await getRolesDropdown().unwrap();
      setGetRoles(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const generatePassword = () => {
    // Define character sets
    const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
    const specialChars = '!@#$%^&*()_+{}:"<>?-=[];,./';
    const numberChars = "0123456789";

    const getRandomChar = (charSet) => {
      const randomIndex = Math.floor(Math.random() * charSet.length);
      return charSet[randomIndex];
    };
    const randomUppercase = getRandomChar(uppercaseChars);
    const randomLowercase = getRandomChar(lowercaseChars);
    const randomSpecial = getRandomChar(specialChars);
    const randomNumber = getRandomChar(numberChars);
    let password = randomUppercase + randomLowercase + randomSpecial + randomNumber;
    const allChars = uppercaseChars + lowercaseChars + specialChars + numberChars;
    for (let i = password.length; i < 8; i++) {
      const randomChar = getRandomChar(allChars);
      password += randomChar;
    }
    const passwordArray = password.split("");
    for (let i = passwordArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [passwordArray[i], passwordArray[j]] = [passwordArray[j], passwordArray[i]];
    }
    password = passwordArray.join("");
    formik.setFieldValue("password", password);
  };

  useEffect(() => {
    if (!formik.values.password) {
      generatePassword();
    }
  }, []);

  const options = getRoles?.map((role) => ({
    id: role.admin_role_id,
    name: role.admin_role_name,
  }));
  useEffect(() => {
    getRolesDetails();
  }, []);
  const capitalizeFirstLetter = (str) => {
    if (!str) return "";

    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const [showModal, setShowModal] = useState(false);

  const handleButtonClick = (e) => {
    if (formik.values.status_type === "0") {
      e.preventDefault();
      setShowModal(true);
    } else {
      formik.handleSubmit();
    }
  };

  return (
    <WrapCard title="Add User">
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={6}>
          <FormLabel htmlFor="did_num">First Name</FormLabel>
          <SuiInput
            inputSettungs={{ autoComplete: "off", required: true }}
            placeholder="First Name"
            id="admin_name"
            name="admin_name"
            value={formik.values.admin_name}
            onChange={formik.handleChange}
          />
          <ErrorMessageComponent
            touched={formik.touched.admin_name}
            error={formik.errors.admin_name}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <FormLabel htmlFor="First_Name">Last Name</FormLabel>
          <SuiInput
            inputSettungs={{ autoComplete: "off", required: true }}
            placeholder="Last Name"
            id="Last_Name"
            name="Last_Name"
            value={formik.values.Last_Name}
            onChange={formik.handleChange}
          />
          <ErrorMessageComponent
            touched={formik.touched.Last_Name}
            error={formik.errors.Last_Name}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <FormLabel htmlFor="Email_id">Email ID</FormLabel>
          <SuiInput
            inputSettungs={{ autoComplete: "off", required: true }}
            inputProps={{
              autoComplete: "off",
              required: true,
            }}
            type="email"
            placeholder="Email ID"
            id="Email_id"
            name="Email_id"
            value={formik.values.Email_id}
            onChange={formik.handleChange}
          />
          <ErrorMessageComponent touched={formik.touched.Email_id} error={formik.errors.Email_id} />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <FormLabel htmlFor="admin_role">Admin Role</FormLabel>
          <AutocompleteSingleSelect
            id="admin_role"
            value={formik.values.admin_role}
            setValue={(value) => {
              formik.setFieldValue("admin_role", value);
              formik.setFieldValue("admin_role1", value.name);
            }}
            noOptionsText="No options"
            placeholder="Select admin role"
            isLoading={false}
            disabled={false}
            options={options}
            formik={formik}
          />

          {/* <AutoCompleteNo
            type="accountNo"
            formik={formik}
            placeholder="Customer Name"
            id="customer_name"
            value={formik.values.customer_name}
            onBlur={formik.handleBlur}
          /> */}
          <ErrorMessageComponent
            touched={formik.touched.admin_role1}
            error={formik.errors.admin_role1}
          />
        </Grid>

        <Grid item xs={12} lg={6} md={6}>
          <FormLabel htmlFor="status_type">Status</FormLabel>
          <StatusDropdown
            name="status_type"
            value={formik.values.status_type}
            setValue={formik.handleChange}
            handlePostPaidSelection={handlePostPaidSelection}
          />
          <ErrorMessageComponent
            touched={formik.touched.status_type}
            error={formik.errors.status_type}
          />
        </Grid>
        <Grid item xs={12}>
          <SuiTypography fontWeight="semiBold" fontSize={16}>
            Login Credentials
          </SuiTypography>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <FormLabel htmlFor="username">Username</FormLabel>
          <SuiInput
            disabled
            inputSettungs={{ autoComplete: "off", required: true }}
            inputProps={{
              autoComplete: "off",
              required: true,
            }}
            type="email"
            placeholder="Username"
            id="Email_id"
            name="Email_id"
            value={formik.values.Email_id}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={6} md={6}>
          <FormLabel htmlFor="password">Password</FormLabel>
          <SuiPasswordInput
            index={1}
            attr={{ name: "password", placeholder: "Password" }}
            value={formik.values.password}
            onBlur={formik.handleBlur}
            getValue={formik.handleChange}
          />
          <ErrorMessageComponent touched={formik.touched.password} error={formik.errors.password} />
        </Grid>
        <Grid item xs={12}>
          <Grid sx={{ justifyContent: "start", pb: 1 }}>
            {Object.keys(err).map((fieldName) => (
              <div style={{ justifyContent: "start" }} key={fieldName}>
                {/* <strong>{fieldName}:</strong> */}
                <span>
                  {err[fieldName].map((error, index) => (
                    <p style={{ color: "red", fontSize: "0.72em" }} key={index}>
                      {error}
                    </p>
                  ))}
                </span>
              </div>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} mt={4}>
          <SuiButton
            size="small"
            component={Link}
            to="/user-management"
            variant="outlined"
            buttonColor="dark"
            sx={{ px: "1rem", py: "0.4rem", marginRight: "14px" }}
            disabled={formik.isSubmitting}
          >
            Cancel
          </SuiButton>
          <SuiButton
            type="submit"
            size="small"
            buttonColor="primary"
            sx={{ px: "1rem" }}
            onClick={handleButtonClick}
            disabled={!formik.dirty || formik.isSubmitting}
          >
            Add
          </SuiButton>
        </Grid>
        <SuiDialog open={showModal} onClose={() => setShowModal(false)} haveCloseIcon>
          <DialogContent
            sx={{
              fontWeight: "semiBold",
            }}
          >
            Are you sure you want to inactive this user account the user will not be able to log in
            please select active to enable login.
            <Grid container rowSpacing={2}>
              <Grid item xs={12} sx={{ marginTop: "20px" }}>
                <Grid container justifyContent={"end"} spacing={2}>
                  <Grid item xs={12} sm="auto">
                    <SuiButton
                      size="small"
                      sx={{ px: "1rem" }}
                      onClick={() => setShowModal(false)}
                      buttonColor="dark"
                      variant="outlined"
                      fullWidth
                      // disabled={importCsvFormik.isSubmitting}
                    >
                      Cancel
                    </SuiButton>
                  </Grid>
                  <Grid item xs={12} sm="auto">
                    <SuiButton
                      size="small"
                      sx={{ px: "1rem" }}
                      buttonColor="info"
                      fullWidth
                      onClick={formik.handleSubmit}
                      // onClick={() => handleDeleteIconClick(deleteID)}
                    >
                      Confirm
                    </SuiButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </SuiDialog>
      </Grid>
    </WrapCard>
  );
};

export default CreateAdmin;
