import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import SuiBox from "components/SuiBox";

import styles from "Container/Sidenav/styles/subLink";

function SubLink({ to, name, active }) {
  const controller = useSelector((store) => store.uiControl);
  const { miniSidenav, transparentSidenav, sidenavColor } = controller;
  const classes = styles({
    active,
    miniSidenav,
    transparentSidenav,
    sidenavColor,
  });

  return (
    <SuiBox component={Link} to={to} customClass={classes.collapse_item}>
      <ListItemIcon component="span" className={classes.collapse_iconBox}>
        <SuiBox component="span" customClass={`${classes.collapse_item_bar} ${active ? "active" : ""}`} />
      </ListItemIcon>
      <ListItemText primary={name} classes={{ root: classes.collapse_text }} />
    </SuiBox>
  );
}

SubLink.defaultProps = {
  active: false,
};

SubLink.propTypes = {
  icon: PropTypes.any,
  to: PropTypes.any,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

export default SubLink;
