import { Card, Grid, FormLabel, CircularProgress } from "@mui/material";
import ErrorMessageComponent from "components/FormikErrorMessage";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import { useFormik } from "formik";
// import useSuiSnackbar from "hooks/useSuiSnackbar";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SuiTypography from "components/SuiTypography";
import AccountTypeDropdown from "components/SuiDropDownList/AccountTypeDropdown";
import SuiPasswordInput from "components/SuiPasswordInput";
import { useCreateCustomerMutation, useGetAllRolesInfoMutation } from "app/features/api/AuthApi";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import { createCustomerSchema } from "Validation";
import WrapCard from "Container/Cards/WrapCard";
import { useSelector } from "react-redux";


const CreateCustomer = () => {
  const [mobileInValid, setMobileInValid] = useState(false);
  const { permission } = useSelector((state) => state.auth)
  const [error, setError] = useState("");
  // console.log(error);
  const navigate = useNavigate();
  const suiSnackbar = useSuiSnackbar();
  const [createCustomer, { isLoading: createLoader }] = useCreateCustomerMutation();
  const [getAllRolesInfo] = useGetAllRolesInfoMutation()
  const [rolesData, setRolesData] = useState([])
  const emailPattern = /(?=^.{10,254}$)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/i;
  const initialValues = {
    cus_username: "",
    cus_email: "",
    cus_password: "",
    cus_confirm_password: "",
    cus_role_id: "",
  };
  const loginType = localStorage.getItem('type')
  useEffect(() => {
    if(permission && !permission?.includes('users:create')) {
      console.log(permission)
      navigate('/profile')
    }
    if(loginType === 'tenant'){
      navigate('/profile')
    }
  },[permission])
  const roles = async() => {
    try {
      const response = await getAllRolesInfo()
      console.log(response?.data?.data)
      const res = response?.data?.data?.map((item) => {
        return {...item, label: item.name}
      })
      setRolesData(res)
    }catch(err) {
      console.log(err)
    }
  }
  useEffect(() => {
    roles()
  }, [])
  const formik = useFormik({
    initialValues,
    validationSchema: createCustomerSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const body = {
        name: values.cus_username,
        email: values.cus_email,
        password: values.cus_password,
        confirmPassword: values.cus_confirm_password,
        roleId: parseInt(values.cus_role_id)
      };

      try {
        const response = await createCustomer(body).unwrap();
        if (response.statusCode === 200) {
          formik.resetForm();
          suiSnackbar(response.message, "success");
          navigate("/users");
          setError("");
        }
      } catch (error) {
        // setError(error?.data.message);
        suiSnackbar(error?.data.message, "error");
        console.log(error);
      }
      // formik.setSubmitting(false);
    },
  });

  useEffect(() => {
    formik.resetForm();
  }, []);


  return (
    // <Card sx={{ my: 3, p: 2 }}>
    <WrapCard title='Add New User' >
      <Grid component={"form"} container spacing={2} rowSpacing={3}>
        <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="cus_username" required>
              Username
            </FormLabel>
            <SuiInput
              inputSettings={{ autoComplete: "off", required: true }}
              type="text"
              placeholder="Enter Username"
              id="cus_username"
              name="cus_username"
              value={formik.values.cus_username}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <ErrorMessageComponent
              touched={formik.touched.cus_username}
              error={formik.errors.cus_username}
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="cus_email" required>
              Email ID
            </FormLabel>
            <SuiInput
              inputSettings={{ autoComplete: "off", required: true }}
              type="email"
              placeholder="Enter Email ID"
              id="cus_email"
              name="cus_email"
              value={formik.values.cus_email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <ErrorMessageComponent
              touched={formik.touched.cus_email}
              error={formik.errors.cus_email}
            />
          </Grid>

        <Grid item xs={12} lg={6}>
          <FormLabel htmlFor="cus_role_id" required>
            Role
          </FormLabel>
          <AccountTypeDropdown
           data={rolesData}
            name="cus_role_id"
            id='cus_role_id'
            value={formik.values.cus_role_id}
            setValue={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <ErrorMessageComponent
            touched={formik.touched.cus_role_id}
            error={formik.errors.cus_role_id}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
        </Grid>

        <Grid item xs={12} lg={6}>
          <FormLabel htmlFor="cus_password" required>
            Create Password
          </FormLabel>
          <SuiPasswordInput
            index={1}
            attr={{ name: "cus_password", placeholder: "Enter Password" }}
            value={formik.values.cus_password}
            onBlur={formik.handleBlur}
            getValue={formik.handleChange}
          />
          <ErrorMessageComponent touched={formik.touched.cus_password} error={formik.errors.cus_password} />
        </Grid>

        {<Grid item xs={12} lg={6}>
          <FormLabel htmlFor="cus_confirm_password" required>
            Confirm Password
          </FormLabel>
          <SuiPasswordInput
            index={1}
            attr={{ name: "cus_confirm_password", placeholder: "Re-enter Password" }}
            value={formik.values.cus_confirm_password}
            onBlur={formik.handleBlur}
            getValue={formik.handleChange}
          />
          <ErrorMessageComponent touched={formik.touched.cus_confirm_password} error={formik.errors.cus_confirm_password} />
        </Grid>}
      </Grid>

      <Grid item sx={{ pb: 1, pt: 1 }}>
        {/* {Object.keys(formik.errors)?.length === 0 && error && ( */}
        <ErrorMessageComponent touched={error} error={error} />
        {/* )} */}
      </Grid>

      <Grid item xs={12}>
        <Grid container pb={2} pt={4} columnSpacing={2} rowSpacing={1}>
          <Grid item>
            <SuiButton
              size="small"
              component={Link}
              to="/users"
              variant="outlined"
              buttonColor="dark"
              sx={{ px: "1rem", py: "0.4rem" }}
            >
              Cancel
            </SuiButton>
          </Grid>
          <Grid item>
            <SuiButton
              type="submit"
              size="small"
              buttonColor="info"
              sx={{ px: "1rem" }}
              onClick={formik.handleSubmit}
              // disabled={!formik.dirty}
            >
              {createLoader ? <CircularProgress size="20px" color="inherit" /> : 'Create'}
            </SuiButton>
          </Grid>
        </Grid>
      </Grid>
      </WrapCard>
    // </Card>
  );
};

export default CreateCustomer;
