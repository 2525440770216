import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    permissions : []
};

export const permissionSlice = createSlice({
  initialState,
  name: "permission",
  reducers: {
    setPermissions: (state, action) => {
      state.permissions = action.payload;
    },
  },
});

export const { setPermissions } = permissionSlice.actions;

export default permissionSlice.reducer;
