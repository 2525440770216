import * as React from 'react';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import colors from "../../base/colors";
import borders from "../../base/borders";
import boxShadows from "../../base/boxShadows";
import pxToRem from "../../functions/pxToRem";
import linearGradient from "../../functions/linearGradient";

const { text, light, gradients } = colors;
const { borderWidth } = borders;
const { regular } = boxShadows;

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: '46.16px',
  height: '23.36px',
  padding: 0,
  borderRadius: '3px 0px 0px 0px',
  transition: "transform 250ms ease-in",

  '& .MuiSwitch-switchBase': {
    padding: 0,
    top: "50%",
    transform: `translate(2px, -50%)`,
    transition: `transform 250ms ease-in-out`,

    '&.Mui-checked': {
      transform: `translate(22px, -50%)`,

      '& + .MuiSwitch-track': {
        backgroundColor: `#4caf50 !important`,
        borderColor: `#4caf50 !important`,
        opacity: 1,
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: light.main,
      },
    },

    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: "0.3 !important",
    },

    '&.Mui-focusVisible .MuiSwitch-thumb': {
      backgroundImage: linearGradient(gradients.info.main, gradients.info.state),
    },
  },

  '& .MuiSwitch-thumb': {
    width: '16px',
    height: '16px',
    backgroundColor: text.focus,
    boxShadow: regular,
    top: "50%",
  },

  '& .MuiSwitch-track': {
    backgroundColor: "#f44336",
    border: `${borderWidth[1]} solid ${light.main}`,
    borderRadius: '3px 0px 0px 0px',
    opacity: 1,
    transition: `background-color 250ms ease, border-color 200ms ease`,
  },
}));

export default function CustomizedSwitches() {
  return (
    <FormGroup>
      <FormControlLabel
        control={<CustomSwitch defaultChecked />}
        label="Custom Switch"
      />
    </FormGroup>
  );
}
