import { makeStyles } from "@mui/styles";

export default makeStyles(({ palette, functions, typography, borders }) => {
  const { inputColors, grey, light, white, dark, transparent } = palette;
  const { pxToRem } = functions;
  const { size: fontSize, fontWeightRegular } = typography;
  const { borderRadius, borderWidth } = borders;

  return {
    suiInput: {
      backgroundColor: ({ disabled }) => (disabled ? '#D7DADD' : white.main),
      borderRadius:"1px !important",
      '& .MuiInputBase-input::placeholder': {
        fontSize: '12px !important', 
        color:'#999999'
      },
    },
    '@global': {
      'input:-webkit-autofill': {
        borderRadius: '2px !important',
      },
    },

    suiInput_disabled: {
      backgroundColor: '#D7DADD',
      color: grey[200],
      opacity: 1,
    },

    suiInput_error: {
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23fd5c70' viewBox='0 0 12 12'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23fd5c70' stroke='none'/%3E%3C/svg%3E\")",
      backgroundRepeat: "no-repeat",
      backgroundPosition: `right ${pxToRem(12)} center`,
      backgroundSize: `${pxToRem(16)} ${pxToRem(16)}`,
      borderColor: inputColors.main,
    },

    suiInput_success: {
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 8'%3E%3Cpath fill='%2366d432' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E\")",
      backgroundRepeat: "no-repeat",
      backgroundPosition: `right ${pxToRem(12)} center`,
      backgroundSize: `${pxToRem(16)} ${pxToRem(16)}`,
      borderColor: inputColors.success,
      
    },

    suiInput_multiline: {
      padding: `${pxToRem(10)} ${pxToRem(12)}`,
    },

    suiInputIcon: {
      display: "flex",
      alignItems: "center",
      backgroundColor: ({ disabled }) => (disabled ? grey[200] : white.main),
      border: `${borderWidth[0]} solid`,
      borderRadius: 0,
      borderColor: ({ error, success }) => {
        let borderColor;

        if (error) {
          borderColor = inputColors.error;
        } else if (success) {
          borderColor = inputColors.success;
        } else {
          borderColor = inputColors.borderColor.main;
        }

        return borderColor;
      },
      "&.inputWithIcon": {
        border: '1px solid #D7DADD',
        borderRadius: '2px',
        '& .MuiInputBase-input': {
          border: 'none !important',
          paddingLeft: 0
        }
        // borderColor: ({ error, success }) => {
        //   let borderColor;
          
        //   if (error) {
        //     borderColor = inputColors.error;
        //   } else if (success) {
        //     borderColor = inputColors.success;
        //   } else {
        //     borderColor = inputColors.borderColor.main;
        //   }
  
        //   return borderColor;
        // },
      }
    },

    suiInputIcon_right: {
      lineHeight: 0,
      padding: ({ size }) =>
        size === "small" ? `${pxToRem(4)} ${pxToRem(10)}` : `${pxToRem(8)} ${pxToRem(10)}`,
      width: pxToRem(39),
      height: "100%",
      color: dark.main,
    },

    suiInputIcon_input: {
      borderColor: transparent.main,
      borderRadius: ({ direction, withIcon }) => {
        let borderRadiusValue;

        if (direction === "rtl" && withIcon.direction === "left") {
          borderRadiusValue = `${borderRadius.md} 0 0 ${borderRadius.md}`;
        } else if (direction === "rtl" && withIcon.direction === "right") {
          borderRadiusValue = `0 ${borderRadius.md} ${borderRadius.md} 0`;
        } else if (direction === "ltr" && withIcon.direction === "right") {
          borderRadiusValue = `${borderRadius.md} 0 0 ${borderRadius.md}`;
        } else {
          borderRadiusValue = `0 ${borderRadius.md} ${borderRadius.md} 0`;
        }

        return borderRadiusValue;
      },
      paddingLeft: ({ direction, withIcon }) => {
        let paddingLeftValue;

        if (direction === "rtl" && withIcon.direction === "left") {
          paddingLeftValue = pxToRem(0);
        } else if (direction === "rtl" && withIcon.direction === "right") {
          paddingLeftValue = 0;
        } else if (direction === "ltr" && withIcon.direction === "right") {
          paddingLeftValue = pxToRem(0);
        } else if (direction === "ltr" && withIcon.direction === "left") {
          paddingLeftValue = 0;
        }

        return paddingLeftValue;
      },
      paddingRight: ({ direction, withIcon }) => {
        let paddingRightValue;

        if (direction === "rtl" && withIcon.direction === "left") {
          paddingRightValue = 0;
        } else if (direction === "rtl" && withIcon.direction === "right") {
          paddingRightValue = pxToRem(12);
        } else if (direction === "ltr" && withIcon.direction === "right") {
          paddingRightValue = 0;
        } else if (direction === "ltr" && withIcon.direction === "left") {
          paddingRightValue = pxToRem(12);
        }

        return paddingRightValue;
      },
    },

    suiInputIcon_icon: {
      fontWeight: fontWeightRegular,
      fontSize: ({ size }) => size === "small" && `${fontSize.regular} !important`,
    },
  };
});
