import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, useMediaQuery, useTheme, IconButton } from '@mui/material';
import colors from 'assets/theme/base/colors';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SuiButton from 'components/SuiButton';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SuiBox from 'components/SuiBox';
import boxShadow from 'assets/theme/functions/boxShadow';

function DialogBoxForEncryptedCode({ open, handleClose, fullScreen, text }) {
    const theme = useTheme();
    const mainjsPath = 'tenant/main.js';
    const isFullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [copied, setCopied] = useState(false);

    const handleCopy = async () => {
        try {
            const textToCopy = document.querySelector('.script-to-copy')?.innerText;
            if (textToCopy) {
                await navigator.clipboard.writeText(textToCopy);
                setCopied(true);
            }
        } catch (err) {
            console.error('Failed to copy text: ', err);
            alert('Failed to copy text.');
        }
    }

    const scriptSRC = process.env.REACT_APP_BASE_URL;

    return (
        <Dialog
            open={open}
            onClose={() => {
                handleClose(null);
                setTimeout(() => {
                    setCopied(false)
                }, 500)
            }}
            fullScreen={fullScreen || isFullScreen}
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: '8px',
                    padding: '16px',
                    maxWidth: '60%'
                }
            }}
        >
            <IconButton
                className="close-btn"
                onClick={() => {
                    handleClose(); setTimeout(() => {
                        setCopied(false)
                    }, 500)
                }}
                color="light"
                sx={{ bgcolor: `${colors.text.main} !important` }}
            >
                <CloseRoundedIcon />
            </IconButton>
            <DialogTitle fontWeight="700">Code</DialogTitle>
            <DialogContent
                sx={{
                    maxHeight: 300,
                    overflowY: 'auto',
                }}
            >
                <SuiBox sx={{ border: '1px solid #D7DADD', boxShadow: '0px 1px 0px #E4E9F0', padding: '20px 20px', position: 'relative' }}>
                    <p className='script-to-copy' style={{ width: '100%', wordWrap: 'break-word', fontSize: '14px', lineHeight: '22px', fontWeight: 500 }}>
                        {`<script src="${scriptSRC}/${mainjsPath}/${text}" ></script>`}
                    </p>
                    <IconButton onClick={handleCopy} sx={{ position: 'absolute', bottom: 0, right: '10px' }}>
                        {copied ? (
                            <CheckCircleIcon sx={{ fontSize: '15px !important', color: '#4CAF50' }} /> // Check icon when copied
                        ) : (
                            <ContentCopyOutlinedIcon sx={{ fontSize: '15px !important', color: '#1264E2' }} /> // Copy icon by default
                        )}
                    </IconButton>
                </SuiBox>
                <div style={{ marginTop: '20px', fontSize: '16px', fontWeight: 600, color: '#161740', marginLeft: '5px' }}>
                    <p>1.<span style={{ paddingLeft: '5px' }}>Go to Website</span></p>
                    <p>2.<span style={{ paddingLeft: '5px' }}>Go to footer</span></p>
                    <p>3.<span style={{ paddingLeft: '5px' }}>Paste the above Code</span></p>
                </div>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'start' }}>
                <SuiButton
                    onClick={() => {
                        handleClose(); setTimeout(() => {
                            setCopied(false)
                        }, 500)
                    }}
                    size="small"
                    variant="outlined"
                    buttonColor="dark"
                    sx={{ px: '1rem', py: '0.4rem' }}
                >
                    Cancel
                </SuiButton>
            </DialogActions>
        </Dialog>
    );
}

export default DialogBoxForEncryptedCode;
