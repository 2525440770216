import borders from "../../base/borders";
import pxToRem from "../../functions/pxToRem";

const { borderWidth } = borders;

export default {
  styleOverrides: {
    root: {
      padding: `${pxToRem(12)} ${pxToRem(16)}`,
      borderBottom: `${borderWidth[2]} solid #E9E9E9`,
      color: "#303841",
      fontWeight: "500",
      display: 'table-cell !important',
      maxWidth: '250px !important',
      fontSize: '14px !important',
      "&.MuiTableCell-head:not(.no-border)": {
        borderTop: `${borderWidth[2]} solid #E9E9E9`,
        fontWeight: 600, color: '#323232', fontSize: '15px !important',
        whiteSpace:'nowrap'
      },
    },
  },
};
