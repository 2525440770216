import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { cleanDigitSectionValue } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";
import colors from "assets/theme/base/colors";
import CommonSpinner from "components/CommonSpinner";
import Nodata from "layouts/customer/components/Nodata";
import React from "react";

const EnhancedTable = ({
  checkAll,
  tableMinWidth,
  isLoading,
  customClass = "",
  tablehead = [],
  rowsData = [],
  sortColumn,
  sortOrder,
  tableType,
  onSort,
  overFlow = 'auto !important'
}) => {
  const colSpan = checkAll ? tablehead.length + 1 : tablehead.length;
  const rows =
    rowsData?.length > 0 ? (
      rowsData
    ) : (
      <TableRow>
        <TableCell
          align="center"
          sx={{
            paddingTop: "100px",
            paddingBottom: "100px",
            fontSize: "1.5rem",
            fontWeight: 600,
          }}
          colSpan={colSpan}
        >
          <Nodata />
        </TableCell>
      </TableRow>
    );

  const tableBodyEl = isLoading ? (
    <TableRow>
      <TableCell
        align="center"
        sx={{
          paddingTop: "100px",
          paddingBottom: "100px",
        }}
        colSpan={colSpan}
      >
        <CommonSpinner size="100px" color={colors.primary.main} />
      </TableCell>
    </TableRow>
  ) : (
    rows
  );
  const headId = React.useId();
  const handleSort = (title) => {
    onSort(title);
  };

  return (
    <TableContainer  sx={{
      overflow: overFlow,
      "&::-webkit-scrollbar": {
        width: "5px",
        height:'4px !important',
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#777",
        borderRadius: "2px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#555",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#f1f1f1",
        borderRadius: "4px",
      },
      "tr:last-of-type td": { border: "none" },
    }}
  >
      <Table sx={{ minWidth: tableMinWidth }} size="medium">
        <TableHead>
          <TableRow>
            {checkAll ? (
              <TableCell className={customClass} align="center">
                {checkAll}
              </TableCell>
            ) : null}
            {tableType === "cdr"
              ? tablehead.map(({ title, align = "left", width = "auto", maxWidth = "100%" }, i) => (
                  <TableCell
                    component="th"
                    key={`${headId}_${i}`}
                    width={width}
                    sx={{
                      fontSize: 14,
                      fontWeight: "400",
                      color: "#848484",
                      textTransform: "capitalize",
                      maxWidth,
                    }}
                    align={align}
                    onClick={
                      title === "Amount" || title === "Duration (S)"
                        ? () => handleSort(title)
                        : undefined
                    }
                  >
                    {title}
                    {(title === "Amount" || title === "Duration (S)") && tableType === "cdr" && (
                      <>
                        <ArrowUpward
                          style={{
                            color: sortColumn === title && sortOrder === "asc" ? "black" : "#ccc",
                          }}
                        />
                        <ArrowDownward
                          style={{
                            color: sortColumn === title && sortOrder === "desc" ? "black" : "#ccc",
                          }}
                        />
                      </>
                    )}
                  </TableCell>
                ))
              : tablehead.map(({ id, align, width, label }) => (
                  <TableCell
                    className={customClass}
                    key={id}
                    align={align}
                    width={width}
                    sx={{
                      fontSize: 14,
                      fontWeight: "400",
                      color: "#848484",
                      textTransform: "capitalize",
                    }}
                  >
                    {label}
                  </TableCell>
                ))}

            {/* {tablehead.map(({ id, align, width, label }) => (
              <TableCell
                className={customClass}
                key={id}
                align={align}
                width={width}
                sx={{
                  // width: width,
                  fontSize: 14,
                  fontWeight: "400",
                  color: "#848484",
                  textTransform: "uppercase",
                }}
              >
                {label} */}
          </TableRow>
        </TableHead>
        <TableBody sx={{ "& td, & th": { fontSize: 14, paddingTop: '7px', paddingBottom: '7px', overflow: 'hidden' } }}>{tableBodyEl}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default EnhancedTable;
