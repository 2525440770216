import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setLocalStorageData } from "hooks/useLocalStorage";
import { getLocalStorageData } from "hooks/useLocalStorage";
import { Logout, setCredentials } from "../AuthSlice";
import { useNavigate } from "react-router-dom";
export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: async (headers) => {
    const token = await getLocalStorageData("auth_token_admin");
    headers.set("Public-Key", "1d5ee304-5de4-4490-8cfa-4b23a2bb5058");
    headers.set("x-appversion", "web");
    headers.set("x-platform", "web");
    if (token) {
      headers.set("authorization", `${token}`);
    }
    return headers;
  },
});
const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  const remember = JSON.parse(localStorage.getItem("isRemember_admin"));
  const token = localStorage.getItem("auth_token_admin") || "";
  if (token) {
    if (result?.error?.status === 403 || (!remember && result?.error?.status === 401)) {
      localStorage.removeItem('auth_token_admin')
      // localStorage.removeItem('type')
      window.location.reload();
    }
  }
  if (result?.error?.status === 401 || result?.error?.status === 403) {
    localStorage.removeItem('auth_token_admin');
    // localStorage.removeItem('type')
    window.location.reload();
    // const type =  localStorage.getItem('type');
    // if(type === 'tenant') {
    //   window.location.href= '/';
    // } else {
    //   window.location.href= '/admin-login';
    // }
    // localStorage.removeItem('type')
  }

  if (remember) {
    const loggedInTime = new Date(localStorage.getItem("loggedInTime_admin")).getTime();
    const tokenExpireat = localStorage.getItem("tokenExpiryTime_admin") * 1000;
    const refreshBefore = 15 * 60 * 1000;
    const shouldRefreshToken = loggedInTime
      ? loggedInTime + (tokenExpireat - refreshBefore) < new Date().getTime()
      : false;
    if (shouldRefreshToken) {
      const refreshResult = await baseQuery(
        {
          url: "admin/refreshtoken",
          method: "POST",
          body: {
            refresh_token: localStorage.getItem("refresh_token_admin"),
          },
        },
        api,
        extraOptions
      );

      if (refreshResult?.error) {
        api.dispatch(Logout());
      }
      if (refreshResult?.data.status === 200) {
        await setLocalStorageData("auth_token_admin", refreshResult?.data.data.token);
        await setLocalStorageData(
          "tokenExpiryTime_admin",
          refreshResult?.data.data.token_expire_at
        );
        await setLocalStorageData("loggedInTime_admin", new Date());
        await setLocalStorageData("refresh_token_admin", refreshResult?.data.data.refresh_token);
        api.dispatch(
          setCredentials({
            token: refreshResult?.data.data.token,
            isAuthenticated: true,
          })
        );
        result = await baseQuery(args, api, extraOptions);
      }
    }
  }
  return result;
};

export const baseAPIService = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ["COMMON_GET"],
  endpoints: () => ({}),
});
