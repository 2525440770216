import {
  Container,
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
  Typography,
} from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import React from "react";
import { Link } from "react-router-dom";
import notFoundImg from "assets/images/404-img.png";
import theme from "assets/theme";

const NotFound = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container>
          <SuiBox
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap={3}
            height="100dvh"
          >
            <SuiBox
              px={{ xs: 0, md: 4, lg: 8, xl: 12 }}
              component="img"
              maxWidth="100%"
              height={{ xs: 215, md: "auto" }}
              sx={{ objectFit: "cover" }}
              src={notFoundImg}
              alt="not-found"
            />
            <Typography variant="h5" fontWeight={600}>
              Page not Found!
            </Typography>
            <SuiButton component={Link} to="/profile" buttonColor="primary" sx={{ px: 7 }}>
              Back to Home
            </SuiButton>
          </SuiBox>
        </Container>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default NotFound;
