import colors from "../../base/colors";
import pxToRem from "../../functions/pxToRem";

const { error, label } = colors;

export default {
  styleOverrides: {
    root: {
      fontSize: pxToRem(13),
      fontWeight: "600",
      paddingBottom: pxToRem(4),
      color:label.main,
      display:"inline-flex !important",
      columnGap:"3px",
      flexDirection:"row-reverse",
    },
    asterisk: {
      color: error.focus,
    },
  },
};
