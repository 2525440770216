import PropTypes from "prop-types";
import SuiDropDownList from "components/SuiDropDownList";
import { accountType } from "./dropdownArrays";

const AccountTypeDropdown = ({ name, value, setValue, placeHolder, data, onBlur, disabled, formik }) => {
  const handleChange = (selectedValue) => {
    setValue(selectedValue);
    if (name === 't_country' && (formik.values.t_state || formik.values.t_city)) {
      formik.setFieldValue("t_state", "");
      formik.setFieldValue("t_city", "");
      formik.setFieldValue("t_city_name", "");

    }
    if (name === 't_state' && formik.values.t_city) {
      formik.setFieldValue("t_city", "");
      formik.setFieldValue("t_city_name", "");
    }
  };
  const shouldRemoveBorderRadius = true;

  return (
    <SuiDropDownList
      data={data}
      select={{
        key: "id",
        valueKey: "id",
        labelName: "label",
        label: (!placeHolder) ? "Select Role" : placeHolder,
        name: name,
        val: value,
        fn: handleChange,
      }}
      disabled={disabled}
      onBlur={onBlur}
      removeBorderRadius={shouldRemoveBorderRadius}
    />
  );
};

AccountTypeDropdown.defaultProps = {
  name: "account_type",
};

AccountTypeDropdown.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  setValue: PropTypes.func,
  placeHolder: PropTypes.any,
  // handlePostPaidSelection: PropTypes.func,
};

export default AccountTypeDropdown;
