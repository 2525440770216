import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  useMediaQuery,
  useTheme,
  Select,
  MenuItem,
  FormLabel,
  Grid,
  Checkbox,
} from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import Draggable from "react-draggable";
import AddIcon from "@mui/icons-material/Add";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import RemoveIcon from "@mui/icons-material/Remove";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import SuiButton from "components/SuiButton";
import colors from "assets/theme/base/colors";
import SuiInput from "components/SuiInput";
import { useGetTokenInfoMutation } from "app/features/api/AuthApi";
import SuiBox from "components/SuiBox";
import { CheckBox } from "@mui/icons-material";
function DraggablePaperComponent(props) {
  return (
    <Draggable
      handle=".draggable-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      bounds="parent"
    >
      <Paper {...props} />
    </Draggable>
  );
}
const ResponsiveDialogWithTable = ({
  open,
  handleClose,
  title,
  initialData,
  formik,
  currentRowIndex,
  rowData,
  handleSaveCalender,
  setSelectedCalender,
  selectedCalender,
}) => {
  const [rows, setRows] = useState(initialData);
  const [errors, setErrors] = useState([]);
  const [saveError, setSaveError] = useState("");
  const [getTokenInfo] = useGetTokenInfoMutation();
  const [tokenList, setTokenList] = useState([]);
  const newRowRef = useRef(null);

  const tokenResponse = async () => {
    try {
      const response = await getTokenInfo();
      if (response?.data?.statusCode === 200) {
        console.log(response?.data?.data);
        setTokenList(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (title === "Calender") {
      tokenResponse();
    }
  }, [title]);

  useEffect(() => {
    if (open) {
      setRows(initialData);
    }
  }, [open, initialData]);

  useEffect(() => {
    if (newRowRef.current) {
      newRowRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [rows.length]);

  const dialogTitle =
    title === "Selectable_Option"
      ? "Selectable Options"
      : "Choose Answer(Drop Down)";

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const validateQuestionToHit = (value, index) => {
    const maxQuestionToHit = rowData.length;
    const questionNumber = rowData[currentRowIndex].questionId;
    let error = "";

    if (!value) {
      error = "";
    } else if (value > maxQuestionToHit) {
      error = " Jump to value count exceeds the number of  question.";
    } else if (questionNumber === parseInt(value)) {
      error = "Jump to value must differ from question number.";
    } else if (value < questionNumber) {
      error = "Jump to value cannot be lesser than the question number.";
    }

    setErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[index] = { ...newErrors[index], questionToHit: error };
      return newErrors;
    });
  };

  const validateValue = (value, index) => {
    let error = "";

    if (!value) {
      error = "Value is required.";
    } else if (value.length < 2) {
      error = "Value must be at least 2 characters long.";
    }

    setErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[index] = { ...newErrors[index], value: error };
      return newErrors;
    });
  };

  const handleAddRow = () => {
    setRows([...rows, { value: "", questionToHit: "" }]);
    setErrors([...errors, { value: "", questionToHit: "" }]);
  };

  const handleRemoveRow = (index) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);

    const newErrors = [...errors];
    newErrors.splice(index, 1);
    setErrors(newErrors);
  };

  const handleInputChange = (index, field, value) => {
    if (field === "questionToHit") {
      validateQuestionToHit(value, index);
    } else if (field === "value") {
      validateValue(value, index);
    }

    const newRows = rows.map((row, i) =>
      i === index ? { ...row, [field]: value } : row
    );
    setRows(newRows);

    const questionToHitValues = newRows.map((row) => row.questionToHit);
    // eslint-disable-next-line no-undef
    const allFilledOrAllEmpty =
      questionToHitValues.every((val) => val === "") ||
      questionToHitValues.every((val) => val !== "");
    if (allFilledOrAllEmpty) {
      setSaveError("");
    } else if (field === "questionToHit") {
      setSaveError("All Jump to fields must be filled or all must be empty.");
    }
  };

  const handleSave = () => {
    // eslint-disable-next-line no-undef
    const hasErrors = rows.some((error) => {
      return error?.value === "" && error?.questionToHit === "";
    });
    if (hasErrors) {
      setSaveError("Kindly fill all the field values");
      return;
    }
    const nameArray = rows.map((row) => row.value);
    const allValueGreater = nameArray?.some((val) => val.length < 2);
    if (allValueGreater) return;

    const questionToHitValues = rows.map((row) => row.questionToHit);
    const allFilledOrAllEmpty =
      questionToHitValues.every((val) => val === "") ||
      questionToHitValues.every((val) => val !== "");
    const everyErrorEmpty = errors?.every(
      (val) => val?.questionToHit === "" || !val?.questionToHit
    );
    console.log(everyErrorEmpty, errors);
    if (!everyErrorEmpty) return;
    if (!allFilledOrAllEmpty) {
      setSaveError("All Jump to fields must be filled or all must be empty.");
      return;
    }
    const nameValue = rows.map((row) => row.value);
    const allNameFilledOrAllEmpty = nameValue.some((val) => val === "");
    if (allNameFilledOrAllEmpty) {
      setSaveError("Kindly fill the name value");
      return;
    }

    setSaveError("");
    const formattedData = rows.map((row, index) => ({
      id: parseInt(index + 1),
      value: row.value,
      questionToHit: row.questionToHit,
    }));
    handleClose(formattedData);
  };

  const maxRows =
    title === "Selectable_Option"
      ? 6
      : title === "Searchable_Dropdown"
      ? 10
      : Infinity;
  const isAddDisabled = rows.length >= maxRows;

  const handleSubmitCalender = (value) => {
    if (!value) {
      setSaveError("Kindly select calendar");
    } else {
      handleSaveCalender(value);
    }
  };

  return (
    <div>
      <Dialog
        disableScrollLock
        open={open}
        onClose={() => {
          handleClose(null);
          setErrors([]);
          setSaveError("");
        }}
        fullScreen={fullScreen}
        fullWidth
        maxWidth="md"
        PaperComponent={DraggablePaperComponent}
        PaperProps={{
          sx: {
            borderRadius: "8px",
            padding: "16px",
            // maxHeight: 375
          },
        }}
      >
        <SuiBox>
          {title === "Selectable_Option" || title === "Searchable_Dropdown" ? (
            <>
              <IconButton
                className="close-btn"
                onClick={() => {
                  handleClose(null);
                  setErrors([]);
                  setSaveError("");
                }}
                color="light"
                sx={{ bgcolor: `${colors.text.main} !important` }}
              >
                <CloseRoundedIcon />
              </IconButton>
              <SuiBox className="draggable-title" style={{ cursor: "pointer" }}>
                <DragIndicatorIcon />
              </SuiBox>
              <DialogTitle className="draggable-title" fontWeight="700" style={{ cursor: "pointer" }}>
                {dialogTitle}
              </DialogTitle>
              <DialogContent
                className="draggable-title"
                sx={{
                  maxHeight: 130,
                  overflowY: "auto",
                  "&::-webkit-scrollbar": {
                    width: "8px",
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "#f1f1f1",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#888",
                    borderRadius: "8px",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: "#555",
                  },
                }}
              >
                <TableContainer
                  component={Paper}
                  elevation={0}
                  sx={{ boxShadow: "none", borderRadius: 0 }}
                >
                  <Table sx={{ borderCollapse: "collapse", borderTop: 0 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ borderBottom: "none" }}>
                          NAME
                        </TableCell>
                        <TableCell sx={{ borderBottom: "none" }}>
                          JUMP To
                        </TableCell>
                        <TableCell sx={{ borderBottom: "none" }} />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row, index) => (
                        <React.Fragment key={index}>
                          <TableRow
                            ref={index === rows.length - 1 ? newRowRef : null}
                          >
                            <TableCell sx={{ borderBottom: "none" }}>
                              <SuiInput
                                fullWidth
                                inputProps={{ maxLength: 100 }} // Use inputProps if supported by the custom component
                                value={row.value}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "value",
                                    e.target.value
                                  )
                                }
                                placeholder={`Option ${index + 1}`}
                              />
                              {/* {errors[index] && errors[index].value && (
                              <Box mt={1} color="red" fontSize="12px">
                                {errors[index].value}
                              </Box>
                            )} */}
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: "none",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                minWidth: "200px",
                              }}
                            >
                              <TextField
                                type="number"
                                size="small"
                                value={row.questionToHit}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "questionToHit",
                                    e.target.value
                                  )
                                }
                                inputProps={{
                                  onWheel: (event) => event?.target?.blur(),
                                  sx: {
                                    width: "50px",
                                    height: "50px",
                                    textAlign: "center",
                                    maxWidth: "50px",
                                    border: "1px solid #E0E0E0",
                                    borderRadius: "4px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  },
                                  onKeyDown: (e) => {
                                    if (
                                      [
                                        "e",
                                        "E",
                                        "+",
                                        "-",
                                        ".",
                                        "ArrowUp",
                                        "ArrowDown",
                                      ].includes(e.key)
                                    ) {
                                      e.preventDefault();
                                    }
                                  },
                                }}
                              />
                              {/* {errors[index] && errors[index].questionToHit && (
                              <Box mt={1} color="red" fontSize="12px" textAlign="center">
                                {errors[index].questionToHit}
                              </Box>
                            )} */}
                            </TableCell>
                            <TableCell sx={{ borderBottom: "none" }}>
                              <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                              >
                                {rows.length !== 1 && (
                                  <IconButton
                                    onClick={() => {
                                      handleRemoveRow(index);
                                      setErrors([]);
                                      setSaveError("");
                                    }}
                                    disabled={rows.length === 1}
                                    sx={{
                                      color: "red",
                                      "&:hover": {
                                        backgroundColor: "transparent",
                                      },
                                    }}
                                  >
                                    <RemoveIcon
                                      sx={{
                                        background: "#FF1733",
                                        color: "#FFFFFF",
                                        padding: "2px",
                                        borderRadius: "5px",
                                      }}
                                    />
                                  </IconButton>
                                )}
                                {index === rows.length - 1 &&
                                  !isAddDisabled && (
                                    <IconButton
                                      onClick={handleAddRow}
                                      sx={{
                                        color: "blue",
                                        "&:hover": {
                                          backgroundColor: "transparent",
                                        },
                                      }}
                                      disabled={isAddDisabled}
                                    >
                                      <AddIcon
                                        sx={{
                                          background: colors.primary.main,
                                          color: "#FFFFFF",
                                          padding: "2px",
                                          borderRadius: "5px",
                                        }}
                                      />
                                    </IconButton>
                                  )}
                              </Box>
                            </TableCell>
                          </TableRow>
                          {console.log(errors, errors[index])}
                          {/* Adding an empty row for error display */}
                          {(errors[index]?.value ||
                            errors[index]?.questionToHit) && (
                            <TableRow>
                              <TableCell
                                colSpan={1}
                                sx={{ borderBottom: "none" }}
                              >
                                <Box color="red" fontSize="12px">
                                  {errors[index].value}
                                </Box>
                              </TableCell>
                              <TableCell
                                colSpan={1}
                                sx={{ borderBottom: "none" }}
                              >
                                <Box color="red" fontSize="12px">
                                  {errors[index]?.questionToHit}
                                </Box>
                              </TableCell>
                              {/* <TableCell></TableCell> */}
                            </TableRow>
                          )}
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </DialogContent>
              {saveError && (
                <Box mt={2} mb={2} sx={{ textAlign: "center" }}>
                  <h6
                    style={{
                      fontSize: ".9em",
                      color: "#FC3C3C",
                      fontWeight: 400,
                      transition: ".2s all",
                    }}
                  >
                    {saveError}
                  </h6>
                </Box>
              )}
              <DialogActions
                sx={{ justifyContent: "left", marginLeft: "15px",cursor: "pointer" }}
                className="draggable-title"
              >
                <SuiButton
                  onClick={() => {
                    handleClose(null);
                    setErrors([]);
                    setSaveError("");
                  }}
                  size="small"
                  variant="outlined"
                  buttonColor="dark"
                  sx={{ maxWidth: "94px !important" }}
                >
                  Cancel
                </SuiButton>
                <SuiButton
                  onClick={handleSave}
                  size="small"
                  variant="contained"
                  buttonColor="info"
                  sx={{ border: "1px solid #1264e2" }}
                >
                  Add
                </SuiButton>
              </DialogActions>
            </>
          ) : title === "Lead_Collection_Form" ? (
            <>
              <>
                <IconButton
                  className="close-btn"
                  onClick={() => {
                    handleSaveCalender(null);
                    setSaveError("");
                  }}
                  color="light"
                  sx={{ bgcolor: `${colors.text.main} !important` }}
                >
                  <CloseRoundedIcon />
                </IconButton>
                <DialogTitle fontWeight="700">Lead Collection Form</DialogTitle>
                <DialogContent
                  sx={{
                    maxHeight: 300,
                    overflowY: "auto",
                    "&::-webkit-scrollbar": {
                      width: "8px",
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "#f1f1f1",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#888",
                      borderRadius: "8px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      backgroundColor: "#555",
                    },
                  }}
                >
                  {/* <Select
                label="Select Calendar"
                fullWidth
                displayEmpty
                value={selectedCalender}
                onChange={e => { setSaveError(''); setSelectedCalender(e.target.value); }}
                renderValue={(selected) => {
                  if (!selected) {
                    return <em>Select Calendar</em>;
                  }
                  const item = tokenList.find(item => item.id === selectedCalender);
                  return item ? item.name : '';
                }}
              >
                <MenuItem value="" disabled>
                  <em>Select Calendar</em>
                </MenuItem>
                {tokenList?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select> */}
                  <Grid item xs={12} lg={6}>
                    <FormLabel htmlFor="t_name" required>
                      Form Title
                    </FormLabel>
                    <SuiInput
                      inputSettungs={{ autoComplete: "off", required: true }}
                      type="text"
                      placeholder="Enter Form Title"
                      id="t_name"
                      name="t_name"
                      // value={formik.values.t_name}
                      // onChange={formik.handleChange}
                      // onBlur={formik.handleBlur}
                    />
                    {/* <ErrorMessageComponent
                      touched={formik.touched.t_name}
                      error={formik.errors.t_name}
                    /> */}
                  </Grid>

                  <TableContainer
                    component={Paper}
                    elevation={0}
                    sx={{ boxShadow: "none", borderRadius: 0 }}
                  >
                    <Table sx={{ borderCollapse: "collapse", borderTop: 0 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ borderBottom: "none" }}>
                            DATA TO PUSH
                          </TableCell>
                          <TableCell sx={{ borderBottom: "none" }}>
                            REFERENCES
                            <br /> PARAMETERS
                          </TableCell>
                          <TableCell sx={{ borderBottom: "none" }}>
                            QUESTIONS
                          </TableCell>
                          <TableCell sx={{ borderBottom: "none" }}>
                            ANSWER
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row, index) => (
                          <React.Fragment key={index}>
                            <TableRow
                              ref={index === rows.length - 1 ? newRowRef : null}
                            >
                              <TableCell sx={{ borderBottom: "none" }}>
                                <Checkbox
                                  color="secondary"
                                  // checked={row.isPushed}
                                  style={{ borderRadius: "0 !important" }}
                                  sx={{
                                    "& svg": {
                                      borderRadius: "1px !important",
                                    },
                                    "& .MuiIconButton-root": {
                                      borderRadius: "1px !important",
                                    },
                                    "&.MuiCheckbox-root": {
                                      borderRadius: "1px !important",
                                    },
                                    "& .css-1me0qvy-MuiButtonBase-root-MuiCheckbox-root":
                                      {
                                        borderRadius: "1px !important",
                                      },
                                  }}
                                  // onChange={() => handleCheckboxChange(id, id)}
                                />
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderBottom: "none",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  minWidth: "200px",
                                }}
                              >
                                <TextField
                                  type="number"
                                  size="small"
                                  value={row.questionToHit}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "questionToHit",
                                      e.target.value
                                    )
                                  }
                                  inputProps={{
                                    onWheel: (event) => event?.target?.blur(),
                                    sx: {
                                      width: "50px",
                                      height: "50px",
                                      textAlign: "center",
                                      maxWidth: "50px",
                                      border: "1px solid #E0E0E0",
                                      borderRadius: "4px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    },
                                    onKeyDown: (e) => {
                                      if (
                                        [
                                          "e",
                                          "E",
                                          "+",
                                          "-",
                                          ".",
                                          "ArrowUp",
                                          "ArrowDown",
                                        ].includes(e.key)
                                      ) {
                                        e.preventDefault();
                                      }
                                    },
                                  }}
                                />
                                {/* {errors[index] && errors[index].questionToHit && (
                              <Box mt={1} color="red" fontSize="12px" textAlign="center">
                                {errors[index].questionToHit}
                              </Box>
                            )} */}
                              </TableCell>
                              <TableCell sx={{ borderBottom: "none" }}>
                                <Box
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  {rows.length !== 1 && (
                                    <IconButton
                                      onClick={() => {
                                        handleRemoveRow(index);
                                        setErrors([]);
                                        setSaveError("");
                                      }}
                                      disabled={rows.length === 1}
                                      sx={{
                                        color: "red",
                                        "&:hover": {
                                          backgroundColor: "transparent",
                                        },
                                      }}
                                    >
                                      <RemoveIcon
                                        sx={{
                                          background: "#FF1733",
                                          color: "#FFFFFF",
                                          padding: "2px",
                                          borderRadius: "5px",
                                        }}
                                      />
                                    </IconButton>
                                  )}
                                  {index === rows.length - 1 &&
                                    !isAddDisabled && (
                                      <IconButton
                                        onClick={handleAddRow}
                                        sx={{
                                          color: "blue",
                                          "&:hover": {
                                            backgroundColor: "transparent",
                                          },
                                        }}
                                        disabled={isAddDisabled}
                                      >
                                        <AddIcon
                                          sx={{
                                            background: colors.primary.main,
                                            color: "#FFFFFF",
                                            padding: "2px",
                                            borderRadius: "5px",
                                          }}
                                        />
                                      </IconButton>
                                    )}
                                </Box>
                              </TableCell>
                            </TableRow>
                            {console.log(errors, errors[index])}
                            {/* Adding an empty row for error display */}
                            {(errors[index]?.value ||
                              errors[index]?.questionToHit) && (
                              <TableRow>
                                <TableCell
                                  colSpan={1}
                                  sx={{ borderBottom: "none" }}
                                >
                                  <Box color="red" fontSize="12px">
                                    {errors[index].value}
                                  </Box>
                                </TableCell>
                                <TableCell
                                  colSpan={1}
                                  sx={{ borderBottom: "none" }}
                                >
                                  <Box color="red" fontSize="12px">
                                    {errors[index]?.questionToHit}
                                  </Box>
                                </TableCell>
                              </TableRow>
                            )}
                          </React.Fragment>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </DialogContent>

                {saveError && (
                  <Box mt={2} mb={2} sx={{ textAlign: "center" }}>
                    <h6
                      style={{
                        fontSize: ".9em",
                        color: "#FC3C3C",
                        fontWeight: 400,
                        transition: ".2s all",
                      }}
                    >
                      {saveError}
                    </h6>
                  </Box>
                )}
                <DialogActions
                  sx={{ justifyContent: "left", marginLeft: "15px" }}
                >
                  <SuiButton
                    onClick={() => {
                      handleSaveCalender(null);
                      setSaveError("");
                    }}
                    size="small"
                    variant="outlined"
                    buttonColor="dark"
                    sx={{ px: "1rem", py: "0.4rem" }}
                  >
                    Cancel
                  </SuiButton>
                  <SuiButton
                    onClick={() => handleSubmitCalender(selectedCalender)}
                    size="small"
                    variant="contained"
                    buttonColor="info"
                    sx={{ px: "1rem", py: "0.4rem" }}
                  >
                    Add
                  </SuiButton>
                </DialogActions>
              </>
            </>
          ) : (
            <>
              <IconButton
                className="close-btn"
                onClick={() => {
                  handleSaveCalender(null);
                  setSaveError("");
                }}
                color="light"
                sx={{ bgcolor: `${colors.text.main} !important` }}
              >
                <CloseRoundedIcon />
              </IconButton>
              <DialogTitle fontWeight="700">Select Calendar</DialogTitle>
              <DialogContent
                sx={{
                  maxHeight: 300,
                  overflowY: "auto",
                  "&::-webkit-scrollbar": {
                    width: "8px",
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "#f1f1f1",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#888",
                    borderRadius: "8px",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: "#555",
                  },
                }}
              >
                <Select
                  label="Select Calendar"
                  fullWidth
                  displayEmpty
                  value={selectedCalender}
                  onChange={(e) => {
                    setSaveError("");
                    setSelectedCalender(e.target.value);
                  }}
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em>Select Calendar</em>;
                    }
                    const item = tokenList.find(
                      (item) => item.id === selectedCalender
                    );
                    return item ? item.name : "";
                  }}
                >
                  <MenuItem value="" disabled>
                    <em>Select Calendar</em>
                  </MenuItem>
                  {tokenList?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </DialogContent>

              {saveError && (
                <Box mt={2} mb={2} sx={{ textAlign: "center" }}>
                  <h6
                    style={{
                      fontSize: ".9em",
                      color: "#FC3C3C",
                      fontWeight: 400,
                      transition: ".2s all",
                    }}
                  >
                    {saveError}
                  </h6>
                </Box>
              )}
              <DialogActions
                sx={{ justifyContent: "left", marginLeft: "15px" }}
              >
                <SuiButton
                  onClick={() => {
                    handleSaveCalender(null);
                    setSaveError("");
                  }}
                  size="small"
                  variant="outlined"
                  buttonColor="dark"
                  sx={{ px: "1rem", py: "0.4rem" }}
                >
                  Cancel
                </SuiButton>
                <SuiButton
                  onClick={() => handleSubmitCalender(selectedCalender)}
                  size="small"
                  variant="contained"
                  buttonColor="info"
                  sx={{ px: "1rem", py: "0.4rem" }}
                >
                  Add
                </SuiButton>
              </DialogActions>
            </>
          )}
        </SuiBox>
      </Dialog>
    </div>
  );
};

export default ResponsiveDialogWithTable;
