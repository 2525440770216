import {
  Card,
  Grid,
  FormLabel,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import ErrorMessageComponent from "components/FormikErrorMessage";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AccountTypeDropdown from "components/SuiDropDownList/AccountTypeDropdown";
import {
  useGetTemplateInfoMutation,
  useGetPlanInfoMutation,
  useGetReferenceParametersInfoMutation,
  useCreateTemplateMutation,
  useTemplateGetByIdMutation,
  useUpdateTemplateMutation,
  useGetAllPlanInfoMutation,
} from "app/features/api/AuthApi";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import CommonSpinner from "components/CommonSpinner";
import colors from "assets/theme/base/colors";
import WrapCard from "Container/Cards/WrapCard";
import RowOrderingGrid from "./TemplateFormTable";
import {
  createTemplateValidation,
  createTenantTemplateValidation,
} from "Validation";
import TenantChatBot from "./TenantChatBot";
import { answersData, answersDataWithCalender } from "./answer";
import { useSelector } from "react-redux";
import SuiTypography from "components/SuiTypography";

const CreateTenant = () => {
  const [mobileInValid, setMobileInValid] = useState(false);
  const navigate = useNavigate();
  const suiSnackbar = useSuiSnackbar();
  const [createTemplate, { isLoading: createLoader }] =
    useCreateTemplateMutation();
  const { id } = useParams();
  const [templateGetById, { isLoading: loader }] = useTemplateGetByIdMutation();
  const [templateData, setTemplateData] = useState([]);
  const [isMergedFalg, setIsMergedFalg] = useState(false);
  const [updateTemplate, { isLoading: updateLoader }] =
    useUpdateTemplateMutation();
  const [getTemplateInfo, { isLoading }] = useGetTemplateInfoMutation();
  const [getReferenceParametersInfo] = useGetReferenceParametersInfoMutation();
  const [referencePara, setReferencePara] = useState([]);
  const [plans, setPlans] = useState([]);
  const [templateDataById, setTemplateDataById] = useState([]);
  const [planType, setPlanType] = useState(null);
  const [answerArray, setAnswerArray] = useState(answersData);
  const [templateDataForROws, setTemplateDataForROws] = useState([]);
  const type = localStorage.getItem("type");
  const subscriptionType = localStorage.getItem("subscriptionType");
  const { permission } = useSelector((state) => state.auth);
  const loginType = localStorage.getItem("type");
  useEffect(() => {
    if (
      permission &&
      loginType === "admin" &&
      !permission?.includes("chatbot:create")
    ) {
      navigate("/profile");
    }
  }, [permission]);
  const initialValues = {
    name: id ? templateDataById?.templateName : "",
    subscriptionPlanId: id
      ? parseInt(templateDataById?.subscriptionPlanId)
      : "",
    data: id ? templateDataForROws : [],
    xcally: id ? templateDataById?.xcally ?? false : false,
    variantKey: id ? templateDataById?.variantKey ?? "" : "",
  };

  const validateParamValue = (rows) => {
    let isValid = true;
    const updatedRows = [...rows];
    const queJumpToHit = rows?.map((row, index) => row.questionId);
    updatedRows.forEach((row, index) => {
      if (
        type === "tenant" &&
        row.answerType === "Calender" &&
        !row.calendlyIntegrationId
      ) {
        formik.setFieldError(
          `data.${index}.answerType`,
          "Kindly select calendar"
        );
        isValid = false;
      }
    });

    updatedRows.forEach((row, index) => {
      if (
        row.answerType === "Selectable_Option" ||
        row.answerType === "Searchable_Dropdown"
      ) {
        if (!row.paramValue || row.paramValue.length === 0) {
          formik.setFieldError(
            `data.${index}.answerType`,
            "Please fill the value in the dialog box"
          );
          isValid = false;
        }
        if (row.paramValue) {
          const paramvalueQueToHit = row.paramValue.map(
            (val) => val.questionToHit
          );
          paramvalueQueToHit.forEach((val) => {
            if (val && !queJumpToHit?.includes(parseInt(val))) {
              formik.setFieldError(
                `data.${index}.answerType`,
                "Jump to value exceeds the number of questions."
              );
              isValid = false;
            }
          });
        }
      }
    });
    return isValid;
  };
  const [getAllPlanInfo] = useGetAllPlanInfoMutation();
  const tenantSubscriptionPlan = localStorage.getItem("subscriptionType");
  const tenantSubscriptionType = tenantSubscriptionPlan?.split(", ");

  const fetchTenantById = async () => {
    try {
      const response = await templateGetById(id).unwrap();
      const transformedResponse = response.data.cb_Questions.map((item) => ({
        id: item.id,
        isPushed: item.isPushed,
        questionId: item.questionId,
        questionName: item.questionName || "",
        answerType: item.answerType || "",
        referenceParameterId:
          item.cb_ReferenceParameter &&
          item.cb_ReferenceParameter.type === "custom"
            ? "custom"
            : item.referenceParameterId,
        name:
          item.cb_ReferenceParameter &&
          item.cb_ReferenceParameter.type === "custom"
            ? item.cb_ReferenceParameter.name
            : "",
        paramValue:
          item.cb_Answers.length > 0 ? item.cb_Answers[0].paramValue : [],
        questionToHit: item.questionToHit || "",
        calendlyIntegrationId: item?.calendlyIntegrationId,
      }));
      setTemplateDataById(response.data);
      const sortedData = transformedResponse?.sort(
        (a, b) => a.questionId - b.questionId
      );
      setTemplateDataForROws(sortedData);
    } catch (err) {
      console.log(err);
    }
  };

  const getPlans = async () => {
    try {
      const response = await getAllPlanInfo();
      const res = response?.data?.data?.map((item) => {
        let typeArray =
          item.type === "xcally, calendly"
            ? item.type.split(", ").map((type) => type.trim())
            : [item.type];
        return { id: item?.id, label: item?.subscriptionName, type: typeArray };
      });
      setPlans(res);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchTemplate = async () => {
    try {
      const response = await getTemplateInfo().unwrap();
      if (response.statusCode === 200) {
        {
          response?.data?.templates?.map((item) => {
            if (item.isMergeTemplate) {
              setIsMergedFalg(true);
            }
          });
        }

        setTemplateData(response?.data?.templates);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchReferenceParameter = async () => {
    try {
      const response = await getReferenceParametersInfo().unwrap();
      if (response.statusCode === 200) {
        setReferencePara(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchTemplate();
    fetchReferenceParameter();
    getPlans();
    if (id) fetchTenantById();
  }, []);

  const removeEmptyQuestionToHit = (data) => {
    return data.map((item) => {
      const {
        questionToHit,
        referenceParameterId,
        calendlyIntegrationId,
        ...rest
      } = item;

      if (questionToHit === "" && referenceParameterId === "") {
        return rest;
      }

      const newItem = { ...item };
      if (!calendlyIntegrationId) {
        delete newItem.calendlyIntegrationId;
      }

      if (item?.answerType !== "Calender" && calendlyIntegrationId) {
        delete newItem.calendlyIntegrationId;
      }
      if (questionToHit === "" || questionToHit === null) {
        delete newItem.questionToHit;
      }
      if (referenceParameterId === "" || referenceParameterId === null) {
        delete newItem.referenceParameterId;
      }
      return newItem;
    });
  };

  const formik = useFormik({
    initialValues,
    validationSchema:
      type === "tenant"
        ? createTenantTemplateValidation
        : createTemplateValidation,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const deleteId = values.questionsToDelete ? values.questionsToDelete : [];
      let processedData;

      if (type === "tenant") {
        delete values.subscriptionPlanId;
      }
      values.data?.map((item) => {
        if (
          item.answerType === "Selectable_Option" ||
          item.answerType === "Searchable_Dropdown"
        ) {
          item.questionToHit = null;
        }
      });
      if (id) {
        const cleanedData = values.data.map((item) => {
          const { cb_Answers, cb_ReferenceParameter, ...rest } = item;
          return rest;
        });
        processedData = removeEmptyQuestionToHit(cleanedData);
      } else {
        processedData = removeEmptyQuestionToHit(values.data);
      }

      // if (values) {
      delete values.variantKey;
      delete values.xcally;
      // }
      // if (planType?.includes('xcally') && !values.variantKey) {
      //   delete values.variantKey;
      // }
      try {
        if (validateParamValue(values.data)) {
          const response = id
            ? await updateTemplate({
                data: {
                  ...values,
                  data: processedData,
                  questionsToDelete: deleteId,
                  ...(isMergedFalg && { isMergeTemplate: true }),
                },
                id,
              }).unwrap()
            : await createTemplate({ ...values, data: processedData }).unwrap();
          if (response.statusCode === 200) {
            suiSnackbar(response.message, "success");
            navigate("/chatbot-template");
          }
        }
      } catch (err) {
        suiSnackbar(err.data.message, "error");
      }
    },
  });
  useEffect(() => {
    if (formik.values.subscriptionPlanId) {
      const planId = formik.values.subscriptionPlanId;
      const plan = plans.filter((item) => item.id === planId);

      if (plan.length > 0) {
        const planType = plan[0].type;
        setPlanType(planType);
        let updatedAnswers = [...answersData];
        if (planType.includes("calendly")) {
          setAnswerArray(answersDataWithCalender);
        } else {
          setAnswerArray(answersData);
          updatedAnswers = updatedAnswers.filter(
            (item) => item.value !== "Calender"
          );
        }
      }
    }
    if (tenantSubscriptionType.includes("calendly"))
      setAnswerArray(answersDataWithCalender);
  }, [formik.values.subscriptionPlanId, plans]);

  return (
    <div>
      {type === "tenant" && !id ? (
        <TenantChatBot />
      ) : (
        <WrapCard
          title={
            id ? "Update Template Configuration" : "Template Configuration"
          }
        >
          {loader ? (
            <CommonSpinner size="100px" color={colors.primary.main} />
          ) : (
            <>
              <Grid component="form" container spacing={2} rowSpacing={3}>
                <Grid item xs={12} lg={6}>
                  <div>
                    <FormLabel htmlFor="name" required>
                      Template Name
                    </FormLabel>
                    <SuiInput
                      inputSettungs={{ autoComplete: "off", required: true }}
                      type="text"
                      placeholder="Enter Template Name"
                      id="name"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <ErrorMessageComponent
                      touched={formik.touched.name}
                      error={formik.errors.name}
                    />
                  </div>
                </Grid>
                {type !== "tenant" && (
                  <Grid item xs={12} lg={6}>
                    <div>
                      <FormLabel htmlFor="subscriptionPlanId" required>
                        Subscription Plan
                      </FormLabel>
                      {/* <SuiTypography
                        variant="h6"
                        mt={1}
                        ml={3}
                        sx={{ color: "#999999 !important" }}
                      >
                        Fixed
                      </SuiTypography> */}
                      <AccountTypeDropdown
                        data={plans}
                        name="subscriptionPlanId"
                        value={formik.values.subscriptionPlanId}
                        setValue={formik.handleChange}
                        placeHolder="Select Subscription Plan"
                        onBlur={formik.handleBlur}
                         disabled={id ? true : false}
                      />
                      <ErrorMessageComponent
                        touched={formik.touched.subscriptionPlanId}
                        error={formik.errors.subscriptionPlanId}
                      />
                    </div>
                  </Grid>
                )}
                <Grid item xs={12} lg={6}>
                  <div>
                    <FormLabel htmlFor="name" required>
                      Chatbot Name
                    </FormLabel>
                    <SuiInput
                      inputSettungs={{ autoComplete: "off", required: true }}
                      type="text"
                      placeholder="Enter Chatbot Name"
                      id="name"
                      name="name"
                      // value={formik.values.name}
                      // onChange={formik.handleChange}
                      // onBlur={formik.handleBlur}
                    />
                    <ErrorMessageComponent
                      touched={formik.touched.name}
                      error={formik.errors.name}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <RowOrderingGrid
                    templateData={templateData}
                    referencePara={referencePara}
                    formik={formik}
                    planType={planType}
                    answersData={answerArray}
                    loginType={type}
                  />
                </Grid>
                {/* {planType?.includes('xcally') && (
                  <>
                    <Grid item xs={12} lg={6}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="xcally"
                              value={formik.values.xcally}
                              checked={formik.values.xcally}
                              onChange={(e) => formik.setFieldValue('xcally', e.target.checked)}
                              color="secondary"
                              style={{ padding: 0 }}
                            />
                          }
                        />
                        <FormLabel htmlFor="xcally" sx={{ fontWeight: 600 }}>
                          Xcally
                        </FormLabel>
                      </div>
                      <ErrorMessageComponent
                        touched={formik.touched.xcally}
                        error={formik.errors.xcally}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}></Grid>
                    <Grid item xs={12} lg={4}>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <SuiInput
                          size="large"
                          inputSettungs={{ autoComplete: "off", required: true }}
                          type="text"
                          placeholder="Enter Variant Key"
                          id="variantKey"
                          name="variantKey"
                          value={formik.values.variantKey}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <ErrorMessageComponent
                          touched={formik.touched.variantKey}
                          error={formik.errors.variantKey}
                        />
                      </div>
                    </Grid>
                  </>
                )} */}
              </Grid>
              <Grid item xs={12}>
                <Grid container pb={2} pt={4} columnSpacing={2} rowSpacing={1}>
                  <Grid item>
                    <SuiButton
                      size="small"
                      component={Link}
                      to="/chatbot-template"
                      variant="outlined"
                      buttonColor="dark"
                      sx={{ px: "1rem", py: "0.4rem" }}
                    >
                      Cancel
                    </SuiButton>
                  </Grid>
                  <Grid item>
                    <SuiButton
                      type="submit"
                      size="small"
                      buttonColor="info"
                      sx={{ px: "1rem" }}
                      onClick={formik.handleSubmit}
                    >
                      {id ? (
                        updateLoader ? (
                          <CircularProgress size="20px" color="inherit" />
                        ) : (
                          "Update"
                        )
                      ) : createLoader ? (
                        <CircularProgress size="20px" color="inherit" />
                      ) : (
                        "Create"
                      )}
                    </SuiButton>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </WrapCard>
      )}
    </div>
  );
};

export default CreateTenant;
