import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  InputLabel,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import SuiPasswordInput from "components/SuiPasswordInput";
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import { useFormik } from "formik";
import { loginWithPasswordSchema } from "Validation";
import ErrorMessageComponent from "components/FormikErrorMessage";
import { useDispatch } from "react-redux";
import { setCredentials } from "app/features/AuthSlice";
import { useLoginWithPasswordMutation, useGetAllPermissionAdminMutation } from "app/features/api/AuthApi";
import { useEffect, useState } from "react";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import MailIcon from "@mui/icons-material/Mail";
import LockIcon from "@mui/icons-material/Lock";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";

const LoginWithPass = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginWithPassword] = useLoginWithPasswordMutation();
  const [getAllPermissionAdmin] = useGetAllPermissionAdminMutation()
  const [remember, setRemember] = useState(false);
  const [loginwithPasswordError, setLoginwithPasswordError] = useState("");
  const suiSnackbar = useSuiSnackbar();
  const secretKey = process.env.REACT_APP_SECRET_KEY;
  const isRemembered = localStorage.getItem("isRemember_admin");
  const [decryptedData, setDecryptedData] = useState({
    decryptedEmail: "",
    decryptedPassword: "",
  });
  const encryptedEmail = Cookies?.get("adminEmail");
  const encryptedPassword = Cookies?.get("adminPassword");

  useEffect(() => {
    const parsedIsRemembered = isRemembered === "true";
    if (parsedIsRemembered && encryptedEmail && encryptedPassword) {
      const decryptedEmail = CryptoJS.AES.decrypt(
        encryptedEmail,
        secretKey
      ).toString(CryptoJS.enc.Utf8);
      const decryptedPassword = CryptoJS.AES.decrypt(
        encryptedPassword,
        secretKey
      ).toString(CryptoJS.enc.Utf8);
      setDecryptedData({ decryptedEmail, decryptedPassword });
      setRemember(parsedIsRemembered);
    }
  }, [isRemembered, encryptedEmail, encryptedPassword, secretKey]);

  const formik = useFormik({
    initialValues: {
      email: decryptedData?.decryptedEmail,
      password: decryptedData?.decryptedPassword,
    },
    validationSchema: loginWithPasswordSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const body = {
        email: values.email,
        password: values.password,
      };
      try {
        const response = await loginWithPassword(body).unwrap();
        const encryptedEmail = CryptoJS.AES.encrypt(
          values.email,
          secretKey
        ).toString();
        const encryptedPassword = CryptoJS.AES.encrypt(
          values.password,
          secretKey
        ).toString();

        const expires = remember ? 365 : undefined;
        Cookies.set("adminEmail", encryptedEmail, { expires });
        Cookies.set("adminPassword", encryptedPassword, { expires });

        if (response.statusCode === 200) {
          localStorage.setItem('type', response.data.type)
          localStorage.setItem("auth_token_admin", response.data.accessToken);
          localStorage.setItem(
            "tokenExpiryTime_admin",
            response.data.token_expire_at
          );
          localStorage.setItem("loggedInTime_admin", new Date());
          localStorage.setItem(
            "refresh_token_admin",
            response.data.refresh_token
          );
          localStorage.setItem("isRemember_admin", remember.toString());
          sessionStorage.setItem("isRemember_admin", remember.toString());
          dispatch(
            setCredentials({
              token_admin: response.data.accessToken,
              isAuthenticated: true,
            })
          );
          navigate("/dashboard");
        }
      } catch (error) {
        suiSnackbar(error?.data?.message, "error");
      }
    },
  });

  const handleSetRememberMe = () => {
    setRemember(!remember);
  };

  console.log('admin');

  return (
    <>
      <SuiTypography mb={3} fontWeight="bold">
        Login to your Account
      </SuiTypography>
      <SuiBox mb={1}>
        <InputLabel required sx={{fontWeight:"600 !Important"}}>Email ID</InputLabel>
      </SuiBox>

      <SuiBox mt={0} mb={3.5} style={{ position: "relative" }}>
        <MailIcon
          style={{
            fill: "none",
            stroke: "currentColor",
            strokeWidth: 2,
            position: "absolute",
            left: 10,
            top: 12,
            zIndex: 9,
            fontSize: "15px !important",
          }}
        />
        <SuiBox mb={3}>
          <SuiInput
            placeholder="Enter Email ID"
            inputProps={{
              sx: { pl: 4 },
              "& .MuiInputBase-input::placeholder": {
                fontSize: "larger !important",
              },
            }}
            inputSettungs={{ autoComplete: "off", required: true }}
            type="email"
            id="email"
            name="email"
            value={formik.values.email}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          <ErrorMessageComponent
            touched={formik.touched.email}
            error={formik.errors.email}
          />
        </SuiBox>
      </SuiBox>

      <SuiBox mb={1}>
        <InputLabel required sx={{fontWeight:"600 !Important"}}>Password</InputLabel>
      </SuiBox>

      <SuiBox mb={2} style={{ position: "relative" }}>
        <LockIcon
          style={{
            fill: "none",
            stroke: "currentColor",
            strokeWidth: 2,
            position: "absolute",
            left: 10,
            top: 11,
            zIndex: 9,
            fontSize: "15px !important",
          }}
        />
        <SuiBox>
          <SuiPasswordInput
            inputProps={{ pl: 4 }}
            name="password"
            placeholder="Enter Password"
            value={formik.values.password}
            getValue={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <ErrorMessageComponent
            touched={formik.touched.password}
            error={formik.errors.password}
          />
        </SuiBox>
      </SuiBox>

      <Grid item sx={{ pb: 1 }}>
        {Object.keys(formik.errors)?.length === 0 && loginwithPasswordError && (
          <ErrorMessageComponent
            touched={loginwithPasswordError}
            error={loginwithPasswordError}
          />
        )}
      </Grid>

      <SuiBox display="flex" alignItems="center" justifyContent="center">
        <FormControlLabel
          value="end"
          control={
            <Checkbox
              color="secondary"
              checked={remember}
              onChange={handleSetRememberMe}
            />
          }
          label="Remember me"
          sx={{
            m: 0,
            "& .MuiTypography-root": {
              color: "#848484",
              fontWeight: 400,
            },
          }}
        />
      </SuiBox>

      <SuiBox mt={2} mb={0.5}>
        <SuiButton
          tabIndex={4}
          type="submit"
          disabled={formik.isSubmitting}
          variant="contained"
          buttonColor="info"
          fullWidth
          onClick={formik.handleSubmit}
        >
          {formik.isSubmitting ? (
            <CircularProgress size="25px" color="inherit" />
          ) : (
            "Login"
          )}
        </SuiButton>
      </SuiBox>
    </>
  );
};

export default LoginWithPass;
