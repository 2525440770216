import { makeStyles } from "@mui/styles";

export default makeStyles(({ palette, transitions, breakpoints, typography, functions }) => {
  const { transparent, text2 } = palette;
  const { fontWeightMedium } = typography;
  const { pxToRem } = functions;

  return {
    collapse_item: {
      background: transparent.main,
      display: "flex",
      alignItems: "center",
      borderRadius: 0,
      width: "100%",
      margin: "3px 0 !important",
      padding: "10px 8px !important",
      paddingLeft: "30px !important",
      userSelect: "none",
      whiteSpace: "nowrap",
      boxShadow: "none",
    },
    collapse_iconBox: {
      background: transparent.main,
      minWidth: "auto",
      position: 'relative',
      height: 20,
    },
    collapse_item_bar: {
      position: 'absolute',
      top: -13,
      left: -0.5,
      width: 3,
      height: 'calc(100% + 26px)',
      borderRadius: 7,
      backgroundColor: transparent.main,
      "&.active": {
        backgroundColor: '#161740',
      }
    },

    collapse_text: {
      marginLeft: pxToRem(12),

      [breakpoints.up("xl")]: {
        opacity: ({ miniSidenav, transparentSidenav }) =>
          miniSidenav || (miniSidenav && transparentSidenav) ? 0 : 1,
        maxWidth: ({ miniSidenav, transparentSidenav }) =>
          miniSidenav || (miniSidenav && transparentSidenav) ? 0 : "100%",
        transition: transitions.create(["opacity", "margin"], {
          easing: transitions.easing.easeInOut,
          duration: transitions.duration.standard,
        }),
      },

      "& span": {
        fontWeight: fontWeightMedium,
        fontSize: 13,
        lineHeight: 0,
        color: ({ active }) => (active ? "#161740" : text2.focus),
      },
    },
  };
});
