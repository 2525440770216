import { Box, IconButton, InputAdornment } from "@mui/material";
import React, { useRef } from "react";
import SuiInput from ".";
import ArrowDropUpRoundedIcon from "@mui/icons-material/ArrowDropUpRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import colors from "../../assets/theme/base/colors";

const ArrowButton = ({ children, ...rest }) => (
  <IconButton
    size="small"
    {...rest}
    sx={{
      bgcolor: "#fff !important",
      borderRadius: "5px",
      padding: "0 !important",
      color: colors.primary.main,
      maxWidth: 30,
      maxHeight: 16,
      fontSize: 24,
      boxShadow: "1px 2px 0 0 #D9DBE7",
    }}
  >
    {children}
  </IconButton>
);

const NewNumberInput = ({ name, value, placeholder, onChange, onBlur, required }) => {
  const numberInputRef = useRef(null);

  const handleIncrement = () => {
    let currentValue = parseFloat(numberInputRef.current.value) || 0;
    currentValue += 1;
    numberInputRef.current.value = currentValue;
    onChange({ target: { name, value: currentValue } });
  };

  const handleDecrement = () => {
    let currentValue = parseFloat(numberInputRef.current.value) || 0;
    currentValue = currentValue > 1 ? currentValue - 1 : 0;
    numberInputRef.current.value = currentValue;
    onChange({ target: { name, value: currentValue } });
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    const numericValue = parseFloat(inputValue);
    if (!isNaN(numericValue) || inputValue === '') {
      onChange(e);
    }
  };

  return (
    <Box position="relative">
      <SuiInput
        type="text"
        size="medium"
        name={name}
        id={name}
        value={value}
        placeholder={placeholder}
        inputSettungs={{
          autoComplete: "off",
          required: Boolean(required),
        }}
        inputProps={{
          ref: numberInputRef,
          pattern: "[0-9]*",
          inputMode: "numeric",
          sx: {
            pr: "2.5rem",
            px: 3,
          },
        }}
        onChange={handleChange}
        onBlur={onBlur}
      />
      <Box
        position="absolute"
        display="flex"
        flexDirection="column"
        rowGap={0.5}
        top="49%"
        right={10}
        sx={{ transform: "translateY(-50%)" }}
      >
        <ArrowButton onClick={handleIncrement}>
          <ArrowDropUpRoundedIcon />
        </ArrowButton>
        <ArrowButton onClick={handleDecrement}>
          <ArrowDropDownRoundedIcon />
        </ArrowButton>
      </Box>
    </Box>
  );
};

export default NewNumberInput;
