import {
  Box,
  Pagination,
  PaginationItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

const CommonPagination = ({
  totalData,
  currentPage = 1,
  onPageChange,
  pageSize = 12,
  mt = 4,
}) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box display="flex" justifyContent="center" alignItems="center" mt={mt}>
      <Pagination
        sx={{
          ".MuiPagination-ul": { margin: "0px 10px", gap: "2px" },
          ".MuiPaginationItem-page": { color: "#151515" },
          ".Mui-selected": { color: "#fff !important" },
          ".MuiPaginationItem-root": {
            borderRadius: "5px",
            backgroundColor: "#e3e8f0",
            fontWeight: 600,
          },
        }}
        page={Number(currentPage)}
        count={Math.ceil(totalData / pageSize)}
        color="primary"
        // siblingCount={0} boundaryCount={3}
        siblingCount={mobileView ? 0 : 1}
        onChange={(e, value) => {
          onPageChange(e, value);
        }}
        size="large"
        renderItem={(item) => (
          <PaginationItem
            slots={{
              previous: () => (
                <Typography
                  variant="label"
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  fontWeight={600}
                >
                  <ChevronLeftRoundedIcon
                    sx={{ fontSize: "25px !important" }}
                  />
                  Previous
                </Typography>
              ),
              next: () => (
                <Typography
                  variant="label"
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  fontWeight={600}
                >
                  Next
                  <ChevronRightRoundedIcon
                    sx={{ fontSize: "25px !important" }}
                  />
                </Typography>
              ),
            }}
            {...item}
          />
        )}
      />
    </Box>
  );
};

export default CommonPagination;
