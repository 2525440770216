import { Card, Grid, FormLabel, CircularProgress, Checkbox, FormControlLabel } from "@mui/material";
import ErrorMessageComponent from "components/FormikErrorMessage";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AccountTypeDropdown from "components/SuiDropDownList/AccountTypeDropdown";
import {
    useGetTemplateInfoMutation
} from "app/features/api/AuthApi";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import { useGetPlanInfoMutation } from "app/features/api/AuthApi";
import CommonSpinner from "components/CommonSpinner";
import colors from "assets/theme/base/colors";
import WrapCard from "Container/Cards/WrapCard";
import RowOrderingGrid from "./TemplateFormTable";
import { useGetReferenceParametersInfoMutation } from "app/features/api/AuthApi";
import { useCreateTemplateMutation } from "app/features/api/AuthApi";
import { useTemplateGetByIdMutation } from "app/features/api/AuthApi";
import { useUpdateTemplateMutation } from "app/features/api/AuthApi";
import { useGetAllPlanInfoMutation } from "app/features/api/AuthApi";
import { answersData } from "./answer";
import { createTemplateValidation } from "Validation";
import { createTenantTemplateValidation } from "Validation";

const initialRows = [
    {
        isPushed: false,
        questionId: 1,
        questionName: '',
        answerType: '',
        referenceParameterId: '',
        name: '',
        paramValue: [],
        questionToHit: '',
    },
    {
        isPushed: false,
        questionId: 2,
        questionName: '',
        answerType: 'End_Chat',
        referenceParameterId: '',
        name: '',
        paramValue: [],
        questionToHit: '',
    },
]

const CreateTenantTemplate = ({ id }) => {
    const [mobileInValid, setMobileInValid] = useState(false);
    const navigate = useNavigate();
    const suiSnackbar = useSuiSnackbar();
    const [createTemplate, { isLoading: createLoader }] = useCreateTemplateMutation();
    const [templateGetById, { isLoading: loader }] = useTemplateGetByIdMutation();
    const [templateData, setTemplateData] = useState([]);
    const [updateTemplate, { isLoading: updateLoader }] = useUpdateTemplateMutation();
    const [getTemplateInfo, { isLoading }] = useGetTemplateInfoMutation();
    const [getReferenceParametersInfo] = useGetReferenceParametersInfoMutation();
    const [referencePara, setReferencePara] = useState([]);
    const [plans, setPlans] = useState([]);
    const [templateDataById, setTemplateDataById] = useState([]);
    const [planType, setPlanType] = useState(null)
    const [answerArray, setAnswerArray] = useState(answersData)
    const [templateDataForROws, setTemplateDataForROws] = useState([])
    const [getAllPlanInfo] = useGetAllPlanInfoMutation();
    const plan = localStorage.getItem('subscriptionType')

    const validateParamValue = (rows) => {
        let isValid = true;
        const updatedRows = [...rows];
        const queJumpToHit = rows?.map((row, index) => row.questionId)
        updatedRows.forEach((row, index) => {

            if (row.answerType === 'Calender' && !row.calendlyIntegrationId) {
                formik.setFieldError(`data.${index}.answerType`, 'Kindly select calendar');
                isValid = false
            }
        })

        updatedRows.forEach((row, index) => {
            if (
                (row.answerType === 'Selectable_Option' || row.answerType === 'Searchable_Dropdown')
            ) {
                if (!row.paramValue || row.paramValue.length === 0) {
                    formik.setFieldError(`data.${index}.answerType`, 'Please fill the value in the dialog box');
                    isValid = false;
                }
                if (row.paramValue) {
                    const paramvalueQueToHit = row.paramValue.map(val => val.questionToHit)
                    paramvalueQueToHit.forEach(val => {
                        console.log(queJumpToHit, parseInt(val))
                        if (val && !queJumpToHit?.includes(parseInt(val))) {
                            formik.setFieldError(`data.${index}.answerType`, 'Jump to value exceeds the number of questions.');
                            isValid = false;
                        }
                    })
                }
            }
        });
        return isValid;
    };

    function getAnswerTypeValue(value) {
        if (!plan.includes('calendly') && value === 'Calender') {
            return ""
        } else {
            return value
        }
    }

    const fetchTenantById = async () => {
        if (id !== "createNew") {
            try {
                const response = await templateGetById(id).unwrap();
                const transformedResponse = response.data.cb_Questions.map(item => ({
                    isPushed: item.isPushed,
                    questionId: item.questionId,
                    questionName: item.questionName || '',
                    answerType: getAnswerTypeValue(item.answerType) || '',
                    referenceParameterId: item.cb_ReferenceParameter && item.cb_ReferenceParameter.type === 'custom'
                        ? 'custom'
                        : item.referenceParameterId,
                    name: item.cb_ReferenceParameter && item.cb_ReferenceParameter.type === 'custom'
                        ? item.cb_ReferenceParameter.name
                        : '',
                    paramValue: item.cb_Answers.length > 0 ? item.cb_Answers[0].paramValue : [],
                    questionToHit: item.questionToHit || ''
                }));
                setTemplateDataById(response.data)
                const sortedData = transformedResponse?.sort((a, b) => a.questionId - b.questionId)
                setTemplateDataForROws(sortedData)
            } catch (err) {
                console.log(err);
            }
        }
    };

    const getPlans = async () => {
        try {
            const response = await getAllPlanInfo();
            const res = response?.data?.data?.map((item, index) => {
                return { id: item?.id, label: item?.subscriptionName }
            });
            setPlans(res);
        }
        catch (err) {
            console.log(err);
        }
    };

    const fetchTemplate = async () => {
        try {
            const response = await getTemplateInfo().unwrap();
            if (response.statusCode === 200) {
                setTemplateData(response?.data?.templates);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const fetchReferenceParameter = async () => {
        try {
            const response = await getReferenceParametersInfo().unwrap();
            if (response.statusCode === 200) {
                setReferencePara(response?.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchTemplate();
        fetchReferenceParameter();
        getPlans();
        if (id && id !== "createNew") {
            fetchTenantById();
        }
        if (id === 'createNew') {
            formik.setFieldValue('data', initialRows)
        }
    }, [id]);

    const initialValues = {
        name: id && id !== "createNew" ? templateDataById?.templateName : '',
        data: id && id !== "createNew" ? templateDataForROws : '',
        xcally: id && id !== "createNew" ? templateDataById?.xcally ?? false : false,
        variantKey: id && id !== "createNew" ? templateDataById?.variantKey : ''
    };
    useEffect(() => {
        if (templateDataById) {
            const newValues = {
                name: id && id !== "createNew" ? templateDataById.templateName : '',
                data: id && id !== "createNew" ? templateDataForROws : initialRows,
                xcally: id && id !== "createNew" ? templateDataById.xcally ?? false : false,
                variantKey: id && id !== "createNew" ? templateDataById.variantKey : ''
            };
            formik.setValues(newValues);
        }
    }, [templateDataById, templateDataForROws, id]);

    useEffect(() => {
        const subscriptionType = localStorage.getItem('subscriptionType')
        const subType = subscriptionType.split(', ')
        setPlanType(subType)
        let updatedAnswers = [...answersData];
        console.log(subType?.includes('calendly'))
        if (subType?.includes('calendly')) {
            const exists = answerArray.some(item => item.value === 'Calender');
            if (!exists) {
                const index = updatedAnswers.findIndex(item => item.value === 'End_Chat');
                updatedAnswers.splice(index, 0, {
                    id: 6,
                    value: 'Calender',
                    name: 'Calendar'
                });
            }
        } else {
            updatedAnswers = updatedAnswers.filter(item => item.value !== 'Calender');
        }
        setAnswerArray(updatedAnswers);
    }, [])

    const removeEmptyQuestionToHit = (data) => {
        return data.map((item) => {
            const { questionToHit, referenceParameterId, ...rest } = item;

            if (questionToHit === '' && referenceParameterId === '') {
                return rest;
            }

            const newItem = { ...item };
            if (questionToHit === '' || questionToHit === null) {
                delete newItem.questionToHit;
            }
            if (referenceParameterId === '') {
                delete newItem.referenceParameterId;
            }
            return newItem;
        });
    };



    const formik = useFormik({
        initialValues,
        validationSchema: createTenantTemplateValidation,
        enableReinitialize: false,
        onSubmit: async (values) => {
            let processedData
            const cleanedData = values.data.map(item => {
                const { cb_Answers, cb_ReferenceParameter, ...rest } = item;
                return rest;
            });
            processedData = removeEmptyQuestionToHit(cleanedData);
            console.log(processedData)
            // if (!planType?.includes('xcally')) {
            delete values.variantKey;
            delete values.xcally
            // }
            // if (planType?.includes('xcally') && !values.variantKey) {
            //     delete values.variantKey;
            // }
            console.log('object', processedData)
            try {
                if (validateParamValue(values?.data)) {
                    const response = await createTemplate({ ...values, data: processedData }).unwrap();

                    if (response.statusCode === 200) {
                        navigate('/chatbot-template');
                        suiSnackbar(response.message, 'success');
                    }
                }
            } catch (err) {
                suiSnackbar(err.data.message, 'error');
            }
        }
    });

    return (
        <div>
            <WrapCard title="Template Configuration">
                {loader || id !== 'createNew' && templateDataForROws.length <= 0 ? <CommonSpinner size="100px" color={colors.primary.main} />
                    :
                    <>
                        <Grid component={"form"} container spacing={2} rowSpacing={3}>
                            <Grid item xs={12} lg={6}>
                                <FormLabel htmlFor="name" required>
                                    Name
                                </FormLabel>
                                <SuiInput
                                    inputSettungs={{ autoComplete: "off", required: true }}
                                    type="text"
                                    placeholder="Enter Template Name"
                                    id="name"
                                    name="name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />

                                <ErrorMessageComponent
                                    touched={formik.touched.name}
                                    error={formik.errors.name}
                                />
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <RowOrderingGrid
                                    templateData={templateData}
                                    referencePara={referencePara}
                                    formik={formik}
                                    loginType='tenant'
                                    planType={planType}
                                    answersData={answerArray}
                                    {

                                    ...(templateDataForROws && {

                                        intialRowValue: templateDataForROws
                                    })
                                    }
                                />
                            </Grid>
                            {/* {planType?.includes('xcally') && 
                            <>
                            <Grid item xs={12} lg={6}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="xcally"
                                                checked={formik.values.xcally}
                                                value={formik.values.xcally}
                                                onChange={formik.handleChange}
                                                color="secondary"
                                                style={{ padding: 0 }}
                                            />
                                        }
                                    />
                                    <FormLabel htmlFor="xcally" sx={{ fontWeight: 600 }} >
                                        Xcally
                                    </FormLabel>
                                </div>
                            </Grid>
                            <Grid item xs={12} lg={6}></Grid>
                            <Grid item xs={12} lg={4}>
                                <SuiInput
                                    size='large'
                                    // inputSettungs={{ autoComplete: "off", required: true }}
                                    type="text"
                                    placeholder="Enter Variant Key"
                                    id="name"
                                    name="variantKey"
                                    value={formik.values.variantKey}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <ErrorMessageComponent
                                    touched={formik.touched.variantKey}
                                    error={formik.errors.variantKey}
                                />
                            </Grid>
                            </>
                            } */}
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container pb={2} pt={4} columnSpacing={2} rowSpacing={1}>
                                <Grid item>
                                    <SuiButton
                                        size="small"
                                        component={Link}
                                        to="/chatbot-template"
                                        variant="outlined"
                                        buttonColor="dark"
                                        sx={{ px: "1rem", py: "0.4rem" }}
                                    >
                                        Cancel
                                    </SuiButton>
                                </Grid>
                                <Grid item>
                                    <SuiButton
                                        type="submit"
                                        size="small"
                                        buttonColor="info"
                                        sx={{ px: "1rem" }}
                                        onClick={formik.handleSubmit}
                                    >
                                        {/* {id && id !== "createNew" ? (
                                            updateLoader ? <CircularProgress size='20px' color='inherit' /> : 'Update'
                                        ) : (
                                            createLoader ? <CircularProgress size='20px' color='inherit' /> : 'Create'
                                        )} */}
                                        {createLoader ? <CircularProgress size='20px' color='inherit' /> : 'Create'}
                                    </SuiButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                }
            </WrapCard>
        </div>
    );
};

export default CreateTenantTemplate;
