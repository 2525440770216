import * as Yup from 'yup';
import { Card, Grid, FormLabel, CircularProgress, Typography } from "@mui/material";
import SuiButton from "components/SuiButton";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import WrapCard from "Container/Cards/WrapCard";
import SuiFileUploadInput from "components/SuiFileUploadInput";
import { useDownloadcsvMutation, useAddCsvMutation } from "app/features/api/AuthApi";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import { useState } from "react";
import SuiTypography from "components/SuiTypography";
import FileUploadUI from "components/SuiFileUploadInput/FileUploadUI";
import ErrorMessageComponent from "components/FormikErrorMessage";

const TenantCsvImport = () => {
  const [addCsv, {isLoading}] = useAddCsvMutation();
  const [csvFile, setCsvFile] = useState(null);
  const navigate = useNavigate();
  const suiSnackbar = useSuiSnackbar();
  const [downloadcsv, { isLoading: downloadLoader }] = useDownloadcsvMutation();

  const validationSchema = Yup.object().shape({
    csvFile: Yup.mixed()
      .test('fileType', 'Only CSV files are allowed', value => {
        return value && value.type === 'text/csv';
      })
      .test('fileSize', 'File size must be less than or equal to 25MB', value => {
        return value && value.size <= 25 * 1024 * 1024; // 25MB in bytes
      })
      .required('CSV file is required'),
  });

  const formik = useFormik({
    initialValues: {
      csvFile: null,
    },
    validationSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      const files = values.csvFile;
      formData.append("csv", files);
      try {
        const response = await addCsv(formData).unwrap();
        if (response.statusCode === 200) {
          formik.resetForm();
          suiSnackbar(response.message, "success");
          navigate("/tenant");
        }
      } catch (error) {
        suiSnackbar(error?.data.message, "error");

        if (error?.data.downloadLink) {
          if (error?.data.downloadLink !== '') {
            fetch(error?.data.downloadLink).then(res => res.blob()).then(blobData => {
              const blob = new Blob([blobData], { type: 'text/csv' });
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = 'error_sample.csv';
              document.body.appendChild(a);
              a.click();
              a.remove();
              window.URL.revokeObjectURL(url);  
            });
          }
        }
      }
    },
  });

  const handleFileChange = (file) => {
    formik.setFieldValue("csvFile", file);
  }

  const removeFileName = () => {
    formik.setFieldValue("csvFile", null);
  };

  const handleDownloadCsv = async () => {
    try {
      const response = await downloadcsv().unwrap();
      const blob = new Blob([response], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'sample.csv';
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url); 
    } catch (err) {
      console.error('Error downloading the CSV file:', err);
    }
  };
  console.log(formik.errors);
  return (
    <div>
      <WrapCard title='Import Tenant'>
        <Grid component={"form"} container spacing={2} onSubmit={formik.handleSubmit}>
          <Grid item xs={12}>
            <SuiButton
              size="small"
              buttonColor="primary"
              sx={{ fontWeight: "500", px: "12px", mb: 2, mt: 2, backgroundColor: '#161740 !important' }}
              to={"/tenant/import"}
              onClick={handleDownloadCsv}
            >
              {downloadLoader ? <CircularProgress size='20px' color="inherit" />
                :
                'Download Sample CSV '
              }
            </SuiButton>
          </Grid>

          <Grid item xs={6}>
            <FileUploadUI
              name="csvFile"
              id="csvFile"
              label="Upload CSV"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              getFile={handleFileChange}
              removeFileName={removeFileName}
            />
            {/* {!formik.values.csvFile && (
              <SuiTypography textColor="text2" fontSize={14} pt={1}>
                Upload a CSV file that has Policies information.
              </SuiTypography>
            )} */}
            <ErrorMessageComponent
              touched={formik.touched.csvFile}
              error={formik.errors.csvFile}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container columnSpacing={2} rowSpacing={1}>
              <Grid item>
                <SuiButton
                  size="small"
                  variant="outlined"
                  buttonColor="dark"
                  sx={{ px: "1rem", py: "0.4rem" }}
                  onClick={() => navigate("/tenant")}
                >
                  Cancel
                </SuiButton>
              </Grid>
              <Grid item>
                <SuiButton
                  size="small"
                  buttonColor="info"
                  sx={{ px: "1rem" }}
                  disabled={isLoading}
                  onClick={formik.handleSubmit}
                >
                  {isLoading ? <CircularProgress size="20px" color="inherit" /> : "Save"}
                </SuiButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </WrapCard>
    </div>
  );
};

export default TenantCsvImport;
