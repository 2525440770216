export const accountType = [
  {
    id: "1",
    number: 1,
    label: "Admin",
  },
  {
    id: "2",
    number: 0,
    label: "Sub Admin",
  },
];
export const statusType = [
  {
    id: "1",
    number: 1,
    label: "Active",
  },
  {
    id: "0",
    number: 0,
    label: "Inactive",
  },
];

export const accountStatus = [
  {
    id: "active",
    number: 1,
    label: "Active",
  },
  {
    id: "inactive",
    number: 0,
    label: "Inactive",
  },
  {
    id: "blocked",
    number: 3,
    label: "Blocked",
  },
  {
    id: "restricted",
    number: 2,
    label: "Restricted",
  },
];

export const requestStatus = [
  {
    id: "approved",
    label: "Approve",
    number: "Approved",
  },
  {
    id: "rejected",
    label: "Reject",
    number: "Rejected",
  },
];

export const requestDIDStatus = [
  {
    id: "Removed",
    number: "Removed",
    label: "Remove",
  },
  {
    id: "Pending",
    number: "Pending",
    label: "Cancel",
  },
];

export const discountType = [
  {
    id: "Percentage",
    label: "Percentage",
  },
  {
    id: "Fixed",
    label: "Fixed",
  },
];

export const paymentMode = [
  {
    id: "card",
    label: "Card",
  },
  // {
  //   id: "credit_card",
  //   label: "Credit Card",
  // },
  // {
  //   id: "debit_card",
  //   label: "Debit Card",
  // },
  // {
  //   id: "net_banking",
  //   label: "Net Banking",
  // },
];

export const paymentStatus = [
  {
    id: "Success",
    label: "Success",
  },
  {
    id: "Failed",
    label: "Failed",
  },
  {
    id: "Pending",
    label: "Pending",
  },
  {
    id: "Removed",
    label: "Removed",
  },
];

export const status = [
  {
    id: "Paid",
    label: "Paid",
  },
  {
    id: "Removed",
    label: "Removed",
  },
  {
    id: "Removal_Requested",
    label: "Removal Requested",
  },
  {
    id: "Pending",
    label: "Pending",
  },
];

export const calltypeStatus = [
  {
    callType: "Inbound Call",
    labelName: "Inbound Call",
  },
  {
    callType: "All Outbound Call",
    labelName: "All Outbound Call",
  },
  {
    callType: "International Roaming Calls",
    labelName: "International Roaming Calls",
  },
  {
    callType: "EEA Calls",
    labelName: "EEA Calls",
  },
  {
    callType: "National Calls",
    labelName: "National Calls",
  },
  {
    callType: "International Calls",
    labelName: "International Calls",
  },
  {
    callType: "Other Calls",
    labelName: "Other Calls",
  },
  {
    callType: "International Mobile Calls",
    labelName: "International Mobile Calls",
  },
  {
    callType: "Calls to Mobile",
    labelName: "Calls to Mobile",
  },
  {
    callType: "Non-Geographic Calls",
    labelName: "Non-Geographic Calls",
  },
  {
    callType: "On Net",
    labelName: "On Net",
  },
];
