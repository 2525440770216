// import { useEffect } from "react";

import loginImg from "assets/images/BgImage.png";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import theme from "assets/theme";
import CoverLayout from "../CoverLayout";
import { Outlet } from "react-router-dom";

const LogIn = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <CoverLayout image={loginImg}>
          <Outlet />
        </CoverLayout>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default LogIn;
