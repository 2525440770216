import { makeStyles } from "@mui/styles";

export default makeStyles(({ palette, transitions, breakpoints, typography, functions }) => {
  const { white, gradients, transparent, primary, text2 } = palette;
  const { fontWeightSemiBold, size } = typography;
  const { pxToRem } = functions;

  return {
    collapse_item: {
      background: transparent.main,
      width: "100%",
      padding: `0 !important`,
      margin: `0 ${pxToRem(8)} !important`,
      userSelect: "none",
      whiteSpace: "nowrap",
      boxShadow: "none",
      "& .MuiAccordionSummary-root": {
        padding: `0 ${pxToRem(8)} 0 ${pxToRem(15)} !important`,
      },
      "& .MuiAccordionDetails-root": {
        padding: "0",
        position: 'relative',
        "&::before": {
          content: "''",
          position: 'absolute',
          width: 2,
          backgroundColor: '#999',
          height: '100%',
          left: 30,
          top: 0,
          borderRadius: 7,
        }
      },
    },
    collapse_summary: {
      borderRadius: 7,
      background: ({ active }) => (active ? "#161740 !important" : transparent.main),
      color: ({ active }) => (active ? "#fff !important" : text2.focus),
      minHeight: "40px !important",
      "& > .MuiAccordionSummary-content": {
        alignItems: "center",
        margin: "0 !important",
      },
      "& .MuiSvgIcon-root": {
        fill: ({ active }) => (active ? "#fff !important" : text2.focus),
      }
    },
    collapse_iconBox: {
      minWidth: '52px !important',
      minHeight: pxToRem(32),
      borderRadius: "0",
      display: "grid",
      placeItems: "center",
      boxShadow: "none",
      color: ({ active }) => (active ? "#fff !important" : text2.focus),
      transition: transitions.create("margin", {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),

      [breakpoints.up("xl")]: {
        background: transparent.main,
      },
      "& svg, svg g": {
        fill: ({ active }) => (active ? primary.main : text2.focus),
      },
    },

    collapse_icon: {
      color: ({ active }) => (active ? white.main : gradients.dark.state),
    },

    collapse_text: {
      marginLeft: pxToRem(2),
      color: ({ active }) => (active ? "#fff !important" : text2.focus),
      [breakpoints.up("")]: {
        opacity: ({ miniSidenav, transparentSidenav }) =>
          miniSidenav || (miniSidenav && transparentSidenav) ? 0 : 1,
        maxWidth: ({ miniSidenav, transparentSidenav }) =>
          miniSidenav || (miniSidenav && transparentSidenav) ? 0 : "100%",
        transition: transitions.create(["opacity", "margin"], {
          easing: transitions.easing.easeInOut,
          duration: transitions.duration.standard,
        }),
      },

      "& span": {
        fontWeight: fontWeightSemiBold,
        fontSize: size.sm,
        lineHeight: 0,
        color: ({ active }) => (active ? "#fff !important" : text2.focus),
      },
    },
  };
});
