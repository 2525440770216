import { BorderBottom, BorderRight } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

export default makeStyles(({ palette, boxShadows, transitions, breakpoints, functions }) => {
  const sidebarWidth = 250;
  const { white, transparent } = palette;
  const { xxl } = boxShadows;
  const { pxToRem } = functions;

  return {
    sidenav: {
      boxShadow: xxl,
      border: "none",
      backgroundColor: white.main,
      minWidth: 218,
      [breakpoints.up("xl")]: {
        boxShadow: "inset 0rem 0rem 0.03125rem 0.03125rem rgba(255, 255, 255, 0.9),  0rem 1rem 1.25rem 0rem rgba(0, 0, 0, 0.05)",
        marginBottom: "inherit",
        left: "0",
        height: "100vh",
        top: 0,
        bottom: 0,
        zIndex: 11,
      },
    },

    sidenav_header: {
      height:"75px",
      // padding: `${pxToRem(24)} ${pxToRem(32)} ${pxToRem(8)}`,
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      borderBottom:"1px solid #E4E9F0 !important",
      borderRight:"1px solid #E4E9F0 !important",

      "& a": {
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
        [breakpoints.up("xl")]: {
          display: "block",
        },
      },
    },

    sidenav_logo: {
      width: pxToRem(10), 
    },
    sidenav_list: {
      display: "flex",
      flexDirection: "column",
      marginTop:"20px",
      gap: 8,
      overflowY: "overlay",
      "&::-webkit-scrollbar": {
        width: pxToRem(4),
      },

      /* Track */
      "&::-webkit-scrollbar-track": {
        background: transparent.main,
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#88888899",
        opacity: 0.5,
        borderRadius: "10px",
        "&:hover": {
          background: "#555555aa",
          opacity: 1,
        },
      },
    },

    sidenav_title: {
      display: "block",
      opacity: 0.6,
      paddingLeft: pxToRem(8),
      margin: `${pxToRem(16)} 0 ${pxToRem(8)} ${pxToRem(8)}`,
    },

    marginTopNone: {
      marginTop: 0,
    },

    sidenav_footer: {
      margin: `auto ${pxToRem(16)} ${pxToRem(16)}`,
      paddingTop: pxToRem(16),
    },

    sidenav_open: {
      transform: "translateX(0)",
      transition: transitions.create("transform", {
        easing: transitions.easing.sharp,
        duration: transitions.duration.shorter,
      }),

      [breakpoints.up("xl")]: {
        width: sidebarWidth,
        transform: "translateX(0)",
        transition: transitions.create(["width", "background-color"], {
          easing: transitions.easing.sharp,
          duration: transitions.duration.enteringScreen,
        }),
      },
    },

    sidenav_close: {
      transform: `translateX(${pxToRem(-320)})`,
      transition: transitions.create("transform", {
        easing: transitions.easing.sharp,
        duration: transitions.duration.shorter,
      }),

      [breakpoints.up("xl")]: {
        width: pxToRem(96),
        overflowX: "hidden",
        // transform: "translateX(0)",
        transition: transitions.create(["width", "background-color"], {
          easing: transitions.easing.sharp,
          duration: transitions.duration.shorter,
        }),
      },
    },

    sidenav_navlink: {
      textDecoration: "none",
    },
  };
});
