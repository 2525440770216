import React, { useEffect, useState } from "react";
import { Box, Card, Grid, Skeleton, Typography } from "@mui/material";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Label } from "recharts";
import DashboardAiIcon from "Container/Icons/DashboardAiIcon";
import DashboardChatBotIcon from "Container/Icons/DashboardChatBotIcon";
import { useGetTenantDashboardInfoMutation } from "app/features/api/AuthApi";
import DashboardGreenIcon from "Container/Icons/DashboardGreenIcon";

const TenantDashboardUi = () => {
  const [getTenantDashboardInfo, { isLoading }] = useGetTenantDashboardInfoMutation();

  const processChatData = (data) => {
    if (!data) return [];
    return data.map((item) => ({
      hour: item.hour.split(' ')[1].substring(0, 5),
      count: item.customerCount !== undefined ? item.customerCount : item.aiRequestCount,
    }));
  };

  const [data, setData] = useState([]);
  const data2 = [
    { label: "Active Tenant", value: 2400, color: "#161740" },
    { label: "Inactive Tenant", value: 4567, color: "#1264E2" },
  ];

  const getCustomerOnload = async () => {
    try {
      const response = await getTenantDashboardInfo().unwrap();
      setData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCustomerOnload();
  }, []);

  const totalChatData = processChatData(data?.customerPerDay);
  const totalAIReqData = processChatData(data?.hourlyAiRequestData);

  return (
    <>
      <Grid item xs={12} lg={3} xxl={2}>
        <Box display="flex" flexDirection="column" gap={3} sx={{ height: "100%" }}>
          {isLoading ? <Skeleton variant="rounded" width={200} height={200} /> :
            <Card
              sx={{
                py: 2,
                px: 2,
                borderRadius: "5px !important",
                bgcolor: "#1264E2",
                alignContent: "center",
                display: "flex",
                justifyContent: "space-between",
                height: "200px",
                position: "relative",
              }}
            >
              {/* Green dot */}
              <Box
                sx={{
                  position: "absolute",
                  top: 12,
                  right: 22,
                  height: "12px",
                  width: "12px",
                  zIndex: 3,
                }}
              >
                <DashboardGreenIcon />
              </Box>

              <Box sx={{ position: "relative", height: "40px", width: "40px" }}>
                <Box
                  sx={{
                    opacity: 0.1,
                    height: "100%",
                    width: "100%",
                    background: "#fff",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 1,
                    borderRadius: "5px",
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    height: "70%",
                    width: "70%",
                    zIndex: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <DashboardChatBotIcon />
                </Box>
              </Box>

              <Typography fontWeight={500} fontSize={16} sx={{ color: "#E4E9F0" }} mt={2}>
                No. of Live Chats
                {/* <Typography fontSize="small" sx={{ color: "#E4E9F0" }}>
      (per day)
    </Typography> */}
              </Typography>
              <Typography sx={{ color: "#E4E9F0" }} fontWeight={700} fontSize={30}>
                {data?.liveUsers}
              </Typography>
            </Card>
          }

          {isLoading ? <Skeleton variant="rounded" width={200} height={200} /> :
            <Card
              sx={{
                py: 2,
                px: 2,
                borderRadius: "5px !important",
                bgcolor: "#1264E2",
                display: "flex",
                justifyContent: "space-between",
                height: "200px",
              }}
            >
              <Box sx={{ position: "relative", height: "40px", width: "40px" }}>
                <Box
                  sx={{
                    opacity: 0.1,
                    height: "100%",
                    width: "100%",
                    background: "#fff",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 1,
                    borderRadius: "5px",
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    height: "70%",
                    width: "70%",
                    zIndex: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <DashboardAiIcon />
                </Box>
              </Box>
              <Typography fontWeight={500} fontSize={16} sx={{ color: "#E4E9F0" }} mt={2}>
                Total Chats
                <Typography fontSize="small" sx={{ color: "#E4E9F0" }}>
                  (per day)
                </Typography>
              </Typography>
              <Typography sx={{ color: "#E4E9F0" }} fontWeight={700} fontSize={30}>
                {data?.totalAiRequestsUsed}
              </Typography>
            </Card>
          }
        </Box>
      </Grid>

      <Grid item xs={12} lg={9} xxl={10}>
        {isLoading ?
          <Skeleton variant="rounded" animation='wave' width={980} height={425} sx={{ ml: 2 }} />
          :
          <Card sx={{ pb: "20px", borderRadius: "5px !important" }}>
            <Typography
              fontWeight={600}
              fontSize={{ xs: 16, md: 17, lg: 18 }}
              p={2}
              borderBottom="2px solid #E4E9F0"
            >
              Total customer per day
            </Typography>
            <Box sx={{ width: "100%", height: 340 }} p={2}>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={totalChatData} margin={{ top: 30, right: 10, left: 13, bottom: 25 }}>
                  <CartesianGrid strokeDasharray="5 3" vertical={false} />
                  <XAxis
                    dataKey="hour"
                    tickLine={false}
                    axisLine={false}
                    minTickGap={-200}
                    angle={45}
                    fontSize={12}
                    dy={10}
                  > <Label value='Hours' fill="#161740" dy={35} offset={-15} position='centerBottom' fontSize={14} fontWeight={500} /> </XAxis>
                  <YAxis
                    tickLine={false}
                    axisLine={false}
                    fontSize={12}
                    dx={-10}
                    fill="#161740"
                  >
                    <Label value="Customer Count" angle={-90} fill="#161740" dx={-35} dy={15}fontSize={14} fontWeight={500} />
                  </YAxis>
                  <Tooltip
                    contentStyle={{
                      backgroundColor: "#f5f5f5",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                      width: 'auto',
                      padding:'0'
                    }}
                    labelStyle={{ color: "#4F6D7A", fontSize: '14px', background: '#E4E9F0', padding:'10px' }}
                    itemStyle={{ color: "#1264E2", padding:'10px' }}
                  />
                  <Tooltip />
                  <Line type="monotone" dataKey="count" stroke="#1264E2" strokeWidth={4} dot={false} strokeDasharray={true} />
                </LineChart>
              </ResponsiveContainer>
            </Box>
          </Card>
        }
      </Grid>

      <Grid item xs={12}>
        {isLoading ?
          <Skeleton variant="rounded" animation='wave' width={1200} height={425} />
          :
        <Card sx={{ pb: "20px", borderRadius: "5px !important" }}>
          <Typography
            fontWeight={600}
            fontSize={{ xs: 16, md: 17, lg: 18 }}
            p={2}
            borderBottom="2px solid #E4E9F0"
          >
            AI request usage per day
          </Typography>
          <Box sx={{ width: "100%", height: 320 }} py={1} px={2}>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={totalAIReqData} margin={{ top: 30, right: 0, left: 0, bottom: 25 }}>
                <CartesianGrid strokeDasharray="5 3" vertical={false} />
                <XAxis
                  dataKey="hour"
                  tickLine={false}
                  axisLine={false}
                  minTickGap={-200}
                  angle={45}
                  fontSize={12}
                  dy={10}
                > <Label value='Hours' fill="#161740" dy={35} offset={-15} position='centerBottom' fontSize={14} fontWeight={500} /> </XAxis>
                <YAxis
                  tickLine={false}
                  axisLine={false}
                  fontSize={12}
                  dx={-10}
                  fill="#161740"
                >
                  <Label value="Count" angle={-90} fill="#161740" dx={-20} fontSize={14} fontWeight={500} />
                </YAxis>
                <Tooltip
                    contentStyle={{
                      backgroundColor: "#f5f5f5",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                      width: 'auto',
                      padding:'0'
                    }}
                    labelStyle={{ color: "#4F6D7A", fontSize: '14px', background: '#E4E9F0', padding:'10px' }}
                    itemStyle={{ color: "#1264E2", padding:'10px' }}
                  />
                <Tooltip />
                <Line type="monotone" dataKey="count" stroke="#1264E2" strokeWidth={4} dot={false} strokeDasharray={true} />
              </LineChart>
            </ResponsiveContainer>
          </Box>
        </Card>
      }
      </Grid>
    </>
  );
};

export default TenantDashboardUi;
