import { useState, useEffect } from "react";
import { Card, Grid, FormLabel, CircularProgress, Checkbox, FormControlLabel } from "@mui/material";
import ErrorMessageComponent from "components/FormikErrorMessage";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import { useFormik } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import { createTenantSchema } from "Validation";
import {
  usePlanGetByIdMutation,
  useUpdatePlanMutation,
  useCreatePlanMutation
} from "app/features/api/AuthApi";
import CommonSpinner from "components/CommonSpinner";
import colors from "assets/theme/base/colors";
import WrapCard from "Container/Cards/WrapCard";
import NumberInput from "components/SuiInput/NumberInput";
import { createPlanSchema } from "Validation";
import NewNumberInput from "components/SuiInput/NewNumberInput";
import { useSelector } from "react-redux";

const CreateTenant = () => {
  const navigate = useNavigate();
  const suiSnackbar = useSuiSnackbar();
  const { id } = useParams();
  const [createPlan, { isLoading: createLoader }] = useCreatePlanMutation();
  const [planGetById, { isLoading: loader }] = usePlanGetByIdMutation();
  const [tenantDataById, setTenantDataById] = useState([]);
  const [updatePlan, { isLoading: updateLoader }] = useUpdatePlanMutation();
  const { permission } = useSelector((state) => state.auth)
  const loginType = localStorage.getItem('type')
  useEffect(() => {
    console.log(permission)
    if(permission && loginType === 'admin' && !permission?.includes("subscription plan management:create")) {
      navigate('/profile')
    }
    if(loginType === 'tenant') {
      navigate('/profile')
    }
  },[permission])
  const initialValues = {
    planName: id ? tenantDataById?.subscriptionName : "",
    integration: id ? tenantDataById?.type?.toLowerCase() : "",
    concurrentChatBotSession: id ? tenantDataById?.concurrentChatbotSession : "",
    aiRequestUsageLimit: id ? tenantDataById?.aiRequestUsageLimit : "",
    xcally: id ? tenantDataById?.type?.toLowerCase().includes("xcally") : false,
    calendly: id ? tenantDataById?.type?.toLowerCase().includes("calendly") : false,
  };
  function getType(values) {
    if (values.xcally && values.calendly) {
      return 'xcally, calendly';
    } else if (values.xcally) {
      return 'xcally';
    } else if (values.calendly) {
      return 'calendly';
    } else {
      return '';
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: createPlanSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      console.log(values);
      const body = {
        subscriptionName: values.planName,
        concurrentChatbotSession: parseInt(values.concurrentChatBotSession),
        aiRequestUsageLimit: parseInt(values.aiRequestUsageLimit),
        type: getType(formik.values)
      };

      try {
        const response = id ? await updatePlan({ data: body, id: id }).unwrap() : await createPlan(body).unwrap();
        if (response.statusCode === 200) {
          formik.resetForm();
          suiSnackbar(response.message, "success");
          navigate("/subscription-plan");
        }
      } catch (error) {
        suiSnackbar(error?.data.message, "error");
        console.log(error);
      }
    },
  });

  const fetchTenantById = async () => {
    try {
      const response = await planGetById(id).unwrap();
      setTenantDataById(response?.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    
    if(tenantDataById && id){
      console.log(tenantDataById?.type)
      const split = tenantDataById?.type?.split(', ') ?? [];
      console.log(split?.includes("calendly"))
      console.log(split?.includes("xcally"))
      const xcallyVal = split?.includes("xcally")
      const calendlyVal = split?.includes("calendly")
      formik.setFieldValue('calendly', calendlyVal)
      formik.setFieldValue('xcally', xcallyVal)
    }

    console.log(formik.values)
  },[tenantDataById, id])

  useEffect(() => {
    if (id) fetchTenantById();
  }, [id]);
  return (
    <div>
      <WrapCard title={id ? 'Update Subscription' : 'Add New Subscription'}>
        {loader ? (
          <CommonSpinner size="100px" color={colors.primary.main} />
        ) : (
          <>
            <Grid component={"form"} container spacing={2} rowSpacing={3}>
              <Grid item xs={12} lg={6}>
                <FormLabel htmlFor="planName" style={{ fontWeight: 'bold' }} required>
                  Subscription Name
                </FormLabel>
                <SuiInput
                  inputSettungs={{ autoComplete: "off", required: true }}
                  type="text"
                  placeholder="Enter Subscription Name"
                  id="planName"
                  name="planName"
                  value={formik.values.planName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <ErrorMessageComponent
                  touched={formik.touched.planName}
                  error={formik.errors.planName}
                />
              </Grid>
              <Grid item xs={12} lg={6}></Grid>
              <Grid item xs={12} lg={6}>
                <FormLabel htmlFor="integration" style={{ fontWeight: 'bold' }} required >
                  Integration
                </FormLabel>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="xcally"
                        checked={formik.values.xcally}
                        value={formik.values.xcally}
                        onChange={formik.handleChange}
                        color="secondary"
                        style={{ marginRight: 4 }}
                      />
                    }
                    label={<FormLabel style={{ fontWeight: 'bold' }}>Xcally</FormLabel>}
                    style={{ marginRight: 20 }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="calendly"
                        value={formik.values.calendly}
                        checked={formik.values.calendly}
                        onChange={formik.handleChange}
                        color="secondary"
                        style={{ marginRight: 4 }}
                      />
                    }
                    label={<FormLabel style={{ fontWeight: 'bold' }}>Calendly</FormLabel>}
                    style={{ marginRight: 20 }}
                  />
                </div>
                <ErrorMessageComponent
                  touched={formik.touched.integration}
                  error={formik.errors.integration}
                />
              </Grid>
              <Grid item xs={12} lg={6}></Grid>
              <Grid item xs={12} lg={6}>
                <FormLabel htmlFor="concurrentChatBotSession" style={{ fontWeight: 'bold' }} required >
                  Concurrent Chatbot Session
                </FormLabel>
                <NewNumberInput
                  inputSettungs={{ autoComplete: "off", required: true }}
                  type="number"
                  placeholder="Enter Concurrent Chat Bot Session"
                  id="concurrentChatBotSession"
                  name="concurrentChatBotSession"
                  value={formik.values.concurrentChatBotSession}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <ErrorMessageComponent
                  touched={formik.touched.concurrentChatBotSession}
                  error={formik.errors.concurrentChatBotSession}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormLabel htmlFor="aiRequestUsageLimit" style={{ fontWeight: 'bold' }} required >
                  AI Request Usage Limit
                </FormLabel>
                <NewNumberInput
                  inputSettungs={{ autoComplete: "off", required: true }}
                  type="number"
                  placeholder="Enter AI Request Usage Limit"
                  id="aiRequestUsageLimit"
                  name="aiRequestUsageLimit"
                  value={formik.values.aiRequestUsageLimit}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <ErrorMessageComponent
                  touched={formik.touched.aiRequestUsageLimit}
                  error={formik.errors.aiRequestUsageLimit}
                />
              </Grid>
            </Grid>

            <Grid item sx={{ pb: 1, pt: 1 }}>
              <ErrorMessageComponent touched={formik.errors.error} error={formik.errors.error} />
            </Grid>

            <Grid item xs={12}>
              <Grid container pb={2} pt={4} columnSpacing={2} rowSpacing={1}>
                <Grid item>
                  <SuiButton
                    size="small"
                    component={Link}
                    to="/subscription-plan"
                    variant="outlined"
                    buttonColor="dark"
                    sx={{ px: "1rem", py: "0.4rem" }}
                  >
                    Cancel
                  </SuiButton>
                </Grid>
                <Grid item>
                  <SuiButton
                    type="submit"
                    size="small"
                    buttonColor="info"
                    sx={{ px: "1rem" }}
                    onClick={formik.handleSubmit}
                  >
                    {id ? (
                      updateLoader ? <CircularProgress size="20px" color="inherit" /> : 'Update'
                    ) : (
                      createLoader ? <CircularProgress size="20px" color="inherit" /> : 'Create'
                    )}
                  </SuiButton>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </WrapCard>
    </div>
  );
};

export default CreateTenant;
