import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  InputLabel,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import SuiPasswordInput from "components/SuiPasswordInput";
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import { useFormik } from "formik";
import { loginWithPasswordSchema } from "Validation";
import ErrorMessageComponent from "components/FormikErrorMessage";
import { useDispatch } from "react-redux";
import { setCredentials } from "app/features/AuthSlice";
import { useTenantLoginWithPasswordMutation } from "app/features/api/AuthApi";
import { useEffect, useState } from "react";
import { fontGrid } from "@mui/material/styles/cssUtils";
import MailIcon from "@mui/icons-material/Mail";
import LockIcon from "@mui/icons-material/Lock";
import { Padding } from "@mui/icons-material";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import useSuiSnackbar from "hooks/useSuiSnackbar";

const TenantLoginWithPass = () => {
  const suiSnackbar = useSuiSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tenantLoginWithPassword] = useTenantLoginWithPasswordMutation();
  const [remember, setRemember] = useState(false);
  const [loginwithPasswordError, setLoginwithPasswordError] = useState("");
  const secretKey = process.env.REACT_APP_SECRET_KEY;
  const isRemembered = localStorage.getItem("isRemember_tenant");
  const [decryptedData, setDecryptedData] = useState({
    decryptedEmail: "",
    decryptedPassword: "",
  });
  const encryptedEmail = Cookies?.get("tenantEmail");
  const encryptedPassword = Cookies?.get("tenantPassword");
  useEffect(() => {
    const parsedIsRemembered = isRemembered === "true";
    if (parsedIsRemembered && encryptedEmail && encryptedPassword) {
      const decryptedEmail = CryptoJS.AES.decrypt(
        encryptedEmail,
        secretKey
      ).toString(CryptoJS.enc.Utf8);
      const decryptedPassword = CryptoJS.AES.decrypt(
        encryptedPassword,
        secretKey
      ).toString(CryptoJS.enc.Utf8);
      setDecryptedData({ decryptedEmail, decryptedPassword });
      setRemember(parsedIsRemembered);
    }
  }, [isRemembered, encryptedEmail, encryptedPassword, secretKey]);

  const formik = useFormik({
    initialValues: {
      email: decryptedData?.decryptedEmail,
      password: decryptedData?.decryptedPassword,
    },
    validationSchema: loginWithPasswordSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const body = {
        email: values.email,
        password: values.password,
      };
      try {
        const response = await tenantLoginWithPassword(body).unwrap();
        const encryptedEmail = CryptoJS.AES.encrypt(
          values.email,
          secretKey
        ).toString();
        const encryptedPassword = CryptoJS.AES.encrypt(
          values.password,
          secretKey
        ).toString();
        const expires = remember ? 365 : undefined;
        Cookies.set("tenantEmail", encryptedEmail, { expires });
        Cookies.set("tenantPassword", encryptedPassword, { expires });

        if (response.statusCode === 200) {
          localStorage.setItem("auth_token_admin", response.data.accessToken);
          localStorage.setItem(
            "tokenExpiryTime_admin",
            response.data.token_expire_at
          );
          localStorage.setItem('type', response.data.type)
          localStorage.setItem("loggedInTime_admin", new Date());
          localStorage.setItem(
            "refresh_token_admin",
            response.data.refresh_token
          );
          localStorage.setItem("isRemember_tenant", remember.toString());
          sessionStorage.setItem("isRemember_tenant", remember.toString());

          dispatch(
            setCredentials({
              token_admin: response.data.accessToken,
              isAuthenticated: true,
            })
          );
          navigate("/dashboard");
        }
      } catch (error) {
        // setLoginwithPasswordError(error.data.message);
        suiSnackbar(error.data.message, 'error')
      }
    },
  });

  const handleSetRememberMe = () => {
    setRemember(!remember);
  };
  console.log("tenant");
  return (
    <>
      <SuiTypography mb={3} fontWeight="bold">
        Login to your Account
      </SuiTypography>
      {/* <SuiTypography component="p" textColor="text2" variant="formSubHeading">
        Filll up below details to sign in
      </SuiTypography> */}
      <SuiBox mb={1}>
        <InputLabel required sx={{ fontWeight: "600 !Important" }}>Email ID</InputLabel>
      </SuiBox>
      <SuiBox mt={0} mb={3.5} style={{ position: "relative" }}>
        <MailIcon
          style={{
            fill: "none",
            stroke: "currentColor",
            strokeWidth: 2,
            position: "absolute",
            left: 10,
            top: 12,
            fontSize: "15px !important",
            zIndex: 9,
          }}
        />
        <SuiBox mb={3}>
          <SuiInput
            placeholder="Enter Email ID"
            sx={{
              "& ::placeholder": {
                fontSize: "small",
                color: "green !important",
              },
            }}
            inputProps={{
              sx: { pl: 4 },
            }}
            inputSettungs={{ autoComplete: "off", required: true }}
            type="email"
            id="email"
            name="email"
            value={formik.values.email}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          <ErrorMessageComponent
            touched={formik.touched.email}
            error={formik.errors.email}
          />
        </SuiBox>
        {/* <SuiBox></SuiBox> */}

        <SuiBox mb={1}>
          <InputLabel required sx={{ fontWeight: "600 !Important" }}>Password</InputLabel>
        </SuiBox>

        <SuiBox mb={2} style={{ position: "relative" }}>
          <LockIcon
            style={{
              fill: "none",
              stroke: "currentColor",
              strokeWidth: 2,
              position: "absolute",
              left: 10,
              top: 11,
              zIndex: 9,
              fontSize: "15px !important",
            }}
          />
          <SuiPasswordInput
            inputProps={{ pl: 4 }}
            name="password"
            placeholder="Enter Password"
            value={formik.values.password}
            getValue={formik.handleChange}
            onBlur={formik.handleBlur}
            isIconVisible="true"
          />
          <ErrorMessageComponent
            touched={formik.touched.password}
            error={formik.errors.password}
          />
        </SuiBox>

        <Grid item sx={{ pb: 1 }}>
          {Object.keys(formik.errors)?.length === 0 &&
            loginwithPasswordError && (
              <ErrorMessageComponent
                touched={loginwithPasswordError}
                error={loginwithPasswordError}
              />
            )}
        </Grid>
        <SuiBox
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <FormControlLabel
            value="end"
            control={
              <Checkbox
                color="secondary"
                checked={remember}
                onChange={handleSetRememberMe}
              />
            }
            label="Remember me"
            sx={{
              m: 0,
              "& .MuiTypography-root": {
                color: "#848484",
                fontWeight: 400,
              },
            }}
          />
          {/* <FormControlLabel
            value="end"
            control={
              <Checkbox
                color="secondary"
                checked={remember}
                onChange={handleSetRememberMe}
              />
            }
            label="Remember me"
            sx={{
              m: 0,
              "& .MuiTypography-root": {
                color: "#848484",
                fontWeight: 400,
              },
            }}
          /> */}
          <Link to="/forgot-password">
            <SuiTypography style={{ fontWeight: 500, fontSize: "14px" }}>
              Forgot Password?
            </SuiTypography>
          </Link>
        </SuiBox>
      </SuiBox>
      <SuiBox mt={2} mb={0.5}>
        <SuiButton
          tabIndex={4}
          type="submit"
          disabled={formik.isSubmitting}
          variant="contained"
          buttonColor="info"
          fullWidth
          onClick={formik.handleSubmit}
        >
          {formik.isSubmitting ? (
            <CircularProgress size="25px" color="inherit" />
          ) : (
            "Login"
          )}
        </SuiButton>
      </SuiBox>
    </>
  );
};

export default TenantLoginWithPass;
