import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { baseAPIService } from "./features/api";
import AuthReducer from "./features/AuthSlice";
import UIControllerReducer from "./features/UIControllerSlice";
import DashboardReducer from "./features/DashboardSlice";
import CustomerDetailsReducer from "./features/CustomeDetailsSlice";
import { permissionSlice } from "./features/PermissionSLice";



export const store = configureStore({
  reducer: {
    auth: AuthReducer,
    dashboard: DashboardReducer,
    customerDetails: CustomerDetailsReducer,
    uiControl: UIControllerReducer,
    permission: permissionSlice.reducer,
    [baseAPIService.reducerPath]: baseAPIService.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(baseAPIService.middleware),
  devTools: true,
});

setupListeners(store.dispatch);

export default store;
