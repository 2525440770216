import {
  Grid,
  Card,
  Typography,
  Box
} from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";
import { LineChart } from "@mui/x-charts";
import { useGetDashboardInfoMutation } from "app/features/api/DashboardApi";
import DashboardChatBotIcon from "Container/Icons/DashboardChatBotIcon";
import DashboardAiIcon from "Container/Icons/DashboardAiIcon";
import TenantDashboardUi from "./TenantDashboardUi";
import AdminDashboardUi from "./AdminDashboardUi";

const Dashboard = () => {
  const loginType = localStorage.getItem('type')
  return (
    <Grid component="section" container py={3} spacing={2}>
      {loginType === 'tenant' ? <TenantDashboardUi /> : <AdminDashboardUi />}
    </Grid>
  );
};

export default Dashboard;
