import { CircularProgress, FormLabel, Grid, TableCell, TableRow, Typography } from '@mui/material';
import SuiInput from 'components/SuiInput';
import WrapCard from 'Container/Cards/WrapCard';
import React, { useEffect, useState } from 'react';
import BasicTable from './AddRoleTable';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import SuiButton from 'components/SuiButton';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ErrorMessageComponent from 'components/FormikErrorMessage';
import { useCreateRolesMutation, useUpdateRoleMutation } from 'app/features/api/AuthApi';
import useSuiSnackbar from 'hooks/useSuiSnackbar';
import { useGetModulesInfoMutation, useRoleGetByIdMutation } from 'app/features/api/AuthApi';
import { ReportSharp } from '@mui/icons-material';
import CommonSpinner from 'components/CommonSpinner';
import colors from 'assets/theme/base/colors';
import { useSelector } from 'react-redux';

const validationSchema = Yup.object({
    roleName: Yup.string()
        .required('Role Name is required')
        .min(2, 'Minimum 2 characters are required')
        .max(50, 'Maximum 50 characters allowed')
        .matches(/^(?=.*[a-zA-Z])[a-zA-Z0-9]+$/, 'Role Name must be alphanumeric with atleast one character')
});
const CreateRole = () => {
    const [modules, setModules] = useState([]);
    const { permission } = useSelector((state) => state.auth)
    const [createRoles, { isLoading: createLoader }] = useCreateRolesMutation();
    const [updateRole, { isLoading: updateLoader }] = useUpdateRoleMutation();
    const navigate = useNavigate();
    const suiSnackbar = useSuiSnackbar();
    const [getModulesInfo] = useGetModulesInfoMutation();
    const [roleGetById] = useRoleGetByIdMutation();
    const { id } = useParams();
    const [loader, setLoader] = useState(true);
    const [roleName, setRoleName] = useState('')
    const loginType = localStorage.getItem('type')
    useEffect(() => {
        if (permission && !permission?.includes('roles and permissions:read')) {
            navigate('/profile')
        }
        if (loginType === 'tenant') {
            navigate('/profile')
        }
    }, [permission])
    useEffect(() => {
        const fetchModulesAndRole = async () => {
            setLoader(true);
            try {
                const [modulesResponse, roleResponse] = await Promise.all([
                    getModulesInfo().unwrap(),
                    id ? roleGetById(id).unwrap() : Promise.resolve(null)
                ]);

                const updatedModules = modulesResponse?.data?.map((item) => ({
                    ...item, create: false, view: false, delete: false
                }));

                if (roleResponse) {
                    setRoleName(roleResponse?.data?.name)
                    const updatedPermissions = roleResponse?.data?.cb_Permissions?.map((item) => {
                        return { ...item, name: item?.cb_Module?.name, id: item?.cb_Module?.id }
                    });

                    const newArray = updatedModules.map((value) => {
                        let updatedValue = value;
                        updatedPermissions.forEach((item) => {
                            if (value?.name === item?.name) {
                                updatedValue = { ...value, create: item.create, delete: item.delete, view: item.read };
                            }
                        });
                        return updatedValue;
                    });
                    setModules(newArray)
                } else {
                    setModules(updatedModules);
                }
            } catch (err) {
                suiSnackbar('Something Went Wrong', 'error')
                navigate('/roles-and-permissions')
                console.log(err);
            } finally {
                setLoader(false);
            }
        };
        fetchModulesAndRole();
    }, [id]);

    const formik = useFormik({
        initialValues: {
            roleName: roleName,
        },
        enableReinitialize: true,
        validationSchema,
        onSubmit: async (values) => {
            console.log(values)
            console.log(modules)
            const modulesObj = modules.map(module => {
                console.log(module)
                return {
                    ...module,
                    module_id: module.id,
                    read: module.view
                }
            });
            const body = {
                name: values.roleName,
                permission: modulesObj
            };

            try {
                const response = id ? await updateRole({ data: body, id: id }).unwrap() : await createRoles(body).unwrap();
                if (response.statusCode === 200 || response.statusCode === 201) {
                    suiSnackbar(response.message, "success");
                    navigate("/roles-and-permissions");
                }
            } catch (error) {
                console.error(error?.data?.message);
                suiSnackbar(error?.data?.message, "error");
            }
        },
    });
    return (
        <div>
            <WrapCard title={id ? 'Update Role' : 'Add New Role'}>
                {loader ?
                    <CommonSpinner size="100px" color={colors.primary.main} />
                    :
                    <form>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormLabel htmlFor="role" required>
                                    Role Name
                                </FormLabel>
                                <SuiInput
                                    id="roleName"
                                    name="roleName"
                                    type="text"
                                    placeholder="Enter Role Name"
                                    value={formik.values.roleName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <ErrorMessageComponent touched={formik.touched.roleName} error={formik.errors.roleName} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography variant='h6' mt={2}>
                                    Permission
                                </Typography>
                            </Grid>
                        </Grid>

                        <BasicTable
                            modules={modules}
                            setModules={setModules}
                        />

                        <Grid item xs={12}>
                            <Grid container pb={2} pt={4} columnSpacing={2} rowSpacing={1}>
                                <Grid item>
                                    <SuiButton
                                        Input
                                        size="small"
                                        component={Link}
                                        to="/roles-and-permissions"
                                        variant="outlined"
                                        buttonColor="dark"
                                        sx={{ px: "1rem", py: "0.4rem" }}
                                    >
                                        Cancel
                                    </SuiButton>
                                </Grid>
                                <Grid item>
                                    <SuiButton
                                        type="button"
                                        onClick={formik.handleSubmit}
                                        size="small"
                                        buttonColor="info"
                                        sx={{ px: "1rem" }}
                                    >

                                        {id ? (
                                            updateLoader ? <CircularProgress size='20px' color='inherit' /> : 'Update'
                                        ) : (
                                            createLoader ? <CircularProgress size='20px' color='inherit' /> : 'Create'
                                        )}
                                    </SuiButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                }
            </WrapCard>

        </div>
    );
}

export default CreateRole;
